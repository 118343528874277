import { useCallback, useEffect, useRef, useState } from "react";
import { ReactComponent as ArrowIcon } from "../../assets/icons/arrow-down.svg";

const variants = {
  basic: "",
};

const CustomAccordion = ({
  className,
  variant = "basic",
  title,
  children,
  openByDefault = false,
}) => {
  const [open, setOpen] = useState(openByDefault);
  const ref = useRef(null);
  const [childHeight, setChildHeight] = useState(0);

  const handleResize = useCallback(() => {
    setChildHeight(ref.current.offsetHeight);
  }, []);

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [handleResize]);

  return (
    <div className={className}>
      <button
        type="button"
        className={`flex w-full items-center justify-between space-x-3 transition-all text-xl
          ${variants[variant]}`}
        onClick={(e) => {
          e.preventDefault();
          setOpen(!open);
        }}
      >
        <div className="text-left font-semibold text-base">{title}</div>
        <div className="h-6 w-6 rounded-full flex items-center justify-center">
          <ArrowIcon
            width="16"
            height="9"
            stroke="#667085"
            className={`transition-all  ${open ? "-rotate-180" : ""}`}
          />
        </div>
      </button>
      <div
        ref={ref}
        className={`transition-all duration-200 overflow-hidden text-base
          ${open ? `h-[${childHeight}px] mt-0 pt-3` : "h-0 pt-0 mt-0"}`}
      >
        {children}
      </div>
    </div>
  );
};

export default CustomAccordion;
