import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import emptyTeamsIllustrationSrc from "../../../../assets/empty-teams.svg";
import { ReactComponent as PlusIcon } from "../../../../assets/icons/plus.svg";
import { ReactComponent as DeleteIcon } from "../../../../assets/icons/trash-redesign.svg";
import {
  createUserTeam,
  deleteTeam,
  getTeams,
} from "../../../../store/user/thunks";
import ActionsButton from "../../../Basic/ActionsButton";
import AvatarStack from "../../../Basic/AvatarStack";
import Button from "../../../Basic/Button";
import DeleteModal from "../../../Basic/DeleteModal";
import ProfilePicture from "../../../Basic/ProfilePicture";
import TeamTableSkeleton from "../../../Skeletons/TeamTableSkeleton";
import DeleteErrorModal from "../DeleteErrorModal";
import UserTable from "../UserTable";
import AddTeamModal from "./AddTeamModal";
import EditTeamModal from "./EditTeamModal";
import SuccessModal from "./SuccessModal";
import TeamListItem from "./TeamListItem";

const Teams = ({ openModal, setOpenModal }) => {
  const { teams, teamsLoading, teamSearchValue } = useSelector(
    (state) => state.user,
  );
  const [openEditModal, setOpenEditModal] = useState(false);
  const [selectedTeam, setSelectedTeam] = useState(null);
  const [createdTeamId, setCreatedTeamId] = useState(null);
  const [createdTeam, setCreatedTeam] = useState({
    name: "",
    leadName: "",
    numOfPatients: 0,
  });
  const [openSuccessModal, setOpenSuccessModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openDeleteErrorModal, setOpenDeleteErrorModal] = useState(false);
  const isDesktop = useMediaQuery({ minWidth: 768 });
  const dispatch = useDispatch();
  const teamSearchValueRef = useRef(teamSearchValue);

  useEffect(() => {
    teamSearchValueRef.current = teamSearchValue;
  }, [teamSearchValue]);

  useEffect(() => {
    dispatch(getTeams(teamSearchValueRef.current));
  }, [dispatch]);

  useEffect(() => {
    if (teams?.length > 0 && createdTeamId) {
      const team = teams.find(
        (team) => team.team?.doctor_team_id === createdTeamId,
      );
      if (team) {
        setOpenModal(false);
        setSelectedTeam(team);
        setOpenEditModal(true);
      } else {
        setSelectedTeam(null);
      }
    }
  }, [teams, setOpenModal, createdTeamId]);

  const columns = [
    { label: "Name", accessor: "name" },
    { label: "Clinical Lead", accessor: "lead" },
    { label: "Members", accessor: "users" },
    {
      label: "Number of patients",
      accessor: "number",
      className: "justify-center",
    },
    { label: "", accessor: "actions" },
  ];

  const getTableData = () => {
    const tableData = [];
    teams.forEach((team) => {
      tableData.push({
        team: team,
        name: (
          <p className="truncate md:max-w-[180px] xl:max-w-[210px] 2xl:max-w-[320px]">
            {team.team?.name || team.display_name}
          </p>
        ),
        lead: (
          <div className="flex items-center space-x-2 truncate md:max-w-[180px] xl:max-w-[210px] 2xl:max-w-[320px]">
            <ProfilePicture
              firstName={team.first_name}
              lastName={team.last_name}
              src={team.profile_picture}
              flagPadding={false}
            />
            <p className="truncate">
              {team.display_name
                ? team.display_name
                : `${team.first_name} ${team.last_name}`}
            </p>
          </div>
        ),
        users: (
          <AvatarStack
            users={team.assistants}
            length={4}
            totalNumber={team.assistants ? team.assistants.length : 0}
          />
        ),
        number: <p className="mx-auto">{team.total_patients}</p>,
        actions: (
          <ActionsButton
            onClick={() => setSelectedTeam(team)}
            actions={[
              {
                icon: (
                  <DeleteIcon width="20" height="20" className="flex-none" />
                ),
                label: "Delete",
                onClick: onDeleteTeam,
              },
            ]}
          />
        ),
      });
    });
    return tableData;
  };

  const onAddTeam = (lead, teamName) => {
    dispatch(
      createUserTeam(
        lead.doctor_id,
        teamName,
        teamSearchValue,
        false,
        (teamId) => {
          setCreatedTeamId(teamId);
        },
      ),
    );
  };

  const onEditTeam = (team) => {
    if (team.team) {
      setSelectedTeam(team);
      setOpenEditModal(true);
    }
  };

  const onDeleteTeam = () => {
    if (selectedTeam.total_patients > 0) {
      setOpenDeleteErrorModal(true);
    } else {
      setOpenDeleteModal(true);
    }
  };

  const handleDeleteTeam = () => {
    dispatch(deleteTeam(selectedTeam.team.doctor_team_id, teamSearchValue));
    setSelectedTeam({});
    setOpenDeleteModal(false);
  };

  return (
    <div className="relative w-full md:h-full overflow-hidden">
      {teamsLoading || teams === null ? (
        <TeamTableSkeleton />
      ) : teams.length > 0 ? (
        isDesktop ? (
          <UserTable
            columns={columns}
            data={getTableData()}
            onClickRow={(team) => onEditTeam(team)}
          />
        ) : (
          <div className="space-y-3">
            {teams.map((team) => (
              <TeamListItem
                key={team.doctor_id}
                team={team}
                onClick={() => onEditTeam(team)}
                onClickActions={() => setSelectedTeam(team)}
                onDelete={onDeleteTeam}
              />
            ))}
          </div>
        )
      ) : (
        <div className="w-full flex flex-col items-center justify-center py-10 md:py-16 space-y-7">
          <img
            src={emptyTeamsIllustrationSrc}
            alt="Empty Teams"
            width="459"
            height="165"
          />
          <p className="text-xl font-bold">No Teams Found</p>
          <Button
            className="font-semibold text-sm sm:text-base space-x-2"
            onClick={() => setOpenModal(true)}
          >
            <PlusIcon width="20" height="20" stroke="white" />
            <p>Create Team</p>
          </Button>
        </div>
      )}

      <AddTeamModal
        open={openModal}
        onClose={() => {
          setOpenModal(false);
          setCreatedTeamId(null);
        }}
        onNext={onAddTeam}
      />

      <EditTeamModal
        open={openEditModal}
        onClose={(e) => {
          e?.preventDefault();
          setSelectedTeam(null);
          setCreatedTeamId(null);
          setOpenEditModal(false);
        }}
        onCancel={(e) => {
          e?.preventDefault();
          if (createdTeamId) {
            dispatch(deleteTeam(createdTeamId, teamSearchValue, false));
          }
          setSelectedTeam(null);
          setCreatedTeamId(null);
          setOpenEditModal(false);
        }}
        onSave={() => {
          if (createdTeamId) {
            setCreatedTeam({
              name: selectedTeam.team?.name || selectedTeam.display_name,
              leadName: selectedTeam.display_name
                ? selectedTeam.display_name
                : `${selectedTeam.first_name} ${selectedTeam.last_name}`,
              numOfPatients: selectedTeam.total_patients,
            });
            setOpenSuccessModal(true);
          }
        }}
        selectedTeam={selectedTeam}
        newTeam={!!createdTeamId}
      />

      {openSuccessModal && (
        <SuccessModal
          onClose={() => {
            setOpenSuccessModal(false);
            setCreatedTeam({ name: "", leadName: "", numOfPatients: 0 });
          }}
          teamName={createdTeam.name}
          leadName={createdTeam.leadName}
          numOfPatients={createdTeam.numOfPatients}
        />
      )}

      {openDeleteModal && (
        <DeleteModal
          onClose={() => setOpenDeleteModal(false)}
          onDelete={handleDeleteTeam}
          title="Delete Team"
          description="Are you sure you want to delete the team?"
        />
      )}

      {openDeleteErrorModal && (
        <DeleteErrorModal
          onClose={() => setOpenDeleteErrorModal(false)}
          title="Delete Team"
          message="You cannot delete team with patients assigned"
        />
      )}
    </div>
  );
};

export default Teams;
