import { ReactComponent as PatientsIcon } from "../../assets/icons/patients-redesign.svg";
import { ReactComponent as PatientHeartIcon } from "../../assets/icons/user-heart-rounded.svg";
import AvatarStack from "../Basic/AvatarStack";

const FacilityListItem = ({ facility, onClick = () => {} }) => {
  return (
    <div className="p-4 rounded-xl space-y-3 bg-white" onClick={onClick}>
      <div>
        <p className="text-sm font-semibold truncate">
          {facility.display_name || facility.name}
        </p>
        <p className="text-xs text-tertiary truncate">
          {facility.phone_number}
        </p>
      </div>
      <div className="grid grid-cols-[auto_auto_auto] divide-x border-gray-foreground">
        <div className="flex flex-col space-y-1 font-medium pr-3">
          <p className="text-xs text-tertiary">Number of provider</p>
          <AvatarStack
            users={facility.providers}
            length={4}
            totalNumber={facility.providers.length}
          />
        </div>
        <div className="flex flex-col space-y-2 font-medium truncate px-3">
          <p className="text-xs text-tertiary">Patients</p>
          <div className="bg-gray-foreground rounded-full flex items-center space-x-1 px-2 py-1 w-fit">
            <PatientHeartIcon
              width={16}
              height={16}
              stroke="#667085"
              className="flex-none"
            />
            <p className="text-xs leading-none text-tertiary font-semibold">
              {facility.total_patients}
            </p>
          </div>
        </div>
        <div className="flex flex-col space-y-2 font-medium truncate pl-3">
          <p className="text-xs text-tertiary">Teams</p>
          <div className="bg-gray-foreground rounded-full flex items-center space-x-1 px-2 py-1 w-fit">
            <PatientsIcon
              width={16}
              height={16}
              stroke="#667085"
              className="flex-none"
            />
            <p className="text-xs leading-none text-tertiary font-semibold">
              {facility.total_teams}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FacilityListItem;
