import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { useDispatch, useSelector } from "react-redux";
import { noteTemplateSlice } from "../components/IndependentScribe/store/noteTemplateSlice";
import { scribeSlice } from "../components/IndependentScribe/store/scribeSlice";
import { storageMiddleware } from "../components/IndependentScribe/store/storageMiddleware";
import timerSlice from "../components/IndependentScribe/store/timerSlice";
import authReducer from "./auth/authReducer";
import billingMetricsReducer from "./billingMetrics/billingMetricsReducer";
import campaignReducer from "./campaign/campaignReducer";
import conversationReducer from "./conversation/conversationReducer";
import facilityReducer from "./facility/facilityReducer";
import metricsReducer from "./metrics/metricsReducer";
import patientReducer from "./patient/patientReducer";
import templateReducer from "./template/templateReducer";
import uiReducer from "./ui/uiReducer";
import userReducer from "./user/userReducer";
import voiceRecorderReducer from "./voiceRecorder/voiceRecorderReducer";

export const rootReducer = combineReducers({
  auth: authReducer,
  user: userReducer,
  conversation: conversationReducer,
  patient: patientReducer,
  campaign: campaignReducer,
  template: templateReducer,
  metrics: metricsReducer,
  voiceRecorder: voiceRecorderReducer,
  billingMetrics: billingMetricsReducer,
  scribe: scribeSlice.reducer,
  noteTemplate: noteTemplateSlice.reducer,
  facility: facilityReducer,
  timer: timerSlice,
  ui: uiReducer,
});

export const setupStore = (preloadedState?: Partial<RootState>) => {
  return configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: {
          ignoredActions: ["timer/setCurrentTime"],
          ignoredPaths: ["timer.currentTime"],
        },
      }).prepend(storageMiddleware),
    preloadedState,
  });
};

export const store = setupStore();

export type RootState = ReturnType<typeof rootReducer>;
export type AppStore = ReturnType<typeof setupStore>;
export type AppDispatch = AppStore["dispatch"];

export const useAppDispatch = useDispatch.withTypes<AppDispatch>();
export const useAppSelector = useSelector.withTypes<RootState>();
