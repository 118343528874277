import type { AxiosError } from "axios";
import { useCallback, useEffect, useRef, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import GenerationDelayed from "../components/Basic/Warning/GenerationDelayed";
import NotEnoughTranscript from "../components/Basic/Warning/NotEnoughTranscript";
import RecordingContextStalled from "../components/Basic/Warning/RecordingContextStalled";
import FinishLaterModal from "../components/IndependentScribe/FinishLaterModal";
import useRecorder from "../components/IndependentScribe/Recorder";
import ScribeContainer from "../components/IndependentScribe/ScribeContainer";
import ScribeList from "../components/IndependentScribe/ScribeList";
import TimerProvider from "../components/IndependentScribe/TimerProvider";
import {
  ERROR_NOTE_GENERATION_DELAYED,
  ERROR_NOT_ENOUGH_TRANSCRIPT,
  NOTE_NOT_SELECTED,
} from "../components/IndependentScribe/consts";
import { useScribeGenerationDelay } from "../components/IndependentScribe/hooks/useScribeGenerationDelay";
import { setShowScribeNoteTemplate } from "../components/IndependentScribe/store/noteTemplateSlice";
import {
  type FixLater,
  setInterruptedRecordingAction,
  setScribe,
  setScribePaused,
  setScribeResumeGenerated,
  setScribeSelectedAudioId,
  setScribeTogglePause,
} from "../components/IndependentScribe/store/scribeSlice";
import {
  getRecordingScribe,
  getSelectedScribe,
} from "../components/IndependentScribe/store/selectors";
import { getProviderNotes } from "../components/IndependentScribe/store/thunks";
import { SetInterruptedRecordingActionType } from "../components/IndependentScribe/store/types";
import { Pages } from "../helpers/constants";
import { useNoSoundNotification } from "../hooks/useNoSoundNotification";
import { store, useAppDispatch, useAppSelector } from "../store";
import { setSelectedPatientInfoAction } from "../store/patient/actions";
import { getPatientInfo } from "../store/patient/thunks";
import { setPathBeforeProfile } from "../store/ui/actions";
import { setSelectedSettingsTabAction } from "../store/user/actions";
import {
  sendFinishScribe,
  sendPatientAudioPart,
} from "../store/voiceRecorder/thunks";

const Scribe = () => {
  const { user } = useAppSelector((state) => state.user);
  const { interruptedRecordingAction } = useAppSelector(
    (state) => state.scribe,
  );
  const { selectedScribeNoteTemplate } = useAppSelector(
    (state) => state.noteTemplate,
  );
  const prevSelectedScribe = useRef(null);
  const selectedScribe = useAppSelector(getSelectedScribe);
  const recordingScribe = useAppSelector(getRecordingScribe);

  const [isNewScribe, setIsNewScribe] = useState(false);
  const [notEnoughTranscript, setNotEnoughTranscript] = useState(false);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [searchParams, setSearchParams] = useSearchParams();
  const selectedParamAudioId = searchParams.get("id");

  const {
    showNoteGenerationDelayedWarning,
    hideNoteGenerationDelayedWarning,
    setScribeGenerationDelayed,
  } = useScribeGenerationDelay(selectedScribe);

  useEffect(() => {
    if (selectedParamAudioId === selectedScribe?.audioId) {
      return;
    }

    if (!selectedScribe?.audioId) {
      setSearchParams({}, { replace: true });
      return;
    }

    setSearchParams({ id: selectedScribe?.audioId }, { replace: true });
  }, [selectedParamAudioId, selectedScribe?.audioId, setSearchParams]);

  useEffect(() => {
    if (
      prevSelectedScribe.current &&
      prevSelectedScribe.current?.audioId !== selectedScribe?.audioId &&
      notEnoughTranscript
    ) {
      setNotEnoughTranscript(false);
    }

    if (!selectedScribe?.isGenerating) {
      setScribeGenerationDelayed(selectedScribe?.audioId, false);
    }

    prevSelectedScribe.current = selectedScribe;
  }, [selectedScribe, setScribeGenerationDelayed, notEnoughTranscript]);

  const patient = selectedScribe?.patient;

  const sendAudioPart = useCallback(
    (data: Blob, chunkPosition: number) => {
      dispatch(
        sendPatientAudioPart(
          patient?.patient_id,
          data,
          () => {},
          () => {},
          selectedScribeNoteTemplate
            ? selectedScribeNoteTemplate.note_template_id
            : null,
          chunkPosition,
        ),
      );
    },
    [dispatch, patient?.patient_id, selectedScribeNoteTemplate],
  );

  const finishRecording = () => {
    dispatch(
      sendFinishScribe(
        patient?.patient_id,
        () => {},
        (error: AxiosError) => {
          console.log("error", error);
          if (
            (error.response?.data as FixLater)?.error ===
            ERROR_NOT_ENOUGH_TRANSCRIPT
          ) {
            dispatch(setScribePaused());
            setNotEnoughTranscript(true);
          }

          if (
            (error.response?.data as FixLater)?.error ===
            ERROR_NOTE_GENERATION_DELAYED
          ) {
            setScribeGenerationDelayed(selectedScribe.audioId, true);
          }
        },
      ),
    );
  };

  const {
    mediaRecorder,
    microphones,
    microphoneId,
    audioVolume,
    noAudioDetected,
    setMicrophoneId,
    startRecording,
    togglePause,
    cancelRecording,
    saveRecording,
    isStalled,
    isReadyToRecord,
    readyToRecordErrors,
    requestMicrophonePermissions,
  } = useRecorder(sendAudioPart, finishRecording);

  useNoSoundNotification(
    isReadyToRecord,
    isStalled,
    selectedScribe,
    requestMicrophonePermissions,
  );

  const handleSelectScribe = useCallback(
    (note?: { audioId: string }) => {
      if (
        !note ||
        note.audioId === NOTE_NOT_SELECTED ||
        selectedScribe?.audioId === note.audioId
      ) {
        dispatch(setScribeSelectedAudioId({ audioId: null }));
        return;
      }

      dispatch(setScribeSelectedAudioId({ audioId: note.audioId }));
    },
    [dispatch, selectedScribe?.audioId],
  );

  useEffect(() => {
    return () => {
      dispatch(setScribeSelectedAudioId({ audioId: null }));
      dispatch(setShowScribeNoteTemplate(false));
      dispatch(setInterruptedRecordingAction(null));
    };
  }, [dispatch]);

  useEffect(() => {
    dispatch(getProviderNotes(user.doctor_id));

    const interval = setInterval(() => {
      dispatch(getProviderNotes(user.doctor_id));
    }, 5000);

    return () => {
      clearInterval(interval);
    };
  }, [dispatch, user?.doctor_id]);

  const dynamicStyles = {
    list:
      !selectedScribe && !isNewScribe
        ? "block"
        : "hidden md:flex md:justify-center",
    container: !selectedScribe && !isNewScribe ? "hidden md:grid" : "grid",
  };

  const setPatient = useCallback(
    (patient) => {
      dispatch(
        setScribe({
          audioId: selectedScribe?.audioId,
          scribe: {
            patient,
          },
        }),
      );
    },
    [dispatch, selectedScribe?.audioId],
  );

  const handleAssignPatientId = useCallback(
    (patientId: number | null) => {
      if (!patientId) {
        setPatient(null);
        return;
      }

      dispatch(
        getPatientInfo(patientId, (patient) => {
          setPatient(patient);
        }),
      );
    },
    [dispatch, setPatient],
  );

  const onCancel = useCallback(() => {
    dispatch(setInterruptedRecordingAction(null));

    cancelRecording();
    setNotEnoughTranscript(false);

    if (
      interruptedRecordingAction?.type ===
      SetInterruptedRecordingActionType.NAVIGATION
    ) {
      navigate(interruptedRecordingAction.value);
    } else if (
      interruptedRecordingAction?.type ===
      SetInterruptedRecordingActionType.SETTINGS_NAVIGATION
    ) {
      const { selectedSettingsTab } = store.getState().user;

      if (!selectedSettingsTab) {
        dispatch(setSelectedSettingsTabAction("Profile"));
      }

      navigate(interruptedRecordingAction.value);
    } else if (
      interruptedRecordingAction?.type ===
      SetInterruptedRecordingActionType.NEW_ENCOUNTER
    ) {
      dispatch(setScribeTogglePause());
      dispatch(setScribeSelectedAudioId({ audioId: null }));
      setIsNewScribe(true);
    } else if (
      interruptedRecordingAction?.type ===
      SetInterruptedRecordingActionType.RESUME_ANOTHER_ENCOUNTER
    ) {
      dispatch(setScribePaused());
      dispatch(
        setScribeSelectedAudioId({ audioId: interruptedRecordingAction.value }),
      );
      dispatch(setScribeTogglePause());
      togglePause();
    } else if (
      interruptedRecordingAction?.type ===
      SetInterruptedRecordingActionType.SCRIBE_BACK
    ) {
      setIsNewScribe(false);
    } else if (
      interruptedRecordingAction?.type ===
      SetInterruptedRecordingActionType.PATIENT_PROFILE_OPEN
    ) {
      dispatch(setPathBeforeProfile(Pages.SCRIBE));
      dispatch(
        getPatientInfo(patient.patient_id, (patientInfo) => {
          dispatch(setSelectedPatientInfoAction(patientInfo));
          navigate(`${Pages.PATIENTS}/${patient.patient_id}`);
        }),
      );
    } else if (
      interruptedRecordingAction?.type ===
      SetInterruptedRecordingActionType.RESUME_GENERATED_ENCOUNTER
    ) {
      togglePause();
      dispatch(setScribePaused());
      dispatch(setScribeSelectedAudioId({ audioId: selectedScribe.audioId }));
      dispatch(setScribeResumeGenerated());
      startRecording();
    }
  }, [
    interruptedRecordingAction,
    selectedScribe?.audioId,
    patient,
    startRecording,
    cancelRecording,
    togglePause,
    navigate,
    dispatch,
  ]);

  const onNotEnoughtTranscriptSubmit = useCallback(() => {
    setNotEnoughTranscript(false);
    dispatch(setScribeTogglePause());
    togglePause();
  }, [dispatch, togglePause]);

  const onSetNewScribe = useCallback(() => {
    if (recordingScribe) {
      dispatch(
        setInterruptedRecordingAction({
          type: SetInterruptedRecordingActionType.NEW_ENCOUNTER,
        }),
      );
      return;
    }

    dispatch(setScribeSelectedAudioId({ audioId: null }));
    setIsNewScribe(true);
  }, [dispatch, recordingScribe]);

  const onBack = useCallback(() => {
    console.log("onBack");
    if (recordingScribe) {
      dispatch(
        setInterruptedRecordingAction({
          type: SetInterruptedRecordingActionType.SCRIBE_BACK,
        }),
      );
      return;
    }

    console.log("cancelling recording");

    cancelRecording();
    setIsNewScribe(false);
  }, [dispatch, recordingScribe, cancelRecording]);

  const onResumeRecording = useCallback(() => {
    if (recordingScribe) {
      dispatch(
        setInterruptedRecordingAction({
          type: SetInterruptedRecordingActionType.RESUME_GENERATED_ENCOUNTER,
        }),
      );
      return;
    }

    dispatch(setScribeResumeGenerated());
    startRecording();
  }, [dispatch, recordingScribe, startRecording]);

  return (
    <TimerProvider>
      <div className="h-full w-full md:grid md:grid-cols-[300px,1fr] overflow-hidden">
        <div
          className={`h-full md:block md:h-screen overflow-hidden px-5 md:px-4 bg-gray-background ${dynamicStyles.list}`}
        >
          <ScribeList
            handleSelectScribe={handleSelectScribe}
            setIsNewScribe={onSetNewScribe}
          />
        </div>
        <div
          className={`w-full h-full md:px-6 bg-white overflow-hidden grid-rows-tab-layout ${dynamicStyles.container}`}
        >
          <ScribeContainer
            patient={patient}
            mediaRecorder={mediaRecorder}
            microphoneId={microphoneId}
            microphones={microphones}
            audioVolume={audioVolume}
            noAudioDetected={noAudioDetected}
            setMicrophoneId={setMicrophoneId}
            startRecording={startRecording}
            saveRecording={saveRecording}
            onBack={onBack}
            onCancel={onCancel}
            handleAssignPatientId={handleAssignPatientId}
            togglePause={togglePause}
            isReadyToRecord={isReadyToRecord}
            readyToRecordErrors={readyToRecordErrors}
            requestMicrophonePermissions={requestMicrophonePermissions}
            onResumeRecording={onResumeRecording}
          />
        </div>
        {interruptedRecordingAction && (
          <FinishLaterModal
            onClose={() => {
              dispatch(setInterruptedRecordingAction(null));
            }}
            onConfirm={onCancel}
          />
        )}
        {notEnoughTranscript && (
          <NotEnoughTranscript
            onSubmit={onNotEnoughtTranscriptSubmit}
            onCancel={onCancel}
          />
        )}
      </div>
      {isStalled &&
        (recordingScribe?.isRecording || !recordingScribe?.isRecording) && (
          <RecordingContextStalled
            // onCancel={() => {
            //   resetStalled();
            //   handleTogglePause();
            // }}
            // onSubmit={() => {
            //   resetStalled();
            //   cancelRecording();
            // }}
            onSubmit={() => window.location.reload()}
          />
        )}
      {showNoteGenerationDelayedWarning && (
        <GenerationDelayed
          onCancel={hideNoteGenerationDelayedWarning}
          onSubmit={hideNoteGenerationDelayedWarning}
        />
      )}
    </TimerProvider>
  );
};

export default Scribe;
