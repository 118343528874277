import { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { ReactComponent as PencilIcon } from "../../assets/icons/pencil.svg";
import Button from "../../components/Basic/Button";
import FacilityMultipleSelect from "../../components/Basic/FacilityMultipleSelect";
import Input from "../../components/Basic/Input";
import InputBlock from "../../components/Basic/InputBlock";
import CountryPhoneInput from "../../components/Basic/PhoneInput/CountryPhoneInput";
import Select from "../../components/Basic/Select";
import AvatarModal from "../../components/Settings/Profile/AvatarModal";
import {
  countryCodes,
  occupations,
  passwordValidationRegEx,
} from "../../helpers/constants";
import { type RootState, useAppDispatch, useAppSelector } from "../../store";
import { getConversation } from "../../store/conversation/thunks";
import type { Facility } from "../../store/facility/facilityReducer";
import { updateUserFacilities } from "../../store/facility/thunks";
import { updatePassword, updateUser } from "../../store/user/thunks";

export interface FormValues {
  first_name: string;
  last_name: string;
  occupation: string;
  role_id: number;
  display_name: string;
  phone_number: string;
  email: string;
  new_email: string;
  old_password: string;
  new_password: string;
}

const ProfileSettings: React.FC = () => {
  const { user, userSearchValue, roleOptions, onlyScribeFeature } =
    useAppSelector((state: RootState) => state.user);
  const { userFacilities } = useAppSelector(
    (state: RootState) => state.facility,
  );
  const { currentConversation } = useAppSelector(
    (state: RootState) => state.conversation,
  );

  const [resetPassword, setResetPassword] = useState<boolean>(false);
  const [changeEmail, setChangeEmail] = useState<boolean>(false);
  const [showInputPosition, setShowInputPosition] = useState<boolean>(false);
  const [positionOther, setPositionOther] = useState<string>("");
  const [positionError, setPositionError] = useState<{
    message: string;
  } | null>(null);
  const [openAvatarModal, setOpenAvatarModal] = useState<boolean>(false);
  const [editMode, setEditMode] = useState<boolean>(false);
  const [selectedFacilities, setSelectedFacilities] = useState<Facility[]>(
    userFacilities[user.doctor_id] || [],
  );
  const [facilitiesError, setFacilitiesError] = useState<string | null>(null);

  const {
    register,
    control,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm<FormValues>({
    defaultValues: {
      first_name: user.first_name,
      last_name: user.last_name,
      occupation: user.occupation,
      role_id: user.role_id,
      display_name: user.display_name,
      phone_number: user.phone_number,
      email: user.email,
      new_email: "",
      old_password: "",
      new_password: "",
    },
  });

  const dispatch = useAppDispatch();
  const watchPosition = watch("occupation");

  useEffect(() => {
    setValue("first_name", user.first_name);
    setValue("last_name", user.last_name);
    setValue("occupation", user.occupation);
    setValue("role_id", user.role_id);
    setValue("display_name", user.display_name);
    setValue("phone_number", user.phone_number);
    setValue("email", user.email);
  }, [user, setValue]);

  useEffect(() => {
    setSelectedFacilities(userFacilities[user.doctor_id] || []);
  }, [userFacilities, user.doctor_id]);

  useEffect(() => {
    if (watchPosition === "other") {
      setShowInputPosition(true);
    } else {
      setPositionOther("");
      setShowInputPosition(false);
    }
  }, [watchPosition]);

  useEffect(() => {
    if (showInputPosition) {
      setPositionOther(user.occupation);
    } else {
      setPositionOther("");
    }
  }, [showInputPosition, user.occupation]);

  useEffect(() => {
    if (user) {
      const isPositionListed = occupations.some(
        (option) => option.value === user.occupation,
      );
      if (!isPositionListed) {
        setValue("occupation", "other");
        setShowInputPosition(true);
        setPositionOther(user.occupation);
      }
    }
  }, [user, setValue]);

  const checkInputPositionOther = useCallback((): void => {
    if (watchPosition === "other" && !positionOther.trim()) {
      setPositionError({ message: "Position is required" });
    } else {
      setPositionError(null);
    }
  }, [watchPosition, positionOther]);

  useEffect(() => {
    checkInputPositionOther();
  }, [checkInputPositionOther]);

  const onSavePicture = (picture: string): void => {
    dispatch(
      updateUser(
        user.doctor_id,
        { profile_picture: picture },
        () => {
          if (currentConversation && !onlyScribeFeature) {
            dispatch(getConversation(currentConversation.conversation_id));
          }
          setOpenAvatarModal(false);
        },
        userSearchValue,
      ),
    );
  };

  const onSubmit = (data: FormValues): void => {
    if (data.occupation === "other") {
      data.occupation = positionOther.trim();
    }

    const updatedFields = {
      first_name: data.first_name,
      last_name: data.last_name,
      occupation: data.occupation,
      display_name: data.display_name,
      phone_number: data.phone_number,
      email: changeEmail && data.new_email ? data.new_email : data.email,
    };

    if (!positionError) {
      if (
        user.role_id === 1 &&
        selectedFacilities.length === 0 &&
        !onlyScribeFeature
      ) {
        setFacilitiesError("Please select at least one clinic");
        return;
      }

      if (user.role_id === 1 && !onlyScribeFeature) {
        dispatch(
          updateUserFacilities(
            user.doctor_id,
            selectedFacilities.map((facility) => facility.customer_id),
          ),
        );
      }

      dispatch(
        updateUser(
          user.doctor_id,
          updatedFields,
          () => {
            if (currentConversation && !onlyScribeFeature) {
              dispatch(getConversation(currentConversation.conversation_id));
            }
          },
          userSearchValue,
        ),
      );

      if (resetPassword) {
        dispatch(updatePassword(data.old_password, data.new_password));
      }

      setEditMode(false);
      setChangeEmail(false);
      setResetPassword(false);
      setValue("old_password", "");
      setValue("new_password", "");
      setValue("new_email", "");
    }
  };

  return (
    <div className="h-full w-full md:grid overflow-y-auto bg-gray-background overflow-hidden">
      <div className="h-full overflow-y-auto scrollbar p-4 md:p-6 lg:pl-8">
        <form
          className="flex flex-col max-w-5xl"
          onSubmit={handleSubmit(onSubmit)}
          id="user-profile-form"
        >
          <h2 className="text-lg md:text-xl font-bold mb-5 md:mb-4">Profile</h2>

          <button
            type="button"
            onClick={(e) => {
              e.preventDefault();
              setOpenAvatarModal(true);
            }}
            className="bg-gray-200 rounded-full relative mb-5 md:mb-6 w-30 h-30 flex-none mx-auto md:mx-0"
          >
            {user.profile_picture ? (
              <img
                src={user.profile_picture}
                alt="Avatar"
                className="rounded-full min-w-full min-h-full object-cover"
              />
            ) : (
              <p className="uppercase text-4xl text-primary-blue">
                {(user.first_name || "").charAt(0)}
                {(user.last_name || "").charAt(0)}
              </p>
            )}
            <div className="absolute bottom-0.5 right-0.5 rounded-full bg-primary-blue flex items-center justify-center w-8 h-8">
              <PencilIcon width={20} height={20} />
            </div>
          </button>

          <div className="space-y-5 md:space-y-6">
            <InputBlock title="Name" subtitle="Full name">
              <Input
                register={register}
                name="first_name"
                placeholder="Preferred First Name"
                type="text"
                required="First name can't be empty"
                validate={(value: string) => {
                  if (value.trim()) {
                    return true;
                  }
                  return "First name can't be empty";
                }}
                error={errors.first_name}
                disabled={!editMode}
              />
              <Input
                register={register}
                name="last_name"
                placeholder="Last Name"
                type="text"
                required="Last name can't be empty"
                validate={(value: string) => {
                  if (value.trim()) {
                    return true;
                  }
                  return "Last name can't be empty";
                }}
                error={errors.last_name}
                disabled={!editMode}
              />
            </InputBlock>

            <InputBlock
              title="Display Name"
              subtitle="Name with titles displayed to patient"
            >
              <Input
                register={register}
                name="display_name"
                placeholder="Display Name"
                type="text"
                className="col-span-2"
                required="Display name can't be empty"
                validate={(value: string) => {
                  if (value.trim()) {
                    return true;
                  }
                  return "Display name can't be empty";
                }}
                error={errors.display_name}
                disabled={!editMode}
              />
            </InputBlock>

            <InputBlock title="Position" subtitle="Job title">
              <div className="col-span-2 flex flex-col space-y-3">
                <Select
                  name="occupation"
                  placeholder="Select Position"
                  options={occupations}
                  register={register}
                  className="col-span-2"
                  required="Position can't be empty"
                  error={errors.occupation}
                  disabled={!editMode}
                />
                {showInputPosition && (
                  <Input
                    value={positionOther}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      setPositionOther(e.target.value)
                    }
                    placeholder="Enter your position"
                    required="Position is required"
                    error={positionError}
                    disabled={!editMode}
                  />
                )}
              </div>
            </InputBlock>

            <InputBlock title="Role">
              <Select
                name="role_id"
                placeholder="Select role"
                className="col-span-2"
                options={roleOptions}
                register={register}
                disabled
              />
            </InputBlock>

            <InputBlock title="Phone Number" subtitle="Phone Number">
              <CountryPhoneInput
                name="phone_number"
                className="col-span-2"
                countries={countryCodes}
                control={control}
                error={errors.phone_number}
                disabled={!editMode}
              />
            </InputBlock>

            <InputBlock
              title="Email Address"
              subtitle={
                <button
                  type="button"
                  className={editMode ? "text-primary-blue" : "text-tertiary"}
                  onClick={(e) => {
                    e.preventDefault();
                    setChangeEmail(!changeEmail);
                  }}
                  disabled={!editMode}
                >
                  Change Email
                </button>
              }
            >
              <>
                <Input
                  register={register}
                  name="email"
                  placeholder="Email address"
                  type="email"
                  error={errors.email}
                  validationPattern={{
                    value: /\S+@\S+\.\S+/,
                    message: "Enter a valid email address",
                  }}
                  className={changeEmail ? "" : "col-span-2"}
                  disabled
                />
                {changeEmail && (
                  <Input
                    register={register}
                    name="new_email"
                    placeholder="New email"
                    type="email"
                    error={errors.new_email}
                    validationPattern={{
                      value: /\S+@\S+\.\S+/,
                      message: "Enter a valid email address",
                    }}
                  />
                )}
              </>
            </InputBlock>

            <InputBlock
              title="Password"
              subtitle={
                <button
                  type="button"
                  className={editMode ? "text-rose-550" : "text-tertiary"}
                  onClick={(e) => {
                    e.preventDefault();
                    setResetPassword(!resetPassword);
                  }}
                  disabled={!editMode}
                >
                  Reset Password
                </button>
              }
            >
              {resetPassword ? (
                <>
                  <Input
                    register={register}
                    name="old_password"
                    placeholder="Old password"
                    type="password"
                    required="Old password can't be blank"
                    error={errors.old_password}
                    autoComplete="new-password"
                    disabled={!resetPassword}
                  />
                  <Input
                    register={register}
                    name="new_password"
                    placeholder="New password"
                    type="password"
                    required="New password can't be blank"
                    error={errors.new_password}
                    minLength={{
                      value: 8,
                      message: "Must be at least 8 characters",
                    }}
                    validationPattern={{
                      value: passwordValidationRegEx,
                      message:
                        "Use a minimum of 8 characters with a mix of letters, numbers & symbols",
                    }}
                    autoComplete="new-password"
                    disabled={!resetPassword}
                  />
                </>
              ) : (
                <Input
                  placeholder="Password"
                  type="password"
                  className="col-span-2"
                  value="**********"
                  disabled
                />
              )}
            </InputBlock>

            {!onlyScribeFeature && (
              <InputBlock title="Clinics">
                <FacilityMultipleSelect
                  className="col-span-2"
                  displaySelect={user.role_id === 1 && editMode}
                  selectedFacilities={selectedFacilities}
                  setSelectedFacilities={setSelectedFacilities}
                  error={facilitiesError}
                  setError={setFacilitiesError}
                />
              </InputBlock>
            )}
          </div>

          <div className="mt-6 ml-auto flex-none w-full md:w-36">
            <Button
              className="font-semibold w-full"
              form="user-profile-form"
              variant="blue-light"
              onClick={(e) => {
                if (!editMode) {
                  e.preventDefault();
                  setEditMode(true);
                }
              }}
              type="submit"
            >
              {editMode ? "Save Changes" : "Edit User"}
            </Button>
          </div>
        </form>

        <AvatarModal
          open={openAvatarModal}
          onClose={() => setOpenAvatarModal(false)}
          onSave={onSavePicture}
        />
      </div>
    </div>
  );
};

export default ProfileSettings;
