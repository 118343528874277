import { useSelector } from "react-redux";
import NotesGeneral from "../../components/Settings/Notes/NotesGeneral";
import NotesTemplates from "../../components/Settings/Notes/Templates/NotesTemplates";
import SettingsContainer from "../../components/Settings/SettingsContainer";
import { NotesTabs } from "../../helpers/constants";
// import NotesDotsPhrases from "../../components/Settings/Notes/NotesDotsPhrases";
// import NotesForms from "../../components/Settings/Notes/Forms/NotesForms";

const NotesSettings = () => {
  const { selectedNotesTab } = useSelector((state) => state.user);

  return (
    <SettingsContainer>
      {selectedNotesTab === NotesTabs.GENERAL && (
        <div className="xl:max-w-2xl">
          <NotesGeneral />
        </div>
      )}
      {selectedNotesTab === NotesTabs.TEMPLATES && (
        <div className="xl:max-w-6xl h-full md:overflow-hidden">
          <NotesTemplates />
        </div>
      )}
      {/*{selectedNotesTab === NotesTabs.FORMS && (*/}
      {/*  <div className="xl:max-w-6xl">*/}
      {/*    <NotesForms />*/}
      {/*  </div>*/}
      {/*)}*/}
      {/*{selectedNotesTab === NotesTabs.DOT_PHRASES && (*/}
      {/*  <div className="xl:max-w-2xl">*/}
      {/*    <NotesDotsPhrases />*/}
      {/*  </div>*/}
      {/*)}*/}
    </SettingsContainer>
  );
};

export default NotesSettings;
