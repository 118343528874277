import Skeleton from "@mui/material/Skeleton";
import { debounce } from "@mui/material/utils";
import dayjs from "dayjs";
import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { ReactComponent as ArrowIcon } from "../../assets/icons/arrow-down.svg";
import { ReactComponent as SortIcon } from "../../assets/icons/arrows-up-down.svg";
import { ReactComponent as DotsIcon } from "../../assets/icons/ellipsis-horizontal.svg";
import { ReactComponent as ExportIcon } from "../../assets/icons/export.svg";
import { ReactComponent as GenerateIcon } from "../../assets/icons/file-check.svg";
import { ReactComponent as SortArrowIcon } from "../../assets/icons/round-sort-vertical.svg";
import { ReactComponent as SearchIcon } from "../../assets/icons/search.svg";
import { campaignTypes, rowsPerPage } from "../../helpers/constants";
import { selectInputText } from "../../helpers/helpers";
import {
  setBillingSearchValueAction,
  setCurrentDateStampAction,
  setSelectedBillingTypeAction,
} from "../../store/billingMetrics/actions";
import {
  exportBillingData,
  generateAllNotes,
  getBillingPatients,
} from "../../store/billingMetrics/thunks";
import Button from "../Basic/Button";
import Loader from "../Basic/Loader";
import ModalMobile from "../Basic/ModalMobile";
import Select from "../Basic/Select";
import YearMonthPicker from "../Basic/YearMonthPicker";

// styles
const numberCardClasses =
  "w-full bg-white rounded-lg p-2 md:p-4 border border-gray-foreground md:space-y-1";
const numberTitleClasses = "text-xs md:text-sm font-semibold";
const numberClasses = "text-sm md:text-xl font-bold text-primary-blue";

const SortButton = ({ name, label, sortedBy, sortingDirection, onSort }) => {
  return (
    <button
      type="button"
      className="w-full whitespace-nowrap py-4 flex items-center space-x-2 justify-between"
      onClick={() => {
        if (sortedBy !== name) {
          onSort(name, "asc");
        } else if (sortedBy === name && sortingDirection === "asc") {
          onSort(name, "desc");
        } else {
          onSort(null, null);
        }
      }}
    >
      <p>{label}</p>
      <div className="flex space-x-2">
        <SortArrowIcon
          width="20"
          height="20"
          stroke={
            sortedBy === name && sortingDirection === "asc"
              ? "#2970FF"
              : "#667085"
          }
        />
        <SortArrowIcon
          width="20"
          height="20"
          className="rotate-180"
          stroke={
            sortedBy === name && sortingDirection === "desc"
              ? "#2970FF"
              : "#667085"
          }
        />
      </div>
    </button>
  );
};

const MetricsTableHeader = ({ onSort }) => {
  const {
    loading,
    currentDateStamp,
    selectedBillingType,
    selectedCptCode,
    currPage,
    exportLoading,
    sortedBy,
    billingSearchValue,
    billingNumbers,
    sortingDirection,
    allNotesGenerated,
  } = useSelector((state) => state.billingMetrics);
  const { campaigns } = useSelector((state) => state.campaign);
  const [selectedDate, setSelectedDate] = useState(dayjs().startOf("month"));
  const [hover, setHover] = useState(false);
  const [openActionModal, setOpenActionModal] = useState(false);
  const [openSortModal, setOpenSortModal] = useState(false);
  const [openBillingTypeModal, setOpenBillingTypeModal] = useState(false);
  const [notesGenerating, setNotesGenerating] = useState(false);

  const dispatch = useDispatch();
  const isDesktop = useMediaQuery({ minWidth: 768 });

  const billingTypeOptions = useMemo(() => {
    const types = [];
    if (campaigns?.length > 0) {
      campaigns.forEach((campaign) => {
        const type = campaignTypes.find(
          (type) => type.value === campaign.campaign_type,
        );
        if (type) {
          types.push({
            value: campaign.campaign_type,
            text: type.text,
          });
        }
      });
    }
    return [{ value: "all", text: "All" }, ...types];
  }, [campaigns]);

  useEffect(() => {
    if (allNotesGenerated) {
      setNotesGenerating(false);
    }
  }, [allNotesGenerated]);

  useEffect(() => {
    if (currentDateStamp) {
      setNotesGenerating(false);
      setSelectedDate(dayjs(currentDateStamp));
    }
  }, [currentDateStamp]);

  const onSelectDate = (date) => {
    setSelectedDate(date);
    dispatch(setCurrentDateStampAction(date.format("YYYY-MM-DD")));
  };

  const onSelectType = (type) => {
    dispatch(setSelectedBillingTypeAction(type));
    dispatch(
      getBillingPatients(
        currentDateStamp,
        rowsPerPage * currPage,
        billingSearchValue,
        type,
        selectedCptCode,
        sortedBy,
        sortingDirection,
      ),
    );
  };

  const onExport = () => {
    dispatch(exportBillingData(currentDateStamp));
    if (!isDesktop) {
      setOpenActionModal(false);
    }
  };

  const handleSearchInput = (e) => {
    dispatch(setBillingSearchValueAction(e.target.value));
    debouncedSearch(e.target.value);
  };

  const generateNotes = () => {
    setNotesGenerating(true);
    dispatch(generateAllNotes(currentDateStamp));
    if (!isDesktop) {
      setOpenActionModal(false);
    }
  };

  const debouncedSearch = useMemo(
    () =>
      debounce((inputValue) => {
        dispatch(
          getBillingPatients(
            currentDateStamp,
            rowsPerPage * currPage,
            inputValue,
            selectedBillingType,
            selectedCptCode,
            sortedBy,
            sortingDirection,
          ),
        );
      }, 200),
    [
      dispatch,
      currPage,
      currentDateStamp,
      sortedBy,
      sortingDirection,
      selectedCptCode,
      selectedBillingType,
    ],
  );

  return (
    <div className="mb-3 md:mb-5">
      <div className="grid grid-cols-2 gap-3 mb-3 md:mb-6">
        <div className={numberCardClasses}>
          {loading || !billingNumbers ? (
            <>
              <Skeleton
                animation="wave"
                variant="rounded"
                width={80}
                height={isDesktop ? 16 : 12}
                sx={{
                  bgcolor: "#EAEDF3",
                  borderRadius: "16px",
                  marginBottom: isDesktop ? "11px" : "8px",
                }}
              />
              <Skeleton
                animation="wave"
                variant="rounded"
                width={isDesktop ? 140 : 42}
                height={isDesktop ? 24 : 14}
                sx={{
                  bgcolor: "#EAEDF3",
                  borderRadius: "24px",
                }}
              />
            </>
          ) : (
            <>
              <p className={numberTitleClasses}>Enrolled</p>
              <p className={numberClasses}>{billingNumbers?.enrolled || 0}</p>
            </>
          )}
        </div>
        <div className={numberCardClasses}>
          {loading || !billingNumbers ? (
            <>
              <Skeleton
                animation="wave"
                variant="rounded"
                width={80}
                height={isDesktop ? 16 : 12}
                sx={{
                  bgcolor: "#EAEDF3",
                  borderRadius: "16px",
                  marginBottom: isDesktop ? "11px" : "8px",
                }}
              />
              <Skeleton
                animation="wave"
                variant="rounded"
                width={isDesktop ? 140 : 42}
                height={isDesktop ? 24 : 14}
                sx={{
                  bgcolor: "#EAEDF3",
                  borderRadius: "24px",
                }}
              />
            </>
          ) : (
            <>
              <p className={numberTitleClasses}>Ready To Bill</p>
              <p className={numberClasses}>
                {billingNumbers?.readyToBill || 0}
              </p>
            </>
          )}
        </div>
      </div>

      <div className="flex items-center justify-between space-x-3 relative z-20 text-sm font-semibold select-none">
        <div className="relative w-full">
          <input
            value={billingSearchValue}
            onClick={selectInputText}
            onChange={handleSearchInput}
            className="rounded-lg h-10 text-sm outline-none w-full pl-11 placeholder:text-gray-400"
            placeholder="Search patient..."
          />
          <SearchIcon
            className="absolute top-2.5 left-4"
            width="20"
            height="20"
          />
        </div>

        <div className="flex flex-none">
          {isDesktop ? (
            <>
              <YearMonthPicker
                startDate={selectedDate}
                onSelectDate={onSelectDate}
              />

              <Select
                className="w-24 lg:w-32 ml-3"
                selectClassName="text-tertiary font-semibold text-base"
                borderColor="gray-foreground"
                placeholder="Select Billing Type"
                name="billingType"
                options={billingTypeOptions || []}
                value={selectedBillingType}
                onChange={(e) => onSelectType(e.target.value)}
              />

              <button
                type="button"
                className="h-10 lg:w-50 whitespace-nowrap ml-3 px-3 flex flex-none items-center justify-center
                  space-x-2 bg-gray-foreground rounded-lg text-base font-semibold text-tertiary
                  hover:bg-primary-blue-light hover:text-primary-blue
                  disabled:hover:bg-gray-foreground disabled:hover:text-tertiary"
                onMouseEnter={() => {
                  if (!allNotesGenerated) {
                    setHover(true);
                  }
                }}
                onMouseLeave={() => setHover(false)}
                onClick={generateNotes}
                disabled={allNotesGenerated || notesGenerating}
              >
                <GenerateIcon
                  width="21"
                  height="20"
                  fill={hover ? "#2970FF" : "#121212"}
                  stroke={hover ? "#2970FF" : "#121212"}
                />
                <p className="hidden lg:flex">
                  {allNotesGenerated
                    ? "Notes Generated"
                    : notesGenerating
                      ? "Generating Notes"
                      : "Generate All Notes"}
                </p>
              </button>

              <Button
                type="button"
                variant="gray-light"
                className="ml-3"
                onClick={onExport}
                disabled={exportLoading}
              >
                {exportLoading ? (
                  <Loader size={5} />
                ) : (
                  <ExportIcon width="20" height="20" stroke="#667085" />
                )}
                <p className="hidden lg:flex ml-2 text-base">Export</p>
              </Button>
            </>
          ) : (
            <>
              <Button
                type="button"
                variant="gray-light"
                size="unset"
                className="w-10 h-10"
                onClick={() => setOpenSortModal(true)}
              >
                <SortIcon
                  width="20"
                  height="20"
                  stroke={
                    sortedBy && sortedBy !== "null" ? "#2970FF" : "#667085"
                  }
                />
              </Button>

              <Button
                type="button"
                variant="gray-light"
                size="unset"
                className="w-10 h-10 ml-3"
                onClick={() => setOpenActionModal(true)}
              >
                <DotsIcon width="20" height="20" />
              </Button>
            </>
          )}
        </div>
      </div>

      {!isDesktop && (
        <div className="flex mt-3">
          <YearMonthPicker
            startDate={selectedDate}
            onSelectDate={onSelectDate}
            className="w-full justify-center bg-white py-2 px-4 rounded-lg border border-gray-foreground text-base h-10"
          />
          <Button
            type="button"
            variant="gray"
            className="w-24 flex-none items-center justify-between ml-3"
            onClick={() => setOpenBillingTypeModal(true)}
          >
            <p>
              {
                billingTypeOptions?.find(
                  (option) => option.value === selectedBillingType,
                )?.text
              }
            </p>
            <ArrowIcon width="10" height="7" />
          </Button>
        </div>
      )}

      {!isDesktop && (
        <ModalMobile
          open={openActionModal}
          childHeight={105}
          header="Select Action"
          onClickAway={() => setOpenActionModal(false)}
        >
          <div className="w-full flex flex-col divide-y border-gray-foreground text-sm font-medium text-primary">
            <button
              type="button"
              className="w-full whitespace-nowrap py-4 flex items-center space-x-2"
              onClick={generateNotes}
              disabled={allNotesGenerated || notesGenerating}
            >
              <GenerateIcon
                width="21"
                height="20"
                fill="#121212"
                stroke="#121212"
              />
              <p>
                {allNotesGenerated
                  ? "Notes Generated"
                  : notesGenerating
                    ? "Generating Notes"
                    : "Generate All Notes"}
              </p>
            </button>

            <button
              type="button"
              className="w-full whitespace-nowrap py-4 flex items-center space-x-2"
              onClick={onExport}
              disabled={exportLoading}
            >
              {exportLoading ? (
                <Loader size={5} />
              ) : (
                <ExportIcon width="20" height="20" stroke="#121212" />
              )}
              <p>Export</p>
            </button>
          </div>
        </ModalMobile>
      )}

      {!isDesktop && (
        <ModalMobile
          open={openSortModal}
          childHeight={230}
          header="Sort"
          onClickAway={() => setOpenSortModal(false)}
          className=""
        >
          <div className="w-full flex flex-col">
            <div className="w-full flex flex-col divide-y border-gray-foreground text-sm font-medium text-primary px-4">
              <SortButton
                name="code"
                label="CPT Codes"
                sortedBy={sortedBy}
                sortingDirection={sortingDirection}
                onSort={onSort}
              />
              <SortButton
                name="billing"
                label="Billing"
                sortedBy={sortedBy}
                sortingDirection={sortingDirection}
                onSort={onSort}
              />
              <SortButton
                name="time"
                label="Total Time"
                sortedBy={sortedBy}
                sortingDirection={sortingDirection}
                onSort={onSort}
              />
            </div>
            <div className="w-full bg-gray-background py-4 px-5 flex space-x-4 text-sm font-semibold">
              <Button
                type="button"
                variant="gray"
                className="w-full"
                onClick={() => {
                  onSort(null, null);
                  setOpenSortModal(false);
                }}
              >
                Cancel
              </Button>
              <Button
                type="button"
                onClick={() => setOpenSortModal(false)}
                className="w-full"
              >
                Apply
              </Button>
            </div>
          </div>
        </ModalMobile>
      )}

      {!isDesktop && (
        <ModalMobile
          open={openBillingTypeModal}
          childHeight={210}
          header="Billing Type"
          onClickAway={() => setOpenBillingTypeModal(false)}
        >
          <div className="w-full flex flex-col divide-y border-gray-foreground text-sm font-medium text-primary">
            {billingTypeOptions?.map((option) => (
              <button
                type="button"
                key={option.value}
                className="w-full whitespace-nowrap py-4 text-left"
                onClick={() => {
                  onSelectType(option.value);
                  setOpenBillingTypeModal(false);
                }}
              >
                {option.text}
              </button>
            ))}
          </div>
        </ModalMobile>
      )}
    </div>
  );
};

export default MetricsTableHeader;
