import Skeleton from "@mui/material/Skeleton";
import { ReactComponent as DotsIcon } from "../../assets/icons/ellipsis-horizontal.svg";
import UserTable from "../Settings/Organization/UserTable";

const UserTableSkeleton = ({
  rows = 15,
  bgColor = "#EAEDF3",
  animation = "wave",
}) => {
  const columns = [
    { label: "Display Name", accessor: "display_name" },
    { label: "Email Address", accessor: "email" },
    { label: "Role", accessor: "role" },
    { label: "Position", accessor: "occupation" },
    { label: "Status", accessor: "status" },
    { label: "", accessor: "actions" },
  ];

  const getTableData = () => {
    const tableData = [];
    for (let i = 0; i < rows; i++) {
      tableData.push({
        display_name: (
          <div className="flex items-center space-x-2">
            <Skeleton
              animation={animation}
              variant="circular"
              width={32}
              height={32}
              sx={{ bgcolor: bgColor }}
            />
            <Skeleton
              animation={animation}
              variant="rounded"
              width={80}
              height={16}
              sx={{ bgcolor: bgColor, borderRadius: "16px" }}
            />
          </div>
        ),
        email: (
          <Skeleton
            animation={animation}
            variant="rounded"
            width={160}
            height={16}
            sx={{ bgcolor: bgColor, borderRadius: "16px" }}
          />
        ),
        role: (
          <Skeleton
            animation={animation}
            variant="rounded"
            width={70}
            height={16}
            sx={{ bgcolor: bgColor, borderRadius: "16px" }}
          />
        ),
        occupation: (
          <Skeleton
            animation={animation}
            variant="rounded"
            width={70}
            height={16}
            sx={{ bgcolor: bgColor, borderRadius: "16px" }}
          />
        ),
        status: (
          <Skeleton
            animation={animation}
            variant="rounded"
            width={70}
            height={16}
            sx={{ bgcolor: bgColor, borderRadius: "16px" }}
          />
        ),
        actions: (
          <div className="flex items-center justify-center w-8 h-8 rounded-md">
            <DotsIcon width="20" height="20" />
          </div>
        ),
      });
    }
    return tableData;
  };

  return (
    <div className="grid w-full">
      <UserTable
        columns={columns}
        data={getTableData()}
        className="hidden md:block"
      />

      <div className="block md:hidden space-y-3">
        {[...Array(rows)].map((_, index) => (
          <div
            key={`user-${index}`}
            className="p-4 rounded-xl border-b border-gray-foreground space-y-4 bg-white"
          >
            <div className="flex items-center justify-between mb-1">
              <div className="flex items-center space-x-2">
                <Skeleton
                  animation={animation}
                  variant="circular"
                  width={32}
                  height={32}
                  sx={{ bgcolor: bgColor }}
                />
                <Skeleton
                  animation={animation}
                  variant="rounded"
                  width={80}
                  height={16}
                  sx={{ bgcolor: bgColor, borderRadius: "16px" }}
                />
              </div>
              <div className="flex items-center justify-center w-8 h-8 rounded-md">
                <DotsIcon width="20" height="20" />
              </div>
            </div>
            <div className="grid grid-cols-2 divide-x border-gray-foreground">
              <div className="flex flex-col space-y-2 font-medium pr-3">
                <Skeleton
                  animation={animation}
                  variant="rounded"
                  width={52}
                  height={12}
                  sx={{ bgcolor: bgColor, borderRadius: "12px" }}
                />
                <Skeleton
                  animation={animation}
                  variant="rounded"
                  width={100}
                  height={14}
                  sx={{ bgcolor: bgColor, borderRadius: "14px" }}
                />
              </div>
              <div className="flex flex-col space-y-2 font-medium truncate pl-3">
                <Skeleton
                  animation={animation}
                  variant="rounded"
                  width={52}
                  height={12}
                  sx={{ bgcolor: bgColor, borderRadius: "12px" }}
                />
                <Skeleton
                  animation={animation}
                  variant="rounded"
                  width={100}
                  height={14}
                  sx={{ bgcolor: bgColor, borderRadius: "14px" }}
                />
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default UserTableSkeleton;
