import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Pages } from "../../../helpers/constants";
import {
  setRegisteredEmailAddress,
  setVerificationCode,
} from "../../../store/auth/actions";
import FirstStepForm from "./FirstStepForm";
import SecondStepForm from "./SecondStepForm";

const OnboardingForm = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    onboarding: { practiceInfo },
    signUp,
  } = useSelector((state) => state.auth);

  useEffect(() => {
    if (!signUp.verificationCode) {
      navigate(Pages.LOGIN);
    }
  }, [navigate, signUp.verificationCode]);

  useEffect(() => {
    return () => {
      dispatch(setRegisteredEmailAddress(""));
      dispatch(setVerificationCode(""));
    };
  }, [dispatch]);

  return (
    <>
      <div className="flex gap-4 py-2 justify-center">
        {[1, 2].map((step) => (
          <div
            key={`step ${step}`}
            className={`w-8 h-[6px] md:w-[100px] rounded-full ${
              (practiceInfo && step === 2) || (!practiceInfo && step === 1)
                ? "bg-[#75A4FE]"
                : "bg-primary-blue-light"
            }`}
          />
        ))}
      </div>
      {!practiceInfo ? <FirstStepForm /> : <SecondStepForm />}
    </>
  );
};

export default OnboardingForm;
