import { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { occupations } from "../../../../helpers/constants";
import { getConversation } from "../../../../store/conversation/thunks";
import { updateUserFacilities } from "../../../../store/facility/thunks";
import { updateUser } from "../../../../store/user/thunks";
import Button from "../../../Basic/Button";
import FacilityMultipleSelect from "../../../Basic/FacilityMultipleSelect";
import Input from "../../../Basic/Input";
import Select from "../../../Basic/Select";
import UserPictureName from "./UserPictureName";

const EditUserForm = ({ className = "", onClose, selectedUser }) => {
  const { roleOptions, userSearchValue, user } = useSelector(
    (state) => state.user,
  );
  const { currentConversation } = useSelector((state) => state.conversation);
  const { userFacilities } = useSelector((state) => state.facility);
  const [showInputPosition, setShowInputPosition] = useState(false);
  const [positionOther, setPositionOther] = useState("");
  const [positionError, setPositionError] = useState(null);
  const [selectedFacilities, setSelectedFacilities] = useState(
    userFacilities[selectedUser.doctor_id] || [],
  );
  const [facilitiesError, setFacilitiesError] = useState(null);
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      display_name: selectedUser.display_name,
      role_id: selectedUser.role_id,
      occupation: selectedUser.occupation,
    },
  });

  const watchPosition = watch("occupation");

  useEffect(() => {
    setSelectedFacilities(userFacilities[selectedUser.doctor_id] || []);
  }, [userFacilities, selectedUser]);

  useEffect(() => {
    if (watchPosition === "other") {
      setShowInputPosition(true);
    } else {
      setPositionOther("");
      setShowInputPosition(false);
    }
  }, [watchPosition]);

  useEffect(() => {
    if (showInputPosition) {
      setPositionOther(selectedUser?.occupation || "");
    } else {
      setPositionOther("");
    }
  }, [showInputPosition, selectedUser]);

  useEffect(() => {
    if (selectedUser) {
      setValue("display_name", selectedUser.display_name);
      setValue("role_id", selectedUser.role_id);

      const isPositionListed = occupations.some(
        (option) => option.value === selectedUser.occupation,
      );

      if (!isPositionListed) {
        setValue("occupation", "other");
        setShowInputPosition(true);
        setPositionOther(selectedUser.occupation);
      } else {
        setValue("occupation", selectedUser.occupation);
        setShowInputPosition(false);
        setPositionOther("");
      }
    }
  }, [selectedUser, setValue]);

  const checkInputPositionOther = useCallback(() => {
    if (watchPosition === "other" && !positionOther?.trim()) {
      setPositionError({ message: "Position can't be empty" });
    } else {
      setPositionError(null);
    }
  }, [watchPosition, positionOther]);

  useEffect(() => {
    checkInputPositionOther();
  }, [checkInputPositionOther]);

  const onSubmit = (data) => {
    data.role_id = Number(data.role_id);

    if (data.occupation === "other") {
      data.occupation = positionOther.trim();
    }

    if (!positionError) {
      if (user.role_id === 1 && selectedFacilities.length === 0) {
        setFacilitiesError("Please select at least one clinic");
        return;
      }

      if (user.role_id === 1) {
        dispatch(
          updateUserFacilities(
            selectedUser.doctor_id,
            selectedFacilities.map((facility) => facility.customer_id),
          ),
        );
      }

      dispatch(
        updateUser(
          selectedUser.doctor_id,
          data,
          () => {
            if (currentConversation) {
              dispatch(getConversation(currentConversation.conversation_id));
            }
            onClose();
          },
          userSearchValue,
        ),
      );
    }
  };

  return (
    <div
      className={`w-full grid grid-rows-note-layout overflow-hidden ${className}`}
    >
      <form
        id="edit-user"
        onSubmit={handleSubmit(onSubmit)}
        className="px-5 space-y-4 overflow-x-hidden min-h-0 flex flex-col overflow-y-auto scrollbar"
      >
        <UserPictureName user={selectedUser} />

        <Input
          label="Display Name"
          register={register}
          name="display_name"
          placeholder="Type display name"
          required="Display name can't be empty"
          withoutAsterisk
          validate={(value) => {
            if (value.trim()) {
              return true;
            }
            return "Display name can't be empty";
          }}
          error={errors.display_name}
        />
        <Select
          label="Role"
          placeholder="Select role"
          name="role_id"
          options={roleOptions}
          register={register}
          disabled={user.role_id !== 1}
        />
        <Select
          label="Position"
          placeholder="Select position"
          name="occupation"
          options={occupations}
          register={register}
        />
        {showInputPosition && (
          <Input
            value={positionOther}
            onChange={(e) => setPositionOther(e.target.value)}
            placeholder="Enter position"
            required="Position can't be empty"
            error={positionError}
          />
        )}
        <div className="space-y-1.5">
          <p className="font-semibold text-sm">Clinics</p>
          <FacilityMultipleSelect
            displaySelect={user.role_id === 1}
            selectedFacilities={selectedFacilities}
            setSelectedFacilities={setSelectedFacilities}
            error={facilitiesError}
            setError={setFacilitiesError}
          />
        </div>
      </form>

      <div className="flex justify-end gap-4 font-semibold text-sm bg-gray-background py-4 px-5 mt-5">
        <Button
          className="w-full md:w-fit"
          variant="gray"
          onClick={(e) => {
            e.preventDefault();
            onClose();
          }}
        >
          Cancel
        </Button>
        <Button
          className="w-full md:w-fit"
          form="edit-user"
          type="submit"
          onClick={checkInputPositionOther}
        >
          Save
        </Button>
      </div>
    </div>
  );
};

export default EditUserForm;
