import { useMediaQuery } from "react-responsive";
import Modal from "../../Basic/Modal";
import ModalMobile from "../../Basic/ModalMobile";
import EditOrganizationForm from "./EditOrganizationForm";

const EditOrganizationModal = ({ user, open, onClose }) => {
  const isDesktop = useMediaQuery({ minWidth: 768 });

  return isDesktop ? (
    open && (
      <Modal className="pt-5 rounded-2xl">
        <div className="grow md:grow-0 sm:w-128 overflow-hidden flex flex-col">
          <p className="text-base md:text-xl font-semibold mb-5 px-5">
            Edit Organization
          </p>
          <EditOrganizationForm user={user} onClose={onClose} />
        </div>
      </Modal>
    )
  ) : (
    <ModalMobile
      open={open}
      header="Edit Organization"
      childHeight={550}
      onClickAway={onClose}
      className=""
    >
      <EditOrganizationForm user={user} onClose={onClose} className="pt-2" />
    </ModalMobile>
  );
};

export default EditOrganizationModal;
