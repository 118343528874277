import type { RefObject } from "react";
import type { AudioContextState } from "../../../types/audio";

interface RecordingReadyState {
  isReady: boolean;
  errors: string[];
}

const useRecordingReadyState = (
  permissionGranted: boolean,
  microphoneId: string,
  contextState: AudioContextState,
  mediaRecorderRef: RefObject<MediaRecorder | null>,
): RecordingReadyState => {
  const newErrors = [];

  if (!permissionGranted) {
    newErrors.push("Microphone permissions not granted");
  }
  if (!microphoneId) {
    newErrors.push("No microphone selected");
  }
  if (!contextState.isInitialized) {
    newErrors.push("Audio context not initialized");
  }
  if (contextState.hasError) {
    newErrors.push(
      `Audio context has an error: ${
        contextState.error?.message || "Unknown error"
      }`,
    );
  }
  if (!mediaRecorderRef.current) {
    newErrors.push("MediaRecorder not available");
  }

  return {
    isReady: newErrors.length === 0,
    errors: newErrors,
  };
};

export default useRecordingReadyState;
