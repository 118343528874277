import { useMediaQuery } from "react-responsive";
import { ReactComponent as CloseIcon } from "../../../assets/icons/close.svg";
import Modal from "../../Basic/Modal";
import ModalMobile from "../../Basic/ModalMobile";
import AvatarSelect from "./AvatarSelect";

const AvatarModal = ({
  open,
  onClose,
  onSave,
}: {
  open: boolean;
  onClose: () => void;
  onSave: (avatar: string) => void;
}) => {
  const isDesktop = useMediaQuery({ minWidth: 768 });

  return isDesktop ? (
    open && (
      <Modal className="pt-5 rounded-2xl">
        <div className="w-106 max-h-[85vh] overflow-hidden flex flex-col">
          <div className="flex items-center justify-between mb-6 px-5">
            <p className="text-base md:text-xl font-semibold">
              Choose set profiles
            </p>
            <button type="button" onClick={onClose}>
              <CloseIcon stroke="#737373" width="24" height="24" />
            </button>
          </div>
          <AvatarSelect onSave={onSave} onCancel={onClose} />
        </div>
      </Modal>
    )
  ) : (
    <ModalMobile
      open={open}
      childHeight={500}
      onClickAway={onClose}
      className=""
      header="Choose set profiles"
    >
      <AvatarSelect onSave={onSave} onCancel={onClose} className="pt-2" />
    </ModalMobile>
  );
};

export default AvatarModal;
