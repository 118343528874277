import Bugsnag from "@bugsnag/js";
import axios from "axios";
import { setPatientNotesLoadingAction } from "../../components/IndependentScribe/store/actions";
import {
  type FixLater,
  setScribes,
} from "../../components/IndependentScribe/store/scribeSlice";
import {
  handleValidation,
  patientSchema,
} from "../../helpers/commonValidationSchemas";
import { BASE_URL } from "../../helpers/config";
import { removeSentMessageAction } from "../conversation/actions";
import { conversationSchema } from "../conversation/validationSchemas";
import { setNotificationAction } from "../user/actions";
import {
  setNumOfFilteredPatientsAction,
  setNumOfPatientsAction,
  setNumOfPatientsByDoctorIdAction,
  setPatientExistsErrorAction,
  setPatientLoaderAction,
  setPatientsAction,
  setProfileConversationAction,
  setProfileLoadingAction,
  setSelectedPatientEnrollmentsAction,
} from "./actions";
import {
  enrollmentsSchema,
  numberOfPatientsSchema,
  patientScribesSchema,
  patientsDataSchema,
} from "./validationSchemas";

export const getNumOfPatients = (doctor) => (dispatch) => {
  axios
    .post(`${BASE_URL}/patient/search`, {
      q: "",
      offset: 0,
      limit: 0,
      doctor_id: doctor
        ? doctor === "unassigned"
          ? null
          : Number(doctor.doctor_id)
        : null,
      unassigned: doctor === "unassigned",
      campaigns: [],
      status: [],
      insurances: [],
    })
    .then((response) => {
      if (response.data.error) {
        dispatch(
          setNotificationAction({
            status: "error",
            title: "Something went wrong",
            desc: "Failed to load number of patients",
          }),
        );
        Bugsnag.notify(response.data.error);
      } else {
        const numberOfPatients: number = response.data.data.total;

        handleValidation(
          numberOfPatientsSchema,
          numberOfPatients,
          "getNumOfPatients",
        );

        dispatch(setNumOfPatientsAction(numberOfPatients));
      }
    })
    .catch((error: FixLater) => {
      console.error(error);
      Bugsnag.notify(error);
    });
};

export const searchPatients =
  (
    searchInput,
    page,
    rows,
    doctor,
    loader = true,
    onSuccess = () => {},
    campaigns = [],
    statuses = [],
    insurances = [],
  ) =>
  (dispatch) => {
    if (loader) {
      dispatch(setPatientLoaderAction(true));
    }
    axios
      .post(`${BASE_URL}/patient/search`, {
        q: searchInput,
        offset: (page - 1) * rows,
        limit: rows,
        doctor_id: doctor
          ? doctor === "unassigned"
            ? null
            : Number(doctor.doctor_id)
          : null,
        unassigned: doctor === "unassigned",
        campaigns: campaigns,
        status: statuses,
        insurances: insurances,
      })
      .then((response) => {
        if (response.data.error) {
          console.error(response.data.error);
          dispatch(
            setNotificationAction({
              status: "error",
              title: "Something went wrong",
              desc: "Failed to load patients",
            }),
          );
          Bugsnag.notify(response.data.error);
        } else {
          const patientsData = response.data.data;

          handleValidation(patientsDataSchema, patientsData, "searchPatients");

          dispatch(setPatientsAction(patientsData.patients));
          dispatch(setNumOfFilteredPatientsAction(patientsData.total));

          const doctorNumbers: FixLater = {};
          patientsData.total_per_doctor.forEach((doctor) => {
            if (doctor.doctor_id === null) {
              doctorNumbers.unassigned = doctor.total_patients;
            }
            doctorNumbers[doctor.doctor_id] = doctor.total_patients;
          });
          doctorNumbers.all = patientsData.total_clinic;

          dispatch(setNumOfPatientsByDoctorIdAction(doctorNumbers));
          onSuccess();
        }
      })
      .catch((error: FixLater) => {
        console.error(error);
        Bugsnag.notify(error);
        dispatch(
          setNotificationAction({
            status: "error",
            title: "Something went wrong",
            desc: "Failed to load patients",
          }),
        );
      })
      .finally(() => {
        dispatch(setPatientLoaderAction(false));
      });
  };

export const createPatient =
  (
    patientData,
    page,
    rows,
    searchValue,
    doctor,
    onSuccess = () => {},
    selectedPatientCampaigns = [],
    selectedPatientStatuses = [],
  ) =>
  (dispatch) => {
    dispatch(setPatientLoaderAction(true));
    axios
      .post(`${BASE_URL}/patient/create`, patientData)
      .then((response) => {
        if (response.data.error) {
          console.error(response.data.error);
          if (response.data.error.message.includes("Patient already exists")) {
            dispatch(setPatientExistsErrorAction(true));
          } else {
            dispatch(
              setNotificationAction({
                status: "error",
                title: "Something went wrong",
                desc: "Failed to create patient",
              }),
            );
            Bugsnag.notify(response.data.error);
          }
        } else {
          dispatch(
            setNotificationAction({
              status: "success",
              title: "Successfully added patient",
              desc: "Your changes have been saved and your profile is live. Your team can make edits.",
            }),
          );
          onSuccess();
        }
        dispatch(
          searchPatients(
            searchValue,
            page,
            rows,
            doctor,
            true,
            () => {},
            selectedPatientCampaigns,
            selectedPatientStatuses,
          ),
        );
        dispatch(getNumOfPatients(doctor));
      })
      .catch((error: FixLater) => {
        console.error(error);
        Bugsnag.notify(error);
      })
      .finally(() => {
        dispatch(setPatientLoaderAction(false));
      });
  };

export const updatePatient =
  (
    patientData,
    page,
    rows,
    searchValue,
    doctor,
    onSuccess = () => {},
    selectedPatientCampaigns = [],
    selectedPatientStatuses = [],
  ) =>
  (dispatch) => {
    dispatch(setPatientLoaderAction(true));
    axios
      .post(`${BASE_URL}/patient/update`, patientData)
      .then((response) => {
        if (response.data.error) {
          console.error(response.data.error);
          dispatch(
            setNotificationAction({
              status: "error",
              title: "Something went wrong",
              desc: "Failed to update patient",
            }),
          );
          Bugsnag.notify(response.data.error);
        } else {
          dispatch(
            searchPatients(
              searchValue,
              page,
              rows,
              doctor,
              true,
              () => {},
              selectedPatientCampaigns,
              selectedPatientStatuses,
            ),
          );
          dispatch(
            setNotificationAction({
              status: "success",
              title: "Successfully updated patient",
              desc: "Your changes have been saved and your profile is live. Your team can make edits.",
            }),
          );
          onSuccess();
        }
      })
      .catch((error: FixLater) => {
        console.error(error);
        Bugsnag.notify(error);
      })
      .finally(() => {
        dispatch(setPatientLoaderAction(false));
      });
  };

export const removePatients =
  (
    patientIds,
    page,
    rows,
    searchValue,
    doctor,
    onSuccess = () => {},
    selectedPatientCampaigns = [],
    selectedPatientStatuses = [],
  ) =>
  (dispatch) => {
    dispatch(setPatientLoaderAction(true));
    axios
      .post(`${BASE_URL}/patient/remove`, { ids: patientIds })
      .then((response) => {
        if (response.data.error) {
          console.error(response.data.error);
          dispatch(
            setNotificationAction({
              status: "error",
              title: "Something went wrong",
              desc: "Failed to delete patients",
            }),
          );
          Bugsnag.notify(response.data.error);
        } else {
          onSuccess();
          dispatch(getNumOfPatients(doctor));
          dispatch(
            searchPatients(
              searchValue,
              page,
              rows,
              doctor,
              true,
              () => {},
              selectedPatientCampaigns,
              selectedPatientStatuses,
            ),
          );
          dispatch(
            setNotificationAction({
              status: "success",
              title: "Successfully deleted patients",
              desc: "Your changes have been saved and your profile is live. Your team can make edits.",
            }),
          );
        }
      })
      .catch((error: FixLater) => {
        console.error(error);
        Bugsnag.notify(error);
      })
      .finally(() => {
        dispatch(setPatientLoaderAction(false));
      });
  };

export const importPatients =
  (
    file,
    page,
    rows,
    searchValue,
    doctor,
    selectedPatientCampaigns = [],
    selectedPatientStatuses = [],
  ) =>
  (dispatch) => {
    dispatch(setPatientLoaderAction(true));
    axios
      .post(
        `${BASE_URL}/patient/import-csv`,
        { csv: file },
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        },
      )
      .then((response) => {
        if (response.data.error) {
          console.error(response.data.error);
          dispatch(
            setNotificationAction({
              status: "error",
              title: "Something went wrong",
              desc: "Failed to import patients",
            }),
          );
          Bugsnag.notify(response.data.error);
        } else {
          dispatch(
            searchPatients(
              searchValue,
              page,
              rows,
              doctor,
              true,
              () => {},
              selectedPatientCampaigns,
              selectedPatientStatuses,
            ),
          );
          dispatch(getNumOfPatients(doctor));
          dispatch(
            setNotificationAction({
              status: "success",
              title: "Successfully imported patients",
              desc: "Your changes have been saved and your profile is live. Your team can make edits.",
            }),
          );
        }
      })
      .catch((error: FixLater) => {
        console.error(error);
        Bugsnag.notify(error);
      })
      .finally(() => {
        dispatch(setPatientLoaderAction(false));
      });
  };

export const pausePatient =
  (
    patientId,
    page,
    rows,
    searchValue,
    doctor,
    selectedPatientCampaigns = [],
    selectedPatientStatuses = [],
  ) =>
  (dispatch) => {
    dispatch(setPatientLoaderAction(true));
    axios
      .post(`${BASE_URL}/patient/pause/${patientId}`)
      .then((response) => {
        if (response.data.error) {
          console.error(response.data.error);
          dispatch(
            setNotificationAction({
              status: "error",
              title: "Something went wrong",
              desc: "Failed to pause patient",
            }),
          );
          Bugsnag.notify(response.data.error);
        } else {
          dispatch(
            searchPatients(
              searchValue,
              page,
              rows,
              doctor,
              true,
              () => {},
              selectedPatientCampaigns,
              selectedPatientStatuses,
            ),
          );
          dispatch(
            setNotificationAction({
              status: "success",
              title: "Successfully paused patient",
              desc: "Your changes have been saved and your profile is live. Your team can make edits.",
            }),
          );
        }
      })
      .catch((error: FixLater) => {
        console.error(error);
        Bugsnag.notify(error);
      })
      .finally(() => {
        dispatch(setPatientLoaderAction(false));
      });
  };

export const unpausePatient =
  (
    patientId,
    page,
    rows,
    searchValue,
    doctor,
    selectedPatientCampaigns = [],
    selectedPatientStatuses = [],
  ) =>
  (dispatch) => {
    dispatch(setPatientLoaderAction(true));
    axios
      .post(`${BASE_URL}/patient/unpause/${patientId}`)
      .then((response) => {
        if (response.data.error) {
          console.error(response.data.error);
          dispatch(
            setNotificationAction({
              status: "error",
              title: "Something went wrong",
              desc: "Failed to unpause patient",
            }),
          );
          Bugsnag.notify(response.data.error);
        } else {
          dispatch(
            searchPatients(
              searchValue,
              page,
              rows,
              doctor,
              true,
              () => {},
              selectedPatientCampaigns,
              selectedPatientStatuses,
            ),
          );
          dispatch(
            setNotificationAction({
              status: "success",
              title: "Successfully unpaused patient",
              desc: "Your changes have been saved and your profile is live. Your team can make edits.",
            }),
          );
        }
      })
      .catch((error: FixLater) => {
        console.error(error);
        Bugsnag.notify(error);
      })
      .finally(() => {
        dispatch(setPatientLoaderAction(false));
      });
  };

export const getPatientInfo =
  (patientId, onSuccess = (_data) => {}) =>
  (dispatch) => {
    axios
      .get(`${BASE_URL}/patient/general/${patientId}`)
      .then((response) => {
        if (response.data.error) {
          console.error(response.data.error);
          dispatch(
            setNotificationAction({
              status: "error",
              title: "Something went wrong",
              desc: "Failed to get patient info",
            }),
          );
          Bugsnag.notify(response.data.error);
        } else {
          const patientInfo = response.data.data.patient;

          handleValidation(patientSchema, patientInfo, "getPatientInfo");

          onSuccess(patientInfo);
        }
      })
      .catch((error: FixLater) => {
        console.error(error);
        Bugsnag.notify(error);
      })
      .finally(() => {
        dispatch(setProfileLoadingAction(false));
      });
  };

export const getPatientEnrollments = (patientId) => (dispatch) => {
  axios
    .get(`${BASE_URL}/patient/enrollments/${patientId}`)
    .then((response) => {
      if (response.data.error) {
        console.error(response.data.error);
        dispatch(
          setNotificationAction({
            status: "error",
            title: "Something went wrong",
            desc: "Failed to get patient enrollments",
          }),
        );
        Bugsnag.notify(response.data.error);
      } else {
        const enrollments = response.data.data.enrollments;

        handleValidation(
          enrollmentsSchema,
          enrollments,
          "getPatientEnrollments",
        );

        dispatch(setSelectedPatientEnrollmentsAction(enrollments));
      }
    })
    .catch((error: FixLater) => {
      console.error(error);
      Bugsnag.notify(error);
    })
    .finally(() => {
      dispatch(setProfileLoadingAction(false));
    });
};

export const generateICD10Codes = (noteId) => (dispatch) => {
  axios
    .post(`${BASE_URL}/voice/${noteId}/generate-codes`)
    .then((response) => {
      if (response.data.error) {
        console.error(response.data.error);
        dispatch(
          setNotificationAction({
            status: "error",
            title: "Something went wrong",
            desc: "Failed to generate codes",
          }),
        );
        Bugsnag.notify(response.data.error);
      }
    })
    .catch((error: FixLater) => {
      console.error(error);
      Bugsnag.notify(error);
    });
};

export const getPatientScribes = (patientId) => (dispatch) => {
  axios
    .get(`${BASE_URL}/patient/notes/${patientId}`)
    .then((response) => {
      if (response.data.error) {
        console.error(response.data.error);
        dispatch(
          setNotificationAction({
            status: "error",
            title: "Something went wrong",
            desc: "Failed to get patient notes",
          }),
        );
        Bugsnag.notify(response.data.error);
      } else {
        const scribes = response.data.data.notes;

        handleValidation(patientScribesSchema, scribes, "getPatientScribes");

        dispatch(setScribes({ scribes: scribes }));
      }
    })
    .catch((error: FixLater) => {
      console.error(error);
      Bugsnag.notify(error);
    })
    .finally(() => {
      dispatch(setProfileLoadingAction(false));
      dispatch(setPatientNotesLoadingAction(false));
    });
};

export const getProfileConversation =
  (id, onSuccess = () => {}) =>
  (dispatch) => {
    axios
      .get(`${BASE_URL}/conversation/${id}`)
      .then((response) => {
        if (response.data.error) {
          console.error(response.data.error);
          dispatch(
            setNotificationAction({
              status: "error",
              title: "Something went wrong",
              desc: "Failed to load conversation",
            }),
          );
          Bugsnag.notify(response.data.error);
        } else {
          const conversation = response.data.data.conversation;

          handleValidation(
            conversationSchema,
            conversation,
            "getProfileConversation",
          );

          onSuccess();
          dispatch(removeSentMessageAction(id));
          dispatch(setProfileConversationAction(conversation));
        }
      })
      .catch((error: FixLater) => {
        console.error(error);
        Bugsnag.notify(error);
      });
  };

export const enrollPatientWithAi =
  (
    patientId,
    campaignId,
    conditions,
    goals,
    medicalHistory,
    doctorId,
    aiChat,
    consent = true,
    onSuccess = () => {},
    updateMessage = false,
  ) =>
  (dispatch) => {
    axios
      .post(`${BASE_URL}/patient/enroll/${patientId}`, {
        campaign_id: campaignId,
        conditions: conditions,
        goals: goals,
        past_medical_history: medicalHistory,
        doctor_id: doctorId,
        ai_chat: aiChat,
        consent: consent,
      })
      .then((response) => {
        if (response.data.error) {
          console.error(response.data.error);
          dispatch(
            setNotificationAction({
              status: "error",
              title: "Something went wrong",
              desc: "Failed to enroll patient",
            }),
          );
          Bugsnag.notify(response.data.error);
        } else {
          onSuccess();
          if (updateMessage) {
            dispatch(
              setNotificationAction({
                status: "success",
                title: "Successfully updated patient enrollment",
                desc: "Campaign has been successfully updated for selected patient",
              }),
            );
          } else {
            dispatch(
              setNotificationAction({
                status: "success",
                title: "Successfully enrolled patient",
                desc: "Campaign has been successfully scheduled for selected patient",
              }),
            );
          }
        }
      })
      .catch((error: FixLater) => {
        console.error(error);
        Bugsnag.notify(error);
        dispatch(
          setNotificationAction({
            status: "error",
            title: "Something went wrong",
            desc: "Failed to enroll patient",
          }),
        );
      });
  };
