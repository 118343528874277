//auth
export const SET_REGISTERED_EMAIL_ADDRESS = "SET_REGISTERED_EMAIL_ADDRESS";
export const SET_PRACTICE_INFO = "SET_PRACTICE_INFO";
export const SET_VERIFICATION_CODE = "SET_VERIFICATION_CODE";

//user
export const LOGOUT = "LOGOUT";
export const SET_USER = "SET_USER";
export const SET_USERS = "SET_USERS";
export const SET_TEAMS = "SET_TEAMS";
export const SET_USERS_WITHOUT_TEAM = "SET_USERS_WITHOUT_TEAM";
export const SET_ROLES = "SET_ROLES";
export const SET_ROLE_OPTIONS = "SET_ROLE_OPTIONS";
export const SET_ERROR = "SET_ERROR";
export const SET_NOTIFICATION = "SET_NOTIFICATION";
export const OPEN_SUCCESS_MODAL = "OPEN_SUCCESS_MODAL";
export const SET_ASSISTANTS = "SET_ASSISTANTS";
export const SET_SIGNUP_INFO = "SET_SIGNUP_INFO";
export const SET_DOCTOR_OPTIONS = "SET_DOCTOR_OPTIONS";
export const SET_SELECTED_CARE_MANAGEMENT_TAB =
  "SET_SELECTED_CARE_MANAGEMENT_TAB";
export const SET_SELECTED_SETTINGS_TAB = "SET_SELECTED_SETTINGS_TAB";
export const SET_SELECTED_ORGANIZATION_TAB = "SET_SELECTED_ORGANIZATION_TAB";
export const SET_SELECTED_NOTES_TAB = "SET_SELECTED_NOTES_TAB";
export const SET_USERS_LOADING = "SET_USERS_LOADING";
export const SET_TEAMS_LOADING = "SET_TEAMS_LOADING";
export const SET_USER_SEARCH_VALUE = "SET_USER_SEARCH_VALUE";
export const SET_TEAM_SEARCH_VALUE = "SET_TEAM_SEARCH_VALUE";
export const SET_USER_FACILITIES = "SET_USER_FACILITIES";
export const SET_ONLY_SCRIBE_FEATURE = "SET_ONLY_SCRIBE_FEATURE";

//conversation
export const SET_CONVERSATIONS_REVIEW = "SET_CONVERSATIONS_REVIEW";
export const SET_CONVERSATIONS = "SET_CONVERSATIONS";
export const SET_CONVERSATIONS_SCROLL_POSITION =
  "SET_CONVERSATIONS_SCROLL_POSITION";
export const SET_CONV_CURR_PAGE = "SET_CONV_CURR_PAGE";
export const SET_CONVERSATIONS_COMPLETED = "SET_CONVERSATIONS_COMPLETED";
export const SET_ACTIVE_INBOX = "SET_ACTIVE_INBOX";
export const SET_CONVERSATIONS_COMPLETED_TEMP =
  "SET_CONVERSATIONS_COMPLETED_TEMP";
export const SET_CONVERSATION = "SET_CONVERSATION";
export const SELECT_CONVERSATION = "SELECT_CONVERSATION";
export const SET_NEXT_CONVERSATION_ID = "SET_NEXT_CONVERSATION_ID";
export const EXPAND_PANEL = "EXPAND_PANEL";
export const COLLAPSE_SIDEBAR = "COLLAPSE_SIDEBAR";
export const SET_LOADER = "SET_LOADER";
export const SET_COLLAPSE_LIST = "SET_COLLAPSE_LIST";
export const SET_ACTIVE_FILTERS = "SET_ACTIVE_FILTERS";
export const SET_INIT_FILTERS = "SET_INIT_FILTERS";
export const SET_CAMPAIGN_OPTIONS = "SET_CAMPAIGN_OPTIONS";
export const SET_SEARCH_INPUT = "SET_SEARCH_INPUT";
export const ADD_SENT_MESSAGE = "ADD_SENT_MESSAGE";
export const REMOVE_SENT_MESSAGE = "REMOVE_SENT_MESSAGE";
export const ADD_FAILED_MESSAGE = "ADD_FAILED_MESSAGE";
export const REMOVE_FAILED_MESSAGE = "REMOVE_FAILED_MESSAGE";
export const SET_CLOSED_RESPONSES = "SET_CLOSED_RESPONSES";
export const SET_NOTE_MARKUP = "SET_NOTE_MARKUP";
export const SET_ENCOUNTERS = "SET_ENCOUNTERS";
export const SET_ENCOUNTER_SEARCH_INPUT = "SET_ENCOUNTER_SEARCH_INPUT";
export const SET_ENCOUNTER_ACTIVE_TAG = "SET_ENCOUNTER_ACTIVE_TAG";
export const SET_CONVERSATIONS_UPDATED = "SET_CONVERSATIONS_UPDATED";
export const SET_ON_MESSAGE_CONVERSATION_ID = "SET_ON_MESSAGE_CONVERSATION_ID";
export const SET_CONVERSATIONS_COUNTS = "SET_CONVERSATIONS_COUNTS";
export const SET_FILTERS_UPDATED = "SET_FILTERS_UPDATED";
export const SET_OPEN_PATIENT_DETAILS = "SET_OPEN_PATIENT_DETAILS";
export const SET_SELECTED_DOCTOR_INBOX = "SET_SELECTED_DOCTOR_INBOX";
export const SET_NUM_CONV_BY_DOCTOR = "SET_NUM_CONV_BY_DOCTOR";
export const SET_SELECTED_CONVERSATION_TAB = "SET_SELECTED_CONVERSATION_TAB";
export const SET_MESSAGES = "SET_MESSAGES";

//voice recorder
export const SET_TWILIO_TOKEN = "SET_TWILIO_TOKEN";
export const SET_DEVICE_STATUS = "SET_DEVICE_STATUS";
export const SET_OPEN_CALL_OPTIONS_MODAL = "SET_OPEN_CALL_OPTIONS_MODAL";
export const SET_OPEN_CALL_MODAL = "SET_OPEN_CALL_MODAL";
export const SET_CALL_EVENT = "SET_CALL_EVENT";
export const SET_SCRIBE_CALLS = "SET_SCRIBE_CALLS";
export const SET_LIVE_TRANSCRIPT = "SET_LIVE_TRANSCRIPT";

//patient
export const SET_PATIENTS = "SET_PATIENTS";
export const SET_SELECTED_PATIENTS = "SET_SELECTED_PATIENTS";
export const SET_SELECTED_PATIENT_INFO = "SET_SELECTED_PATIENT_INFO";
export const SET_SELECTED_PATIENT_ENROLLMENTS =
  "SET_SELECTED_PATIENT_ENROLLMENTS";

export const SET_LAST_SELECTED_PATIENT_PROFILE =
  "SET_LAST_SELECTED_PATIENT_PROFILE";
export const SET_CURR_PAGE = "SET_CURR_PAGE";
export const SET_SEARCH = "SET_SEARCH";
export const SET_NUM_PATIENTS = "SET_NUM_PATIENTS";
export const SET_NUM_PATIENTS_BY_DOCTOR = "SET_NUM_PATIENTS_BY_DOCTOR";
export const SET_NUM_FILTERED_PATIENTS = "SET_NUM_FILTERED_PATIENTS";
export const SET_PATIENT_LOADER = "SET_PATIENT_LOADER";
export const SET_TABLE_SCROLL_POSITION = "SET_TABLE_SCROLL_POSITION";
export const SET_PROFILE_LOADING = "SET_PROFILE_LOADING";
export const SET_PATIENT_EXISTS_ERROR = "SET_PATIENT_EXISTS_ERROR";
export const SET_SELECTED_DOCTOR_PATIENTS = "SET_SELECTED_DOCTOR_PATIENTS";
export const SET_SELECTED_PATIENT_PANEL_TAB = "SET_SELECTED_PATIENT_PANEL_TAB";
export const SET_SELECTED_GENERATED_NOTE_AUDIO_ID =
  "SET_SELECTED_GENERATED_NOTE_AUDIO_ID";
export const SET_SELECTED_PATIENT_PROFILE_TAB =
  "SET_SELECTED_PATIENT_PROFILE_TAB";
export const SET_PROFILE_CONVERSATION = "SET_PROFILE_CONVERSATION";
export const SET_OPEN_EDIT_INFO_MODAL = "SET_OPEN_EDIT_INFO_MODAL";
export const SET_SELECTED_PATIENT_CAMPAIGNS = "SET_SELECTED_PATIENT_CAMPAIGNS";
export const SET_SELECTED_PATIENT_STATUSES = "SET_SELECTED_PATIENT_STATUSES";
export const SET_SELECTED_PATIENT_INSURANCES =
  "SET_SELECTED_PATIENT_INSURANCES";
export const SET_PATIENT_RECORDING_SCRIBE_TITLE =
  "SET_PATIENT_RECORDING_SCRIBE_TITLE";

//campaign
export const SET_CAMPAIGNS = "SET_CAMPAIGNS";
export const SET_CAMPAIGN_TAGS = "SET_CAMPAIGN_TAGS";
export const SET_SELECTED_ENROLL_CAMPAIGN = "SET_SELECTED_ENROLL_CAMPAIGN";
export const SET_SELECTED_CAMPAIGN_IDS = "SET_SELECTED_CAMPAIGN_IDS";
export const SET_SELECTED_TAB = "SET_SELECTED_TAB";
export const SET_SELECTED_START_DATE = "SET_SELECTED_START_DATE";
export const SET_START_DATE_SHORTCUT = "SET_START_DATE_SHORTCUT";
export const SET_UPCOMING_MESSAGES = "SET_UPCOMING_MESSAGES";
export const SET_SELECTED_UPCOMING_POSITION = "SET_SELECTED_UPCOMING_POSITION";
export const SET_MORE_EVENTS_POSITION = "SET_MORE_EVENTS_POSITION";
export const SET_MORE_EVENTS_DATE = "SET_MORE_EVENTS_DATE";
export const SET_SELECTED_EVENT = "SET_SELECTED_EVENT";
export const SET_CURRENT_VIEW = "SET_CURRENT_VIEW";
export const SET_SELECTED_ENROLL_PATIENTS = "SET_SELECTED_ENROLL_PATIENTS";
export const SET_RERENDER_ENROLL_FIELDS = "SET_RERENDER_ENROLL_FIELDS";
export const SET_ENROLL_FIELDS = "SET_ENROLL_FIELDS";
export const SET_CAMPAIGN_LOADER = "SET_CAMPAIGN_LOADER";
export const SET_SMART_ENROLL_GENERATED = "SET_SMART_ENROLL_GENERATED";
export const SET_SMART_ENROLL_LOADING = "SET_SMART_ENROLL_LOADING";

//template
export const SET_TEMPLATES = "SET_TEMPLATES";
export const SET_TEMPLATES_LOADER = "SET_TEMPLATES_LOADER";

//metrics
export const SET_METRICS_PASSWORD = "SET_METRICS_PASSWORD";
export const SET_METRICS_PASSWORD_ERROR = "SET_METRICS_PASSWORD_ERROR";
export const SET_METRICS_CUSTOMERS = "SET_METRICS_CUSTOMERS";
export const SET_METRICS_CAMPAIGNS = "SET_METRICS_CAMPAIGNS";
export const SET_METRICS = "SET_METRICS";
export const SET_LOADING = "SET_LOADING";

//billing
export const SET_BILLING_PATIENTS = "SET_BILLING_PATIENTS";
export const SET_NUM_BILLING_PATIENTS = "SET_NUM_BILLING_PATIENTS";
export const SET_CURRENT_BILLING_PATIENT = "SET_CURRENT_BILLING_PATIENT";
export const SET_CURRENT_DATE_STAMP = "SET_CURRENT_DATE_STAMP";
export const SET_BILLING_LOADING = "SET_BILLING_LOADING";
export const SET_TIME_ADJUST_LOADING = "SET_TIME_ADJUST_LOADING";
export const SET_EXPORT_LOADING = "SET_EXPORT_LOADING";
export const SET_SELECTED_BILLING_TYPE = "SET_SELECTED_BILLING_TYPE";
export const SET_SELECTED_CPT_CODE = "SET_SELECTED_CPT_CODE";
export const SET_SORTED_BY = "SET_SORTED_BY";
export const SET_SORTING_DIRECTION = "SET_SORTING_DIRECTION";
export const SET_BILLING_SEARCH_VALUE = "SET_BILLING_SEARCH_VALUE";
export const SET_BILLING_TABLE_SCROLL_POSITION =
  "SET_BILLING_TABLE_SCROLL_POSITION";
export const SET_CURR_BILLING_PAGE = "SET_CURR_BILLING_PAGE";
export const SET_BILLING_NUMBERS = "SET_BILLING_NUMBERS";
export const SET_ALL_NOTES_GENERATED = "SET_ALL_NOTES_GENERATED";

// facility
export const SET_FACILITIES = "SET_FACILITIES";
export const SET_FACILITY_SEARCH_VALUE = "SET_FACILITY_SEARCH_VALUE";
export const SET_FACILITIES_LOADING = "SET_FACILITIES_LOADING";

// ui
export const SET_IS_MODAL_OPEN = "SET_IS_MODAL_OPEN";
export const SET_IS_DATE_PICKER_OPEN = "SET_IS_DATE_PICKER_OPEN";
export const SET_PATH_BEFORE_PROFILE = "SET_PATH_BEFORE_PROFILE";
