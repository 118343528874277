import { useEffect, useState } from "react";
import { ReactComponent as ArrowIcon } from "../../../../assets/icons/arrow-down.svg";

const DropdownButton = ({
  onClick,
  label,
  selectedItem,
  showTitleInput,
  newTitle,
  onUpdateTitle,
}) => {
  const [titleValue, setTitleValue] = useState(newTitle || "");

  useEffect(() => {
    if (showTitleInput) {
      setTitleValue(newTitle || "");
    }
  }, [showTitleInput, newTitle]);

  useEffect(() => {
    if (selectedItem) {
      setTitleValue(selectedItem.name);
    } else {
      setTitleValue(newTitle || "");
    }
  }, [selectedItem, newTitle]);

  return (
    <div
      className={`w-full h-10 flex items-center justify-between px-3 rounded-lg bg-white border border-gray-300 truncate
        ${!showTitleInput ? "cursor-pointer" : ""}`}
      onClick={(e) => {
        if (!showTitleInput) {
          onClick(e);
        }
      }}
    >
      {showTitleInput ? (
        <input
          autoFocus
          value={titleValue}
          onChange={(e) => setTitleValue(e.target.value)}
          onBlur={() => onUpdateTitle(titleValue)}
          className="w-full h-full outline-none text-base md:text-sm"
        />
      ) : (
        <p className="text-base md:text-sm font-semibold truncate">{label}</p>
      )}
      <button
        type="button"
        className="h-full w-5 flex items-center justify-center"
        onClick={(e) => {
          if (showTitleInput) {
            onClick(e);
          }
        }}
      >
        <ArrowIcon width="10" height="7" className="flex-none" />
      </button>
    </div>
  );
};

export default DropdownButton;
