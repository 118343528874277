import { isEqual } from "lodash";

import { CampaignTabs } from "../../helpers/constants";
import LocalStorageService from "../../services/LocalStorageService";
import {
  LOGOUT,
  SET_CAMPAIGNS,
  SET_CAMPAIGN_LOADER,
  SET_CAMPAIGN_TAGS,
  SET_CURRENT_VIEW,
  SET_ENROLL_FIELDS,
  SET_MORE_EVENTS_DATE,
  SET_MORE_EVENTS_POSITION,
  SET_RERENDER_ENROLL_FIELDS,
  SET_SELECTED_CAMPAIGN_IDS,
  SET_SELECTED_ENROLL_CAMPAIGN,
  SET_SELECTED_ENROLL_PATIENTS,
  SET_SELECTED_EVENT,
  SET_SELECTED_START_DATE,
  SET_SELECTED_TAB,
  SET_SELECTED_UPCOMING_POSITION,
  SET_SMART_ENROLL_GENERATED,
  SET_SMART_ENROLL_LOADING,
  SET_START_DATE_SHORTCUT,
  SET_UPCOMING_MESSAGES,
} from "../types";

const storageService = new LocalStorageService();

const init = {
  campaigns: [],
  campaignTags: [],
  upcomingMessages: [],
  selectedEnrollCampaign:
    storageService.getItem("selectedEnrollCampaign") ?? null,
  selectedCampaignIds: storageService.getItem("selectedCampaignIds") ?? [],
  selectedTab: storageService.getItem("campaignTab") ?? CampaignTabs.DETAILS,
  selectedEnrollPatients: [],
  rerenderEnrollFields: false,
  enrollFields: {},
  selectedStartDate: null,
  activeStartDateShortcut: "Tomorrow",
  selectedUpcomingPosition: null,
  moreEventsPosition: null,
  moreEventsDate: null,
  selectedEvent: null,
  currentView: storageService.getItem("calendarView") ?? "week",
  loader: false,
  smartEnrollGenerated: storageService.getItem("smartEnrollGenerated") ?? {},
  smartEnrollLoading: false,
};

export default function campaignReducer(state = init, action) {
  const { type, payload } = action;

  switch (type) {
    case LOGOUT:
      return { ...init };
    case SET_CAMPAIGNS:
      if (isEqual(state.campaigns, payload.campaigns)) {
        return state;
      }

      return { ...state, campaigns: payload.campaigns };
    case SET_CAMPAIGN_TAGS:
      if (isEqual(state.campaignTags, payload.campaignTags)) {
        return state;
      }

      return { ...state, campaignTags: payload.campaignTags };
    case SET_SELECTED_ENROLL_CAMPAIGN:
      if (payload.campaign) {
        storageService.setItem("selectedEnrollCampaign", payload.campaign);
      }
      return { ...state, selectedEnrollCampaign: payload.campaign };
    case SET_SELECTED_CAMPAIGN_IDS:
      storageService.setItem("selectedCampaignIds", payload.campaignIds);
      return { ...state, selectedCampaignIds: payload.campaignIds };
    case SET_SELECTED_TAB:
      storageService.setItem("campaignTab", payload.selectedTab);
      return { ...state, selectedTab: payload.selectedTab };
    case SET_SELECTED_ENROLL_PATIENTS:
      return { ...state, selectedEnrollPatients: payload.selectedPatients };
    case SET_RERENDER_ENROLL_FIELDS:
      return { ...state, rerenderEnrollFields: payload.rerenderEnrollFields };
    case SET_ENROLL_FIELDS:
      return { ...state, enrollFields: payload.enrollFields };
    case SET_SELECTED_START_DATE:
      return { ...state, selectedStartDate: payload.selectedStartDate };
    case SET_START_DATE_SHORTCUT:
      return {
        ...state,
        activeStartDateShortcut: payload.activeStartDateShortcut,
      };
    case SET_UPCOMING_MESSAGES:
      return { ...state, upcomingMessages: payload.upcomingMessages };
    case SET_SELECTED_UPCOMING_POSITION:
      return { ...state, selectedUpcomingPosition: payload.position };
    case SET_MORE_EVENTS_POSITION:
      return { ...state, moreEventsPosition: payload.position };
    case SET_MORE_EVENTS_DATE:
      return { ...state, moreEventsDate: payload.date };
    case SET_SELECTED_EVENT:
      if (isEqual(state.selectedEvent, payload.selectedEvent)) {
        return state;
      }

      return { ...state, selectedEvent: payload.selectedEvent };
    case SET_CURRENT_VIEW:
      storageService.setItem("calendarView", payload.currentView);
      return { ...state, currentView: payload.currentView };
    case SET_CAMPAIGN_LOADER:
      if (state.loader === payload.loader) {
        return state;
      }

      return { ...state, loader: payload.loader };
    case SET_SMART_ENROLL_GENERATED:
      storageService.setItem(
        "smartEnrollGenerated",
        payload.smartEnrollGenerated,
      );
      return { ...state, smartEnrollGenerated: payload.smartEnrollGenerated };
    case SET_SMART_ENROLL_LOADING:
      return { ...state, smartEnrollLoading: payload.smartEnrollLoading };
    default:
      return state;
  }
}
