import { useCallback, useEffect, useRef, useState } from "react";
import { TabsOrientation } from "../../helpers/constants";
import { getTagVariant } from "../../helpers/helpers";
import Tag from "./Tag";

const TabsBorder = ({
  tabs,
  icons,
  campaigns,
  showDesc,
  tags,
  activeTab,
  setActiveTab,
  className,
  activeBorderColor = "primary-blue",
  activeBgColor = "transparent",
  activeTextColor = "primary",
  tabClassName = "pb-2.5 px-2",
  orientation = TabsOrientation.HORIZONTAL,
  rerender = [],
}) => {
  const [translate, setTranslate] = useState(0);
  const [tabSizes, setTabSizes] = useState({});
  const [transition, setTransition] = useState("");
  const [activeIndex, setActiveIndex] = useState(0);
  const isVertical = orientation === TabsOrientation.VERTICAL;
  const tabsContainerRef = useRef();
  const tabRefs = useRef([]);

  useEffect(() => {
    const id = setTimeout(() => {
      setTransition("transition-all");
    }, 500);

    const tabsContainer = tabsContainerRef?.current;
    if (!tabsContainer) {
      return;
    }

    return () => {
      clearTimeout(id);
    };
  }, []);

  const updateTranslate = useCallback(() => {
    let translationOffset = 0;
    if (tabSizes) {
      translationOffset = Object.values(tabSizes)
        .slice(0, activeIndex)
        .reduce((a, b) => a + b, 0);
    }
    setTranslate(translationOffset);
  }, [activeIndex, tabSizes]);

  useEffect(() => {
    updateTranslate();
  }, [updateTranslate]);

  const updateTabSizes = useCallback(() => {
    tabRefs.current.forEach((ref, index) => {
      if (ref) {
        setTabSizes((tabSizes) => ({
          ...tabSizes,
          [index]: isVertical ? ref.offsetHeight : ref.offsetWidth,
        }));
      }
    });
  }, [isVertical]);

  useEffect(() => {
    const observer = new ResizeObserver(() => {
      updateTabSizes();
    });
    if (tabsContainerRef.current) {
      observer.observe(tabsContainerRef.current);
    }

    return () => observer.disconnect();
  }, [updateTabSizes]);

  useEffect(() => {
    updateTabSizes();
  }, [updateTabSizes, ...rerender]);

  useEffect(() => {
    if (campaigns) {
      setActiveIndex(
        tabs.findIndex((tab) => tab.campaign_id === activeTab.campaign_id) || 0,
      );
    } else {
      setActiveIndex(tabs.indexOf(activeTab));
    }
  }, [activeTab, tabs, campaigns]);

  const getTab = (tab, index) => {
    let tag = null;
    if (campaigns && tags && tags.length > 0) {
      tag = tags.find((tag) => tag.value === tab.campaign_type);
    }

    return campaigns ? (
      <li
        key={tab.campaign_id}
        ref={(el) => {
          tabRefs.current[index] = el;
        }}
        onClick={() => setActiveTab(tab)}
        onKeyUp={(e) => {
          if (e.key === "Enter") {
            setActiveTab(tab);
          }
        }}
        className={`cursor-pointer font-semibold relative transition-all pl-4 flex items-center
          md:items-start md:flex-col py-4 pr-2
          ${activeTab.campaign_id === tab.campaign_id ? "text-primary" : "text-gray-500 md:text-primary"} `}
      >
        <div className="flex md:grid grid-cols-conv-layout items-center justify-between gap-1.5 w-full">
          <p className="text-sm md:text-base font-semibold whitespace-nowrap truncate">
            {tab.campaign}
          </p>
          {tag && (
            <Tag
              key={tag.value}
              text={tag.text}
              variant={getTagVariant(tag.text)}
              className="whitespace-nowrap py-1 px-2"
            />
          )}
        </div>

        {showDesc && tab.description && (
          <p className="text-xs text-zinc-700 mt-2.5">{tab.description}</p>
        )}
      </li>
    ) : (
      <li
        key={tab}
        ref={(el) => {
          tabRefs.current[index] = el;
        }}
        onClick={() => setActiveTab(tab)}
        onKeyUp={(e) => {
          if (e.key === "Enter") {
            setActiveTab(tab);
          }
        }}
        className={`cursor-pointer font-semibold relative transition-all ${tabClassName}
          ${activeTab === tab ? `text-${activeTextColor} bg-${activeBgColor}` : "text-gray-500"}`}
      >
        {icons?.[tab]}
        {tab}
      </li>
    );
  };

  return (
    <ul
      ref={tabsContainerRef}
      className={`flex w-fit min-w-full md:w-full relative z-10
        ${isVertical ? "flex-col" : "flex-row"} ${className}`}
    >
      {tabs.map((tab, index) => getTab(tab, index))}
      <div
        aria-hidden="true"
        style={{
          transform: isVertical
            ? `translateY(${translate}px)`
            : `translateX(${translate}px)`,
          width: isVertical
            ? "2px"
            : tabSizes
              ? `${tabSizes[activeIndex]}px`
              : "33px",
          height: isVertical
            ? tabSizes
              ? `${tabSizes[activeIndex]}px`
              : "55px"
            : "2px",
        }}
        className={`absolute rounded-lg z-0 bg-${activeBorderColor} ${transition}
          ${!activeTab ? "hidden" : ""}
          ${isVertical ? "top-0 -left-0.5" : "-bottom-0.5 left-0"}`}
      >
        {isVertical && (
          <>
            <div className="absolute top-0 left-0 h-3.5 w-full bg-white" />
            <div className="absolute bottom-0 left-0 h-3.5 w-full bg-white" />
          </>
        )}
      </div>
    </ul>
  );
};

export default TabsBorder;
