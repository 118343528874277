import CareManagement from "../../components/Settings/CareManagement";
import SettingsContainer from "../../components/Settings/SettingsContainer";

const CareManagementSettings = () => {
  return (
    <SettingsContainer>
      <CareManagement />
    </SettingsContainer>
  );
};

export default CareManagementSettings;
