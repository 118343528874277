import { useEffect, useLayoutEffect, useRef } from "react";
import InfiniteScroll from "react-infinite-scroller";
import { useMediaQuery } from "react-responsive";
import { useNavigate } from "react-router-dom";
import {
  Pages,
  patientPanelTabs,
  patientProfileTabs,
  rowsPerPage,
} from "../../helpers/constants";
import { useAppDispatch, useAppSelector } from "../../store";
import {
  setCurrPageAction,
  setSelectedPatientInfoAction,
  setSelectedPatientPanelTabAction,
  setSelectedPatientProfileTabAction,
  setTableScrollPositionAction,
} from "../../store/patient/actions";
import { getPatientInfo, searchPatients } from "../../store/patient/thunks";
import { setPathBeforeProfile } from "../../store/ui/actions";
import { setOpenCallOptionsModalAction } from "../../store/voiceRecorder/actions";
import LoaderDots from "../Basic/LoaderDots";
import { MOCK_PATIENT_FIRST_NAME } from "../IndependentScribe/consts";
import PatientItem from "./PatientItem";

const TableData = ({
  getTableProps,
  headerGroups,
  getTableBodyProps,
  rows,
  prepareRow,
  onEdit,
  onPause,
  onDelete,
}) => {
  const {
    numOfFilteredPatients,
    searchValue,
    currPage,
    tableScrollPosition,
    selectedDoctorPatients,
    patientsPage,
    selectedPatients,
    selectedPatientCampaigns,
    selectedPatientStatuses,
    selectedPatientInsurances,
  } = useAppSelector((state) => state.patient);
  const isDesktop = useMediaQuery({ minWidth: 768 });
  const dispatch = useAppDispatch();
  const tableRef = useRef<HTMLDivElement>(null);
  const selectedPatientsRef = useRef(selectedPatients);
  const navigate = useNavigate();

  useEffect(() => {
    selectedPatientsRef.current = selectedPatients;
  }, [selectedPatients]);

  useLayoutEffect(() => {
    const ref = tableRef.current;
    if (ref) {
      ref.scroll(0, tableScrollPosition);
    }
    return () => {
      if (ref) {
        dispatch(setTableScrollPositionAction(ref.scrollTop));
      }
    };
  }, [dispatch, tableScrollPosition]);

  useEffect(() => {
    if (isDesktop) {
      rows.forEach((row) => {
        if (
          selectedPatientsRef?.current?.some(
            (patient) => patient.patient_id === row.original.patient_id,
          )
        ) {
          row.toggleRowSelected(true);
        }
      });
    }
  }, [rows, isDesktop]);

  const onNext = () => {
    dispatch(
      searchPatients(
        searchValue,
        1,
        rowsPerPage * (currPage + 1),
        selectedDoctorPatients,
        false,
        () => dispatch(setCurrPageAction(currPage + 1)),
        selectedPatientCampaigns,
        selectedPatientStatuses,
        selectedPatientInsurances,
      ),
    );
  };

  const navigateToProfile = (patient) => {
    dispatch(setPathBeforeProfile(Pages.PATIENTS));
    dispatch(
      getPatientInfo(patient.patient_id, (patientInfo) => {
        dispatch(setSelectedPatientInfoAction(patientInfo));
        navigate(`${Pages.PATIENTS}/${patient.patient_id}`);
        dispatch(setOpenCallOptionsModalAction(true));
      }),
    );
    dispatch(setSelectedPatientProfileTabAction(patientProfileTabs.NOTES));
    dispatch(setSelectedPatientPanelTabAction(patientPanelTabs.SCRIBE));
  };

  const patients = patientsPage?.filter(
    (patient) => patient.first_name !== MOCK_PATIENT_FIRST_NAME,
  );

  return (
    <div className="overflow-auto h-full w-full scrollbar pr-1" ref={tableRef}>
      <InfiniteScroll
        pageStart={0}
        loadMore={onNext}
        hasMore={rows.length < numOfFilteredPatients}
        useWindow={false}
        loader={
          <div key={0} className="w-full h-10 flex items-center justify-center">
            <LoaderDots />
          </div>
        }
      >
        <div className="min-w-fit w-full h-fit">
          {isDesktop ? (
            <table {...getTableProps()} className="min-w-full">
              <thead className="bg-gray-background sticky top-0 z-[9]">
                {headerGroups.map((headerGroup) => {
                  const { key, ...restHeaderGroupProps } =
                    headerGroup.getHeaderGroupProps();
                  return (
                    <tr {...restHeaderGroupProps} key={key}>
                      {headerGroup.headers.map((column, index) => (
                        <th
                          {...column.getHeaderProps()}
                          key={index}
                          scope="col"
                          className="group h-11 text-sm font-semibold text-tertiary p-0"
                        >
                          <div
                            className={`flex items-center space-x-2 h-full w-full py-3 px-3 xl:px-4 truncate
                          ${column.id === "selection" ? "w-10 lg:pr-0" : "pr-4 lg:pr-6"}`}
                          >
                            {column.render("Header")}
                          </div>
                        </th>
                      ))}
                    </tr>
                  );
                })}
              </thead>
              <tbody {...getTableBodyProps()}>
                {rows.map((row, i) => {
                  prepareRow(row);

                  return (
                    <tr
                      key={i}
                      className="cursor-pointer"
                      onClick={() => navigateToProfile(row.original)}
                    >
                      {row.cells.map((cell, index) => {
                        const { key, ...restCellProps } = cell.getCellProps();
                        return (
                          <td
                            key={key}
                            {...restCellProps}
                            className="whitespace-nowrap text-zinc-700 font-semibold text-sm py-1.5 px-0"
                            onClick={(e) => {
                              if (cell.column.id === "selection") {
                                e.stopPropagation();
                              }
                            }}
                          >
                            <div
                              className={`px-3 xl:px-4 h-14 w-full flex items-center
                            ${row.isSelected ? "bg-gray-140" : "bg-white"}
                            ${index === 0 ? "rounded-l-lg" : ""}
                            ${index === row.cells.length - 1 ? "rounded-r-lg" : ""}`}
                            >
                              {cell.render("Cell")}
                            </div>
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          ) : (
            <div className="space-y-2">
              {patients?.length > 0 &&
                patients.map((patient, index) => (
                  <PatientItem
                    key={`${patient.patient_id}_${index}`}
                    patient={patient}
                    onClick={() => navigateToProfile(patient)}
                    onEdit={onEdit}
                    onPause={onPause}
                    onDelete={onDelete}
                  />
                ))}
            </div>
          )}
        </div>
      </InfiniteScroll>
    </div>
  );
};

export default TableData;
