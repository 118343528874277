import type React from "react";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { VariableSizeList } from "react-window";
import { useAppDispatch, useAppSelector } from "../../../store";
import { getUser } from "../../../store/user/selectors";
import { deleteScribe } from "../../../store/voiceRecorder/thunks";
import DeleteModal from "../../Basic/DeleteModal";
import ScribeListItem from "../ScribeListItem";
import { type ScribeGroupItem, getGroupedScribes } from "../store/selectors";
import { getProviderNotes } from "../store/thunks";

const ITEM_HEIGHT = 54;
const HEADER_HEIGHT = 20;
const SEPARATOR_HEIGHT = 25;

const ScribeListContent = ({
  handleSelectScribe,
  className = "",
}: {
  handleSelectScribe: (scribe: { audioId: string }) => void;
  className?: string;
}) => {
  const groupedScribes = useAppSelector(getGroupedScribes);
  const user = useAppSelector(getUser);

  const containerRef = useRef<HTMLDivElement | null>(null);
  const listRef = useRef<VariableSizeList>(null);
  const [containerHeight, setContainerHeight] = useState(0);
  const openDeleteModalRef = useRef(false);
  const deleteNoteRef = useRef<ScribeGroupItem | null>(null);

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (containerRef.current) {
      setContainerHeight(containerRef.current.clientHeight);
    }

    const resizeObserver = new ResizeObserver((entries) => {
      for (const entry of entries) {
        if (entry.target === containerRef.current) {
          setContainerHeight(entry.contentRect.height);
        }
      }
    });

    if (containerRef.current) {
      resizeObserver.observe(containerRef.current);
    }

    return () => {
      if (containerRef.current) {
        resizeObserver.unobserve(containerRef.current);
      }
    };
  }, []);

  const memoizedItems = useMemo(() => {
    const items: { type: "header" | "item" | "separator"; data: any }[] = [];
    groupedScribes.forEach((group, groupIdx) => {
      items.push({ type: "header", data: group.date });
      group.entries.forEach((scribe) => {
        items.push({ type: "item", data: scribe });
      });
      if (groupIdx < groupedScribes.length - 1) {
        items.push({ type: "separator", data: group.date });
      }
    });
    return items;
  }, [groupedScribes]);

  useEffect(() => {
    if (listRef.current && memoizedItems.length > 0) {
      listRef.current.resetAfterIndex(0, true);
    }
  }, [memoizedItems]);

  const getItemSize = useCallback(
    (index: number) => {
      const item = memoizedItems[index];
      switch (item.type) {
        case "header":
          return HEADER_HEIGHT;
        case "item":
          return ITEM_HEIGHT;
        case "separator":
          return SEPARATOR_HEIGHT;
        default:
          return HEADER_HEIGHT;
      }
    },
    [memoizedItems],
  );

  const renderItem = useCallback(
    ({ index, style }: { index: number; style: React.CSSProperties }) => {
      const item = memoizedItems[index];
      switch (item.type) {
        case "header":
          return (
            <div key={item.data} style={style} className="pr-1">
              <div className="flex justify-between pb-1">
                <p className="text-xs font-medium text-tertiary uppercase">
                  {item.data}
                </p>
              </div>
            </div>
          );
        case "item":
          return (
            <div key={item.data.audioId} style={style}>
              <ScribeListItem
                scribe={item.data}
                onClick={() => handleSelectScribe(item.data)}
                onDelete={() => {
                  deleteNoteRef.current = item.data;
                  openDeleteModalRef.current = true;
                }}
              />
            </div>
          );
        case "separator":
          return (
            <div
              key={`separator-${item.data}`}
              style={style}
              className="md:px-1 py-3"
            >
              <hr />
            </div>
          );
        default:
          return null;
      }
    },
    [memoizedItems, handleSelectScribe],
  );

  const handleDelete = () => {
    dispatch(
      deleteScribe(deleteNoteRef.current.audioId, () => {
        dispatch(getProviderNotes(user.doctor_id));
        openDeleteModalRef.current = false;
      }),
    );
  };

  return (
    <main className={`h-full ${className}`} ref={containerRef}>
      <VariableSizeList
        ref={listRef}
        className="scrollbar"
        height={containerHeight}
        itemCount={memoizedItems.length}
        itemSize={getItemSize}
        width="100%"
        overscanCount={15}
      >
        {renderItem}
      </VariableSizeList>
      {openDeleteModalRef.current && (
        <DeleteModal
          title="Delete Encounter"
          description="Are you sure you want to delete the note?"
          onDelete={handleDelete}
          onClose={() => {
            openDeleteModalRef.current = false;
          }}
        />
      )}
    </main>
  );
};

export default ScribeListContent;
