import { useEffect, useState } from "react";
import { convPerPage } from "../../../../helpers/constants";
import { useAppDispatch, useAppSelector } from "../../../../store";
import {
  setConversationsUpdatedAction,
  setFiltersUpdatedAction,
} from "../../../../store/conversation/actions";
import { searchConversationsWithFilters } from "../../../../store/conversation/thunks";
import type { Patient } from "../../../../store/patient/interfaces";
import { getProfileConversation } from "../../../../store/patient/thunks";
import AddPhoneNumber from "../../../Basic/AddPhoneNumber";
import type { FixLater } from "../../../IndependentScribe/store/scribeSlice";
import Chat from "../../../Messaging/Conversation/Chat";
import InputArea from "../../../Messaging/Conversation/InputArea";

type Conversation = FixLater;
type Message = FixLater;

type MessageTab = {
  patientInfo: Patient;
  conversation: Conversation;
  messages: Message[];
  setMessages: (messages: Message[]) => void;
};

const MessageTab: React.FC<MessageTab> = ({
  patientInfo,
  conversation,
  messages,
  setMessages,
}) => {
  const {
    conversationSearchInput,
    activeInbox,
    activeFilters,
    convCurrPage,
    conversationsUpdated,
    onMessageConversationId,
    selectedDoctorInbox,
  } = useAppSelector((state) => state.conversation);
  const [scrolledUp, setScrolledUp] = useState(false);
  const [showResponse, setShowResponse] = useState(false);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(setConversationsUpdatedAction(false));
  }, [dispatch]);

  useEffect(() => {
    if (!conversationsUpdated) {
      updateConversations();
    }
  }, [conversationsUpdated]);

  const updateConversations = () => {
    if (
      patientInfo &&
      onMessageConversationId &&
      patientInfo.conversation_id === onMessageConversationId
    ) {
      dispatch(getProfileConversation(patientInfo.conversation_id));
    }
    dispatch(setConversationsUpdatedAction(true));

    dispatch(
      searchConversationsWithFilters(
        conversationSearchInput,
        activeFilters,
        1,
        convPerPage * convCurrPage,
        activeInbox,
        selectedDoctorInbox,
        () => dispatch(setFiltersUpdatedAction(true)),
      ),
    );
  };

  return (
    <div className="min-h-0 h-full w-full grid grid-rows-note-layout bg-gray-background">
      {patientInfo.phone_number ? (
        conversation && (
          <>
            <Chat
              scrolledUp={scrolledUp}
              setScrolledUp={setScrolledUp}
              showResponse={showResponse}
              currentConversation={conversation}
              currentConversationId={conversation.conversation_id}
            />
            <div>
              <InputArea
                setScrolledUp={setScrolledUp}
                messages={messages}
                setMessages={setMessages}
                showResponse={showResponse}
                setShowResponse={setShowResponse}
                currentConversation={conversation}
                currentConversationId={conversation.conversation_id}
              />
            </div>
          </>
        )
      ) : (
        <AddPhoneNumber patientId={patientInfo.patient_id} />
      )}
    </div>
  );
};

export default MessageTab;
