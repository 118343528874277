import { useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";
import { useDispatch, useSelector } from "react-redux";
import { ReactComponent as UploadIcon } from "../../assets/icons/arrow-up-tray.svg";
import { ReactComponent as FileIcon } from "../../assets/icons/document-text.svg";
import { ReactComponent as TrashIcon } from "../../assets/icons/trash-redesign.svg";
import { rowsPerPage } from "../../helpers/constants";
import { importPatients } from "../../store/patient/thunks";
import Button from "../Basic/Button";
import Modal from "../Basic/Modal";

const ImportModal = ({ onClose }) => {
  const {
    searchValue,
    currPage,
    selectedDoctorPatients,
    selectedPatientCampaigns,
    selectedPatientStatuses,
  } = useSelector((state) => state.patient);
  const [files, setFiles] = useState([]);
  const dispatch = useDispatch();

  const onDrop = useCallback((acceptedFiles) => {
    setFiles(acceptedFiles);
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: onDrop,
    multiple: false,
    accept: {
      "text/*": [".csv"],
    },
  });

  const onSave = () => {
    dispatch(
      importPatients(
        files[0],
        1,
        rowsPerPage * currPage,
        searchValue,
        selectedDoctorPatients,
        selectedPatientCampaigns,
        selectedPatientStatuses,
      ),
    );
    onClose();
  };

  const deleteFile = (fileName) => {
    setFiles(files.filter((file) => file.name !== fileName));
  };

  const humanReadableFileSize = (size) => {
    const i = size === 0 ? 0 : Math.floor(Math.log(size) / Math.log(1024));
    return `${(size / 1024 ** i).toFixed(2) * 1} ${["B", "KB", "MB", "GB", "TB"][i]}`;
  };

  return (
    <Modal className="pt-5 rounded-2xl">
      <div className="w-128">
        <p className="text-base md:text-xl font-semibold mb-1 mx-5">
          Import new patient
        </p>
        <p className="text-xs md:text-sm text-neutral-500 mb-5 mx-5">
          Upload a CSV to import new patients to your patient list
        </p>

        <div
          {...getRootProps()}
          className={`rounded-xl border border-dashed p-2 text-sm font-medium text-tertiary flex flex-col items-center
            hover:border-primary-blue hover:bg-primary-blue-light height-xs:p-6 cursor-pointer mx-5 group
            ${isDragActive ? "bg-primary-blue-light border-primary-blue" : "border-gray-300"}`}
        >
          <input {...getInputProps()} />
          <div
            className={`h-10 w-10 group-hover:bg-primary-blue rounded-full flex items-center justify-center
              mb-1 height-xs:mb-3
              ${isDragActive ? "bg-primary-blue" : "bg-primary"}`}
          >
            <UploadIcon width="20" height="20" stroke="#FFFFFF" />
          </div>
          {isDragActive ? (
            <p>Drop the files here ...</p>
          ) : (
            <p>
              <span className="text-primary-blue font-semibold">
                Click to upload
              </span>{" "}
              or drag and drop
            </p>
          )}
        </div>

        <div
          className="flex flex-col md:flex-row justify-between gap-1 md:gap-2 text-sm text-gray-400 font-medium
            mt-2 px-5"
        >
          <p>Supported formats : CSV</p>
          <p>Maximum size : 20MB</p>
        </div>

        {files.length > 0 &&
          files.map((file) => (
            <div
              key={file.name}
              className="rounded-xl border border-gray-foreground bg-gray-110 p-4 text-sm flex items-start space-x-4
                mt-5 mx-5"
            >
              <div
                className="w-10 h-10 rounded-lg border border-gray-foreground bg-white flex-none
                  flex items-center justify-center"
              >
                <FileIcon width="20" height="20" stroke="#2970FF" />
              </div>
              <div className="flex items-start justify-between w-full">
                <div>
                  <p className="text-primary font-semibold">{file.name}</p>
                  <p className="text-tertiary font-medium">
                    {humanReadableFileSize(file.size)}
                  </p>
                </div>
                <button type="button" onClick={() => deleteFile(file.name)}>
                  <TrashIcon width="20" height="20" />
                </button>
              </div>
            </div>
          ))}

        <div className="flex justify-end gap-4 font-semibold text-sm bg-gray-background py-4 px-5 mt-5">
          <Button className="w-full md:w-fit" variant="gray" onClick={onClose}>
            Cancel
          </Button>
          <Button className="w-full md:w-fit" onClick={onSave}>
            Save
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default ImportModal;
