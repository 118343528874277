import { produce } from "immer";
import { createSelector } from "reselect";
import { getDurationString, parseDateString } from "../../../helpers/helpers";
import type { RootState } from "../../../store";
import type { Patient } from "../../../store/patient/interfaces";
import type { Scribe } from "./interfaces";
import { scribesAdapter } from "./scribeSlice";

const selectScribeState = (state: RootState) => state.scribe;

const scribeSelectors = scribesAdapter.getSelectors(selectScribeState);

export const getAllScribes = scribeSelectors.selectAll;

export const getScribes = createSelector([getAllScribes], (scribes) =>
  scribes.filter((scribe) => scribe.type === "scribe"),
);

export const getByPatientId = createSelector(
  [getAllScribes, (_: RootState, patientId: number) => patientId],
  (scribes, patientId) =>
    scribes.filter((scribe) => scribe.patient?.patient_id === patientId),
);

export const getScribeById = (audioId: string) => (state: RootState) =>
  scribeSelectors.selectById(state, audioId);

export const getRecordingScribe = createSelector(getAllScribes, (scribes) =>
  scribes.find((scribe) => scribe.isRecording),
);

export const getSelectedAudioId = (state: RootState) =>
  state.scribe.selectedAudioId;

export const getSelectedScribe = createSelector(
  [getAllScribes, getSelectedAudioId],
  (scribes, selectedAudioId): Scribe | null => {
    if (!selectedAudioId) {
      return null;
    }

    return scribes.find((scribe) => scribe.audioId === selectedAudioId);
  },
);

const getCurrentTime = (state: RootState) => state.timer.currentTime;

export type ScribeGroup = {
  date: string;
  sortableDate: string;
  entries: ScribeGroupItem[];
};

export type ScribeGroupItem = {
  id: number;
  audioId: string;
  title: string;
  time: string;
  createdAt: number;
  duration: string;
  patient: Patient | null;
  isRecording: boolean;
  isGenerating: boolean;
  isPaused: boolean;
  note?: string;
  type: "scribe" | "call" | "chat" | "ccm";
};

/**
 * Groups scribes into organized collections based on a customizable grouping strategy.
 *
 * @param scribes - An array of scribe records to be grouped.
 * @param currentTime - The current time used for calculating ongoing recording durations.
 * @param groupingCallback - Optional callback function to determine how scribes are grouped.
 *        If not provided, uses a default grouping strategy based on date proximity (Today, Yesterday, This Week, etc.).
 *
 * @returns An array of grouped scribes, sorted by date with entries sorted within each group.
 *
 * */
const groupScribes = (
  scribes: Scribe[],
  currentTime: Date,
  groupingCallback?: (scribe: Scribe) => string,
  timeOptions?: Intl.DateTimeFormatOptions,
): ScribeGroup[] => {
  if (!scribes) {
    return [];
  }

  const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  const defaultGroupingCallback = (note: Scribe) => {
    const createDate = parseDateString(
      note.createdAt || new Date().toISOString(),
    );
    const today = new Date();
    today.setHours(0, 0, 0, 0);

    const isToday = createDate.toDateString() === today.toDateString();
    const dayDifference =
      (today.getTime() - createDate.getTime()) / (1000 * 60 * 60 * 24);

    if (isToday) {
      return "Today";
    }
    if (dayDifference < 1) {
      return "Yesterday";
    }
    if (dayDifference < 7) {
      return createDate.toLocaleDateString("en-US", {
        weekday: "long",
      });
    }

    return createDate.toLocaleString("en-US", {
      timeZone: userTimezone,
      month: "numeric",
      day: "numeric",
      year: "numeric",
    });
  };

  const defaultTimeOptions: Intl.DateTimeFormatOptions = {
    timeZone: userTimezone,
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  };

  const getGroupKey = groupingCallback || defaultGroupingCallback;

  const today = new Date();
  today.setHours(0, 0, 0, 0);

  const groupedScribes = produce<ScribeGroup[]>([], (draft) => {
    scribes.forEach((note) => {
      if (!note.isVisible) {
        return;
      }

      const createDate = parseDateString(
        note.createdAt || new Date().toISOString(),
      );
      const startDate = parseDateString(
        note.startedAt || new Date().toISOString(),
      );

      const formattedDate = getGroupKey(note);
      const sortableDate = createDate.toISOString();

      let group = draft.find((g) => g.date === formattedDate);
      if (!group) {
        group = {
          date: formattedDate,
          sortableDate,
          entries: [],
        };
        draft.push(group);
      }

      const durationInSeconds =
        note.finishedAt || !note.isRecording
          ? Math.floor(note.accumulatedDuration / 1000)
          : Math.max(
              0,
              Math.floor(
                (currentTime.getTime() -
                  startDate.getTime() +
                  note.accumulatedDuration) /
                  1000,
              ),
            );

      const item: ScribeGroupItem = {
        id: note.noteId,
        audioId: note.audioId,
        title: note.title,
        time: createDate.toLocaleString(
          "en-US",
          timeOptions || defaultTimeOptions,
        ),
        createdAt: createDate.getTime(),
        duration: getDurationString(durationInSeconds),
        patient: note.patient,
        isRecording: note.isRecording,
        isGenerating: note.isGenerating,
        isPaused:
          (note.isRecording && note.startedAt === null) || note.isPaused,
        type: note.type,
        note: note.note,
      };

      group.entries.push(item);

      group.entries.sort((item1, item2) => item2.createdAt - item1.createdAt);
    });

    draft.sort(
      (group1, group2) =>
        new Date(group2.sortableDate).getTime() -
        new Date(group1.sortableDate).getTime(),
    );
  });

  return groupedScribes;
};

export const getGroupedScribes = createSelector(
  [getScribes, getCurrentTime],
  (scribes, currentTime): ScribeGroup[] => {
    return groupScribes(scribes, currentTime);
  },
);

export const getGroupedScribesByPatientId = createSelector(
  [getByPatientId, getCurrentTime],
  (scribes, currentTime): ScribeGroup[] => {
    return groupScribes(
      scribes,
      currentTime,
      (scribe) =>
        new Date(scribe.createdAt).toLocaleString("en-US", {
          month: "long",
          year: "numeric",
        }),
      {
        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        month: "short",
        day: "2-digit",
      },
    );
  },
);
