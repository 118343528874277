import { LOGOUT, SET_TEMPLATES, SET_TEMPLATES_LOADER } from "../types";

const init = {
  templates: [],
  loader: false,
};

export default function templateReducer(state = init, action) {
  const { type, payload } = action;

  switch (type) {
    case LOGOUT:
      return { ...init };
    case SET_TEMPLATES:
      return { ...state, templates: payload.templates };
    case SET_TEMPLATES_LOADER:
      return { ...state, loader: payload.loader };
    default:
      return state;
  }
}
