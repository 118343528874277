import Bugsnag from "@bugsnag/js";
import axios from "axios";
import dayjs from "dayjs";
import { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { ReactComponent as CheckIcon } from "../assets/icons/check-circle-completed.svg";
import { ReactComponent as RemoveIcon } from "../assets/icons/close.svg";
import { ReactComponent as TemplateIcon } from "../assets/icons/library.svg";
import { ReactComponent as WandIcon } from "../assets/icons/magic-stick.svg";
import { ReactComponent as HistoryIcon } from "../assets/icons/sidebar-left.svg";
import AutocompleteRequests from "../components/Basic/Autocomplete/AutocompleteRequests";
import AutocompleteVirtualized from "../components/Basic/Autocomplete/AutocompleteVirtualized";
import Button from "../components/Basic/Button";
import CircleDivider from "../components/Basic/CircleDivider";
import DateTimeShortcutsInput from "../components/Basic/DateTimeShortcutsInput";
import CustomDateShortcuts from "../components/Basic/DateTimeShortcutsInput/CustomDateShortcuts";
import Loader from "../components/Basic/Loader";
import LoaderDots from "../components/Basic/LoaderDots";
import ProfilePicture from "../components/Basic/ProfilePicture";
import Select from "../components/Basic/Select";
import Switch from "../components/Basic/Switch";
import TextareaAutosizeForm from "../components/Basic/TextareaAutosizeForm";
import TextareaOrderedLines from "../components/Basic/TextareaOrderedLines";
import HistoryModal from "../components/Campaigns/HistoryModal";
import TemplateModal from "../components/Campaigns/TemplateModal";
import { BASE_URL } from "../helpers/config";
import {
  Pages,
  chronicCcmPlaceholder,
  chronicPostDischargePlaceholder,
  frequency,
  frequencyCCM,
  goalsCcmPlaceholder,
  goalsPostDischargePlaceholder,
  medicalCcmPlaceholder,
  medicalPostDischargePlaceholder,
  shortcutsItems,
  twoConditionsRegex,
} from "../helpers/constants";
import { getAge } from "../helpers/helpers";
import {
  setEnrollFieldsAction,
  setRerenderEnrollFieldsAction,
  setSelectedEnrollCampaignAction,
  setSelectedEnrollPatientsAction,
  setSelectedStartDateAction,
  setSmartEnrollGeneratedAction,
} from "../store/campaign/actions";
import {
  campaignSmartEnroll,
  cancelUpcomingMessage,
  getUpcomingMessages,
  scheduleCampaign,
} from "../store/campaign/thunks";
import { enrollPatientWithAi, getPatientInfo } from "../store/patient/thunks";
import { setNotificationAction } from "../store/user/actions";

const Enroll = () => {
  const { user, doctorOptions } = useSelector((state) => state.user);
  const { loader } = useSelector((state) => state.patient);
  const {
    campaigns,
    selectedEnrollCampaign,
    upcomingMessages,
    selectedEnrollPatients,
    rerenderEnrollFields,
    enrollFields,
    activeStartDateShortcut,
    smartEnrollLoading,
    smartEnrollGenerated,
  } = useSelector((state) => state.campaign);
  const [selectedPatientsType, setSelectedPatientsType] = useState("none");
  const [campaignEnrollFields, setCampaignEnrollFields] = useState({});
  const [campaignSelectedPatients, setCampaignSelectedPatients] = useState([]);
  const [selectedPatientIds, setSelectedPatientIds] = useState([]);
  const [enrolledPatientIds, setEnrolledPatientIds] = useState([]);
  const [allPatients, setAllPatients] = useState([]);
  const [allUpcoming, setAllUpcoming] = useState([]);
  const [options, setOptions] = useState([]);
  const [optionsTotal, setOptionsTotal] = useState(0);
  const [optionsLoading, setOptionsLoading] = useState(false);
  const [openTemplates, setOpenTemplates] = useState(false);
  const [selectedProvider, setSelectedProvider] = useState(null);
  const [aiChat, setAiChat] = useState(true);
  const [verbalConsent, setVerbalConsent] = useState(true);
  const [openHistory, setOpenHistory] = useState(false);
  const [selectedPatientHistory, setSelectedPatientHistory] = useState(null);
  const [carePlan, setCarePlan] = useState(null);
  const dispatch = useDispatch();
  const rows = 50;

  const getDefaultFrequency = () => {
    if (selectedEnrollCampaign?.default_frequency) {
      return selectedEnrollCampaign.default_frequency;
    }
    if (selectedEnrollCampaign?.campaign_type.includes("Outreach")) {
      return "monthly";
    }
    return "once";
  };

  const getDefaultStartDate = useCallback(() => {
    if (selectedEnrollCampaign?.default_time_to_send) {
      switch (selectedEnrollCampaign.default_time_to_send) {
        case "now":
          return "now";
        case "tomorrow":
          return dayjs().add(1, "day");
        case "next_week":
          return dayjs().add(1, "week");
        case "next_month":
          return dayjs().add(1, "month");
        default:
          return dayjs().add(1, "day");
      }
    }
    if (selectedEnrollCampaign?.campaign_type === "HPI") {
      return "now";
    }
    return dayjs().add(1, "day");
  }, [selectedEnrollCampaign]);

  const {
    register,
    handleSubmit,
    control,
    setValue,
    getValues,
    reset,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm({
    defaultValues: {
      chronicConditions: "",
      goals: "",
      markers: "",
      pertinentMedicalHistory: "",
      frequency: getDefaultFrequency(),
      start: getDefaultStartDate() === "now" ? dayjs() : getDefaultStartDate(),
    },
  });

  useEffect(() => {
    searchPatients(1, rows, setAllPatients);
  }, []);

  useEffect(() => {
    setCampaignSelectedPatients(
      selectedEnrollPatients[selectedEnrollCampaign?.campaign_id] || [],
    );
  }, [selectedEnrollPatients, selectedEnrollCampaign]);

  useEffect(() => {
    setCampaignEnrollFields(
      enrollFields[selectedEnrollCampaign?.campaign_id] || {},
    );
  }, [enrollFields, selectedEnrollCampaign]);

  useEffect(() => {
    setSavedValues();
    if (campaignEnrollFields.start) {
      dispatch(setSelectedStartDateAction(campaignEnrollFields.start));
    } else {
      const dateString =
        getDefaultStartDate() === "now"
          ? "now"
          : getDefaultStartDate().toDate().toLocaleString("en-us");
      dispatch(setSelectedStartDateAction(dateString));
    }
  }, [campaignEnrollFields, dispatch, getDefaultStartDate]);

  useEffect(() => {
    if (campaigns.length > 0) {
      getAllUpcoming();
    }
    if (upcomingMessages.length > 0) {
      setEnrolledPatientIds(
        upcomingMessages.map((upcoming) => upcoming.patient.patient_id),
      );
    } else {
      setEnrolledPatientIds([]);
    }
  }, [upcomingMessages, campaigns.length]);

  useEffect(() => {
    clearErrors();
    if (selectedEnrollCampaign) {
      dispatch(getUpcomingMessages([selectedEnrollCampaign.campaign_id]));
    }
  }, [selectedEnrollCampaign, dispatch, clearErrors]);

  useEffect(() => {
    setOptions(
      allPatients.filter(
        (patient) =>
          !campaignSelectedPatients.some(
            (selectedPatient) =>
              selectedPatient.patient_id === patient.patient_id,
          ),
      ),
    );
  }, [allPatients, campaignSelectedPatients]);

  useEffect(() => {
    if (rerenderEnrollFields) {
      setCampaignSelectedPatients(
        selectedEnrollPatients[selectedEnrollCampaign?.campaign_id] || [],
      );
      dispatch(setRerenderEnrollFieldsAction(false));
    }
  }, [
    rerenderEnrollFields,
    dispatch,
    selectedEnrollPatients,
    selectedEnrollCampaign?.campaign_id,
  ]);

  useEffect(() => {
    if (campaignSelectedPatients.length > 0) {
      setSelectedPatientIds(
        campaignSelectedPatients.map((patient) => patient.patient_id),
      );
    } else {
      setSelectedPatientIds([]);
    }
  }, [campaignSelectedPatients]);

  useEffect(() => {
    if (selectedPatientIds.length > 0) {
      dispatch(
        getPatientInfo(selectedPatientIds[0], (patientInfo) => {
          setSelectedPatientHistory(patientInfo);
        }),
      );
    } else {
      setSelectedPatientHistory(null);
      setOpenHistory(false);
      setCarePlan(null);
    }
  }, [selectedPatientIds, dispatch]);

  useEffect(() => {
    if (
      selectedPatientIds.length === 1 &&
      enrolledPatientIds.find((id) => id === selectedPatientIds[0])
    ) {
      setSelectedPatientsType("enrolled");
    } else if (
      selectedPatientIds.length >= 1 &&
      !enrolledPatientIds.find((id) => id === selectedPatientIds[0])
    ) {
      setSelectedPatientsType("notEnrolled");
    } else {
      setSelectedPatientsType("none");
    }
  }, [selectedPatientIds, enrolledPatientIds]);

  useEffect(() => {
    if (
      selectedPatientsType === "enrolled" ||
      (campaignSelectedPatients.length > 0 &&
        campaignSelectedPatients[0].campaign_id)
    ) {
      if (
        campaignSelectedPatients[0]?.consent === undefined ||
        campaignSelectedPatients[0]?.consent === null
      ) {
        setVerbalConsent(true);
      } else {
        setVerbalConsent(!!campaignSelectedPatients[0]?.consent);
      }
    } else if (selectedPatientsType === "notEnrolled") {
      setVerbalConsent(true);
    } else {
      setVerbalConsent(true);
    }
  }, [selectedPatientsType, campaignSelectedPatients]);

  const onSmartEnroll = (e) => {
    e.preventDefault();
    const generatedData = smartEnrollGenerated[selectedPatientIds[0]];
    if (generatedData) {
      setCarePlan(generatedData.providerSummary);
      setOpenHistory(true);
      dispatch(
        setEnrollFieldsAction({
          ...enrollFields,
          [selectedEnrollCampaign.campaign_id]: {
            chronicConditions: generatedData.conditionsToMonitor,
            goals: generatedData.lifestyleHealthGoals,
            markers: generatedData.objectiveMarkers,
            pertinentMedicalHistory: generatedData.relevantPastMedicalHistory,
          },
        }),
      );
    } else {
      dispatch(
        campaignSmartEnroll(
          selectedPatientIds[0],
          selectedEnrollCampaign?.campaign_id,
          (data) => {
            setCarePlan(data.providerSummary);
            setOpenHistory(true);
            dispatch(
              setEnrollFieldsAction({
                ...enrollFields,
                [selectedEnrollCampaign.campaign_id]: {
                  chronicConditions: data.conditionsToMonitor,
                  goals: data.lifestyleHealthGoals,
                  markers: data.objectiveMarkers,
                  pertinentMedicalHistory: data.relevantPastMedicalHistory,
                },
              }),
            );
            dispatch(
              setSmartEnrollGeneratedAction({ [selectedPatientIds[0]]: data }),
            );
          },
        ),
      );
    }
  };

  const onSubmit = (data) => {
    if (
      verbalConsent &&
      selectedEnrollCampaign.campaign_type === "Outreach" &&
      !twoConditionsRegex.test(getValues("chronicConditions"))
    ) {
      setError("chronicConditions", {
        type: "pattern",
        message: "Please provide at least two conditions",
      });
      return;
    }

    let isNow = false;
    if (data.start) {
      data.start = data.start.toDate().toISOString();
      const currentDateTime = dayjs();
      const selectedDateTime = dayjs(data.start);
      isNow = selectedDateTime.isSameOrBefore(currentDateTime);
    }
    if (activeStartDateShortcut === "Now") {
      isNow = true;
    }

    // if the patient is already enrolled
    if (selectedPatientsType === "enrolled") {
      const upcomingMessage = upcomingMessages.find(
        (upcoming) =>
          upcoming.patient.patient_id ===
          campaignSelectedPatients[0].patient_id,
      );

      // if the ai_chat was true and now it is false
      if (!!upcomingMessage.patient.ai_chat && !aiChat) {
        // delete the campaign scheduled
        dispatch(
          cancelUpcomingMessage(
            upcomingMessage.campaign_id,
            upcomingMessage.campaign_patient_id,
            upcomingMessage.patient.patient_id,
            () =>
              dispatch(
                getUpcomingMessages([selectedEnrollCampaign.campaign_id]),
              ),
            false,
          ),
        );
      }

      // if the ai_chat was false and now it is true
      if (!upcomingMessage.patient.ai_chat && !!aiChat) {
        // new schedule
        console.log("new schedule");
        dispatch(
          scheduleCampaign(
            selectedEnrollCampaign.campaign_id,
            selectedPatientIds,
            isNow ? "now" : data.start,
            data.frequency,
            data.chronicConditions,
            data.goals,
            // todo
            // data.markers,
            data.pertinentMedicalHistory,
            selectedProvider ? selectedProvider.doctor_id : null,
            verbalConsent,
            false,
            () =>
              dispatch(
                getUpcomingMessages([selectedEnrollCampaign.campaign_id]),
              ),
            false,
          ),
        );
      }

      // if the ai_chat was true and keep true
      if (!!upcomingMessage.patient.ai_chat && !!aiChat) {
        // reschedule if start or frequency is changed
        const reschedule =
          new Date(
            `${upcomingMessage.next_checkin.replace(/-/g, "/")} GMT+0`,
          ).toLocaleDateString() !==
            new Date(data.start).toLocaleDateString() ||
          upcomingMessage.frequency !== data.frequency;
        console.log("reschedule", reschedule);

        dispatch(
          scheduleCampaign(
            selectedEnrollCampaign.campaign_id,
            selectedPatientIds,
            isNow ? "now" : data.start,
            data.frequency,
            data.chronicConditions,
            data.goals,
            // todo
            // data.markers,
            data.pertinentMedicalHistory,
            selectedProvider ? selectedProvider.doctor_id : null,
            verbalConsent,
            reschedule,
            () =>
              dispatch(
                getUpcomingMessages([selectedEnrollCampaign.campaign_id]),
              ),
            false,
          ),
        );
      }
    } else {
      // if the patient is not enrolled and ai_chat is true
      if (aiChat) {
        console.log("new schedule");
        dispatch(
          scheduleCampaign(
            selectedEnrollCampaign.campaign_id,
            selectedPatientIds,
            isNow ? "now" : data.start,
            data.frequency,
            data.chronicConditions,
            data.goals,
            // todo
            // data.markers,
            data.pertinentMedicalHistory,
            selectedProvider ? selectedProvider.doctor_id : null,
            verbalConsent,
            false,
            () =>
              dispatch(
                getUpcomingMessages([selectedEnrollCampaign.campaign_id]),
              ),
            false,
          ),
        );
      }
    }

    // enroll patient
    dispatch(
      enrollPatientWithAi(
        selectedPatientIds[0],
        selectedEnrollCampaign.campaign_id,
        data.chronicConditions,
        data.goals,
        // todo
        // data.markers,
        data.pertinentMedicalHistory,
        selectedProvider ? selectedProvider.doctor_id : null,
        aiChat,
        verbalConsent,
        () =>
          dispatch(getUpcomingMessages([selectedEnrollCampaign.campaign_id])),
        selectedPatientsType === "enrolled",
      ),
    );

    reset();
    dispatch(
      setSelectedEnrollPatientsAction({
        ...selectedEnrollPatients,
        [selectedEnrollCampaign.campaign_id]: [],
      }),
    );
    dispatch(
      setEnrollFieldsAction({
        ...enrollFields,
        [selectedEnrollCampaign.campaign_id]: {},
      }),
    );
    setValue("frequency", getDefaultFrequency());
    setValue(
      "start",
      getDefaultStartDate() === "now" ? dayjs() : getDefaultStartDate(),
    );
    setSelectedProvider(null);
    setAiChat(true);
  };

  const saveField = (name, value) => {
    dispatch(
      setEnrollFieldsAction({
        ...enrollFields,
        [selectedEnrollCampaign?.campaign_id]: {
          ...(enrollFields[selectedEnrollCampaign?.campaign_id] || {}),
          [name]: value,
        },
      }),
    );
  };

  const setSavedValues = () => {
    setValue(
      "start",
      campaignEnrollFields.start
        ? campaignEnrollFields.start === "now"
          ? dayjs()
          : dayjs(campaignEnrollFields.start)
        : getDefaultStartDate() === "now"
          ? dayjs()
          : getDefaultStartDate(),
    );
    setValue(
      "frequency",
      campaignEnrollFields.frequency
        ? campaignEnrollFields.frequency
        : getDefaultFrequency(),
    );
    setValue(
      "chronicConditions",
      campaignEnrollFields.chronicConditions
        ? campaignEnrollFields.chronicConditions
        : "",
    );
    setValue(
      "goals",
      campaignEnrollFields.goals ? campaignEnrollFields.goals : "",
    );
    setValue(
      "markers",
      campaignEnrollFields.markers ? campaignEnrollFields.markers : "",
    );
    setValue(
      "pertinentMedicalHistory",
      campaignEnrollFields.pertinentMedicalHistory
        ? campaignEnrollFields.pertinentMedicalHistory
        : "",
    );

    setSelectedProvider(
      campaignEnrollFields.doctorId
        ? doctorOptions.find(
            (doctor) => doctor.doctor_id === campaignEnrollFields.doctorId,
          )
        : null,
    );
    setAiChat(!!campaignEnrollFields.aiChat);
  };

  const saveFilledValues = (patient) => {
    const upcomingMessage = upcomingMessages.find(
      (upcoming) => upcoming.patient.patient_id === patient.patient_id,
    );
    const defaultDateString =
      getDefaultStartDate() === "now"
        ? "now"
        : getDefaultStartDate().toDate().toLocaleString("en-us");
    const selectedDate = upcomingMessage
      ? upcomingMessage.next_checkin
        ? new Date(
            `${upcomingMessage.next_checkin.replace(/-/g, "/")} GMT+0`,
          ).toLocaleString("en-us")
        : defaultDateString
      : campaignEnrollFields.start || defaultDateString;

    dispatch(
      setEnrollFieldsAction({
        ...enrollFields,
        [selectedEnrollCampaign?.campaign_id]: {
          chronicConditions: upcomingMessage
            ? upcomingMessage.outreach_focus || ""
            : patient.conditions
              ? patient.conditions
              : campaignEnrollFields.chronicConditions || "",
          goals: upcomingMessage
            ? upcomingMessage.outreach_goals || ""
            : patient.goals
              ? patient.goals
              : campaignEnrollFields.goals || "",
          markers: upcomingMessage
            ? upcomingMessage.markers || ""
            : patient.markers
              ? patient.markers
              : campaignEnrollFields.markers || "",
          pertinentMedicalHistory: upcomingMessage
            ? upcomingMessage.ehr_information || ""
            : patient.past_medical_history
              ? patient.past_medical_history
              : campaignEnrollFields.pertinentMedicalHistory || "",
          frequency: upcomingMessage
            ? upcomingMessage.frequency
              ? upcomingMessage.frequency
              : getDefaultFrequency()
            : campaignEnrollFields.frequency || getDefaultFrequency(),
          start: selectedDate,
          doctorId: upcomingMessage
            ? upcomingMessage.patient.doctor_id
            : patient.doctor_id || "",
          aiChat: upcomingMessage
            ? !!upcomingMessage.patient.ai_chat
            : !!patient.ai_chat,
        },
      }),
    );
    dispatch(setSelectedStartDateAction(selectedDate));
  };

  const unselectPatient = (patientId) => {
    const patients = campaignSelectedPatients.filter(
      (patient) => patient.patient_id !== patientId,
    );
    dispatch(
      setSelectedEnrollPatientsAction({
        ...selectedEnrollPatients,
        [selectedEnrollCampaign?.campaign_id]: patients,
      }),
    );
  };

  const selectPatient = (patient) => {
    saveFilledValues(patient);

    dispatch(
      setSelectedEnrollPatientsAction({
        ...selectedEnrollPatients,
        [selectedEnrollCampaign?.campaign_id]: [patient],
      }),
    );
  };

  const searchPatients = useCallback(
    (page, rows, setPage, searchInput = "") => {
      setOptionsLoading(true);
      axios
        .post(`${BASE_URL}/patient/search`, {
          q: searchInput,
          offset: (page - 1) * rows,
          limit: rows,
          doctor_id: null,
          unassigned: false,
          campaigns: [],
          status: [],
        })
        .then((response) => {
          if (response.data.error) {
            dispatch(
              setNotificationAction({
                status: "error",
                title: "Something went wrong",
                desc: "Failed to load patients",
              }),
            );
            Bugsnag.notify(response.data.error);
          } else {
            setOptionsTotal(response.data.data.total);
            setPage(response.data.data.patients);
          }
          setOptionsLoading(false);
        });
    },
    [dispatch],
  );

  const loadOptions = useCallback(
    (inputValue, page) => {
      searchPatients(1, page * rows, setAllPatients, inputValue);
    },
    [searchPatients],
  );

  const getAllUpcoming = () => {
    axios
      .post(`${BASE_URL}/campaigns/next-checkins`, {
        campaign_ids: campaigns.map((campaign) => campaign.campaign_id),
      })
      .then((response) => {
        if (response.data.error) {
          dispatch(
            setNotificationAction({
              status: "error",
              title: "Something went wrong",
              desc: "Failed to load upcoming messages",
            }),
          );
          Bugsnag.notify(response.data.error);
        } else {
          setAllUpcoming(response.data.data.next_checkins);
        }
      });
  };

  const getAllUpcomingOfPatient = (patientId) => {
    return allUpcoming.filter((upcoming) => upcoming.patient_id === patientId);
  };

  const getOtherCampaigns = (patient) => {
    const allCampaigns = getAllUpcomingOfPatient(patient.patient_id);
    return allCampaigns.filter(
      (campaign) => campaign.campaign_id !== selectedEnrollCampaign.campaign_id,
    );
  };

  const countPatientsWithOtherCampaigns = (patients) => {
    let count = 0;
    patients.forEach((patient) => {
      if (getOtherCampaigns(patient).length > 0) {
        count++;
      }
    });
    return count;
  };

  const getCampaignNameById = (campaignId) => {
    const campaign = campaigns.find(
      (campaign) => campaign.campaign_id === campaignId,
    );
    return campaign ? campaign.campaign : "";
  };

  const getCurrentEnrollments = (campaignSelectedPatients) => {
    let content = null;
    if (campaignSelectedPatients.length === 1) {
      const otherCampaigns = getOtherCampaigns(campaignSelectedPatients[0]);
      content =
        otherCampaigns.length > 0 &&
        otherCampaigns.map((campaign) => (
          <div key={campaign.campaign_patient_id}>
            <div className="flex items-center justify-between space-x-2">
              <p className="truncate">
                {getCampaignNameById(campaign.campaign_id)}
              </p>
              <p className="text-xs text-zinc-450 font-medium">
                {new Date(
                  `${campaign.next_checkin.replace(/-/g, "/")} GMT+0`,
                ).toLocaleDateString("en-US", {
                  month: "2-digit",
                  day: "2-digit",
                  year: "2-digit",
                })}
              </p>
            </div>
            {campaign.outreach_focus && (
              <div className="mt-2 rounded-xl bg-gray-50 px-2 py-2.5 text-zinc-700 text-xs font-medium whitespace-pre-wrap">
                {campaign.outreach_focus}
              </div>
            )}
          </div>
        ));
    } else if (campaignSelectedPatients.length > 1) {
      const countPatients = countPatientsWithOtherCampaigns(
        campaignSelectedPatients,
      );
      content = countPatients > 0 && (
        <p className="rounded-xl bg-gray-50 px-2 py-2.5 text-zinc-700 text-xs font-medium">
          {countPatients} {countPatients === 1 ? "patient" : "patients"} have an
          ongoing conversations
        </p>
      );
    }

    return (
      content && (
        <div className="text-sm font-semibold space-y-4">
          <p>Current Enrollments:</p>
          {content}
          <p className="font-normal">
            Scheduled enrollments will be delayed 24hrs if a campaign is in
            progress. Complete ongoing campaign to send new enrollment.
          </p>
        </div>
      )
    );
  };

  return (
    <div
      className="p-4 bg-gray-background border-t md:border-t-0 md:border-l border-gray-foreground md:px-10 md:py-6
        overflow-y-auto scrollbar"
    >
      <p className="text-xl font-semibold">Care Management</p>
      <div
        className="flex flex-wrap md:flex-nowrap gap-3 md:gap-5 pt-3 md:pb-3 px-0.5 mb-6
          md:overflow-x-auto scrollbar"
      >
        {campaigns?.length > 0 ? (
          campaigns.map((campaign) => (
            <button
              type="button"
              className={`w-52 md:w-48 md:h-48 flex flex-col items-start flex-none p-3 md:p-4 rounded-lg border
                ${
                  selectedEnrollCampaign?.campaign_id === campaign.campaign_id
                    ? "border-primary text-primary bg-white"
                    : "border-gray-300 bg-transparent text-tertiary"
                }`}
              onClick={() =>
                dispatch(setSelectedEnrollCampaignAction(campaign))
              }
              key={campaign.campaign_id}
            >
              <div className="w-full flex justify-between space-x-3">
                <p className="text-left text-base font-semibold">
                  {campaign.campaign}
                </p>
                <div className="w-5">
                  {selectedEnrollCampaign?.campaign_id ===
                    campaign.campaign_id && (
                    <CheckIcon
                      width="20"
                      height="20"
                      fill="#121212"
                      className="flex-none"
                    />
                  )}
                </div>
              </div>
              <p className="text-left text-xs text-tertiary font-medium md:pt-1 hidden md:flex">
                {campaign.description}
              </p>
            </button>
          ))
        ) : (
          <p>No campaigns</p>
        )}
      </div>

      <div className="mb-6 md:max-w-xl">
        <p className="text-sm font-semibold mb-1.5">Patient</p>

        {campaignSelectedPatients.length > 0 ? (
          <div className="space-y-4">
            {campaignSelectedPatients.map((patient) => (
              <div
                key={patient.patient_id}
                className="flex items-center justify-between h-[42px]"
              >
                <div className="flex space-x-2 items-center truncate">
                  <ProfilePicture
                    firstName={
                      patient.preferred_name
                        ? patient.preferred_name
                        : patient.first_name
                    }
                    lastName={patient.last_name}
                    flagPadding={false}
                    src={patient.profile_picture}
                  />
                  <div className="font-medium truncate">
                    <p className="text-sm truncate">
                      {patient.preferred_name
                        ? patient.preferred_name
                        : patient.first_name}{" "}
                      {patient.last_name}
                    </p>
                    <div className="flex items-center space-x-2 text-xs text-tertiary truncate">
                      {patient.birthdate && (
                        <>
                          <p>Age {getAge(patient.birthdate)}</p>
                          <CircleDivider />
                        </>
                      )}
                      <p className="capitalize">
                        {patient.gender ? patient.gender : patient.sex}
                      </p>
                    </div>
                  </div>
                </div>
                <button
                  type="button"
                  aria-label="Remove"
                  className="w-8 h-8 bg-white rounded-lg flex items-center justify-center"
                  onClick={() => {
                    if (campaignSelectedPatients.length === 1) {
                      reset();
                      dispatch(
                        setEnrollFieldsAction({
                          ...enrollFields,
                          [selectedEnrollCampaign?.campaign_id]: {},
                        }),
                      );
                      setValue("frequency", getDefaultFrequency());
                      setValue(
                        "start",
                        getDefaultStartDate() === "now"
                          ? dayjs()
                          : getDefaultStartDate(),
                      );
                    }
                    unselectPatient(patient.patient_id);
                  }}
                >
                  <RemoveIcon stroke="#667085" height="16" width="16" />
                </button>
              </div>
            ))}
          </div>
        ) : (
          <AutocompleteRequests
            placeholder="Search"
            options={options}
            onSelect={selectPatient}
            disabledIds={[]}
            loadOptions={loadOptions}
            hasMore={options.length < optionsTotal}
            loading={optionsLoading}
            inputClassName="bg-white"
            pathBeforeProfile={Pages.ENROLL}
          />
        )}
      </div>

      <form className="space-y-6 md:max-w-xl" onSubmit={handleSubmit(onSubmit)}>
        {campaignSelectedPatients.length > 0 && (
          <>
            <div className="w-full flex flex-wrap items-center justify-between gap-2 text-sm font-medium">
              <p>I acknowledge the patient verbally consents to CCM</p>
              <div className="flex items-center space-x-2">
                <p
                  className={`w-6 text-center
                    ${verbalConsent ? "text-primary-blue" : "text-tertiary"}`}
                >
                  {verbalConsent ? "Yes" : "No"}
                </p>
                <Switch
                  size="medium"
                  enabled={verbalConsent}
                  onChange={() => setVerbalConsent(!verbalConsent)}
                />
              </div>
            </div>
            {verbalConsent && <hr />}
          </>
        )}

        {campaignSelectedPatients.length > 0 && !verbalConsent ? (
          <></>
        ) : (
          <>
            {selectedEnrollCampaign?.campaign_type.includes("Outreach") && (
              <>
                <div className="flex flex-wrap items-center">
                  <Button
                    variant={openTemplates ? "secondary-light" : "gray-light"}
                    size="unset"
                    className="h-8 px-4 text-sm font-semibold space-x-2 mr-2 mb-2 sm:mb-0"
                    onClick={(e) => {
                      e.preventDefault();
                      setOpenTemplates(true);
                    }}
                  >
                    <TemplateIcon
                      width="18"
                      height="18"
                      stroke={openTemplates ? "#2970FF" : "#667085"}
                      className="flex-none"
                    />
                    <p>Templates</p>
                  </Button>
                  {user.customer?.has_ehr_data && (
                    <>
                      <Button
                        disabled={
                          selectedPatientIds.length === 0 ||
                          !selectedPatientHistory?.features?.smartEnroll ||
                          smartEnrollLoading
                        }
                        variant={
                          smartEnrollLoading || carePlan
                            ? "secondary-light"
                            : "gray-light"
                        }
                        size="unset"
                        className="h-8 px-4 text-sm font-semibold space-x-2 whitespace-nowrap mr-2 mb-2 sm:mb-0"
                        onClick={onSmartEnroll}
                      >
                        {smartEnrollLoading ? (
                          <Loader size={4} borderWidth={2} />
                        ) : (
                          <WandIcon
                            width="18"
                            height="18"
                            stroke={carePlan ? "#2970FF" : "#667085"}
                            className="flex-none"
                          />
                        )}
                        <p>EHR Smart Import</p>
                      </Button>
                      {selectedPatientIds.length > 0 && (
                        <Button
                          variant={
                            openHistory ? "secondary-light" : "gray-light"
                          }
                          size="unset"
                          className="h-8 px-4 text-sm font-semibold space-x-2 mb-2 sm:mb-0"
                          onClick={(e) => {
                            e.preventDefault();
                            setOpenHistory(!openHistory);
                          }}
                        >
                          <HistoryIcon
                            width="18"
                            height="18"
                            stroke={openHistory ? "#2970FF" : "#667085"}
                            className="flex-none"
                          />
                          <p>History</p>
                        </Button>
                      )}
                    </>
                  )}
                </div>

                <TextareaOrderedLines
                  name="chronicConditions"
                  label="Conditions to Monitor"
                  placeholder={
                    selectedEnrollCampaign.campaign === "Post-Discharge Care"
                      ? chronicPostDischargePlaceholder
                      : chronicCcmPlaceholder
                  }
                  register={register}
                  setValue={setValue}
                  onChange={(e) =>
                    saveField("chronicConditions", e.target.value)
                  }
                  required={
                    verbalConsent
                      ? selectedEnrollCampaign.campaign_type === "Outreach"
                        ? "Please provide at least two conditions"
                        : "Please provide at least one condition"
                      : false
                  }
                  error={errors.chronicConditions}
                  minRows={2}
                  initLength={getValues("chronicConditions").length}
                  maxLength={500}
                />
                <TextareaAutosizeForm
                  name="goals"
                  label="Lifestyle Health Goals"
                  placeholder={
                    selectedEnrollCampaign.campaign === "Post-Discharge Care"
                      ? goalsPostDischargePlaceholder
                      : goalsCcmPlaceholder
                  }
                  onChange={(e) => {
                    saveField("goals", e.target.value);
                  }}
                  register={register}
                  minRows={2}
                  initLength={getValues("goals").length}
                  maxLength={5000}
                />
                <TextareaAutosizeForm
                  name="markers"
                  label="Objective Markers"
                  placeholder={`Blood pressure: Target <130/80 mmHg
Weight: Target 202 lbs (5% reduction)
BMI: Target <30 (current 30.5)`}
                  onChange={(e) => {
                    saveField("markers", e.target.value);
                  }}
                  register={register}
                  minRows={2}
                  initLength={getValues("markers").length}
                  maxLength={5000}
                />
                <TextareaAutosizeForm
                  name="pertinentMedicalHistory"
                  label="Relevant Past Medical History"
                  placeholder={
                    selectedEnrollCampaign.campaign === "Post-Discharge Care"
                      ? medicalPostDischargePlaceholder
                      : medicalCcmPlaceholder
                  }
                  onChange={(e) =>
                    saveField("pertinentMedicalHistory", e.target.value)
                  }
                  register={register}
                  minRows={2}
                  initLength={getValues("pertinentMedicalHistory").length}
                  maxLength={500}
                />
              </>
            )}

            <div>
              <p className="font-semibold mb-1.5 text-sm">
                Provider<span className="text-red-600">*</span>
              </p>

              {selectedProvider ? (
                <div className="grid grid-cols-conv-layout items-center justify-between gap-2.5 w-full min-w-0 h-[42px]">
                  <div className="flex items-center space-x-2.5 min-w-0">
                    <ProfilePicture
                      firstName={selectedProvider.first_name}
                      lastName={selectedProvider.last_name}
                      src={selectedProvider.profile_picture}
                      flagPadding={false}
                    />
                    <div className="truncate">
                      <p className="text-sm font-semibold truncate">
                        {selectedProvider.display_name}
                      </p>
                      <p className="text-xs font-medium text-tertiary truncate">
                        {selectedProvider.occupation}
                      </p>
                    </div>
                  </div>
                  <button
                    type="button"
                    className="w-8 h-8 bg-white rounded-lg flex items-center justify-center"
                    onClick={() => setSelectedProvider(null)}
                  >
                    <RemoveIcon stroke="#667085" width="16" height="16" />
                  </button>
                </div>
              ) : (
                <AutocompleteVirtualized
                  options={doctorOptions}
                  onSelect={(provider) => {
                    setSelectedProvider(provider);
                    saveField("doctorId", provider.doctor_id);
                  }}
                  className="w-full bg-white rounded-lg"
                  placeholder="Select Provider (Clinical Lead)"
                  isDoctors
                  pathBeforeProfile={Pages.ENROLL}
                />
              )}
            </div>

            <div>
              <p className="font-semibold mb-1.5 text-sm">AI Chat</p>
              <Switch
                size="medium"
                enabled={aiChat}
                onChange={() => {
                  saveField("aiChat", !aiChat);
                  setAiChat(!aiChat);
                }}
              />
            </div>

            {aiChat && (
              <>
                <div>
                  <>
                    <p className="text-sm font-semibold mb-1.5">Start</p>
                    <CustomDateShortcuts
                      items={shortcutsItems}
                      onChange={(value) => {
                        setValue("start", value);
                        saveField(
                          "start",
                          value.isSameOrBefore(dayjs())
                            ? "now"
                            : value.toDate().toLocaleString("en-us"),
                        );
                      }}
                      tabsClassName="w-full bg-white text-sm"
                      withoutPadding
                    />
                  </>

                  {activeStartDateShortcut !== "Now" && (
                    <DateTimeShortcutsInput
                      className="mt-2.5"
                      name="start"
                      withTime
                      withShortcuts
                      validateFunc={(value) => {
                        if (verbalConsent && aiChat) {
                          const selectedDate = new Date(value);
                          const currentDate = new Date();
                          if (selectedDate < currentDate) {
                            return "Please choose a date in the future";
                          }
                          return true;
                        }
                        return true;
                      }}
                      control={control}
                      error={errors.start}
                      minDateTime={dayjs()}
                      onSelectDate={(date) => {
                        const dateString = date
                          .toDate()
                          .toLocaleString("en-us");
                        const isNow = date.isSameOrBefore(dayjs());
                        saveField("start", isNow ? "now" : dateString);
                        dispatch(
                          setSelectedStartDateAction(
                            isNow ? "now" : dateString,
                          ),
                        );
                      }}
                    />
                  )}
                </div>
                <Select
                  register={register}
                  label="Frequency"
                  name="frequency"
                  placeholder="Select frequency"
                  options={
                    selectedEnrollCampaign?.campaign_type.includes("Outreach")
                      ? frequencyCCM
                      : frequency
                  }
                  onChange={(e) => saveField("frequency", e.target.value)}
                />
              </>
            )}

            {getCurrentEnrollments(campaignSelectedPatients)}
          </>
        )}

        <div className="flex justify-end space-x-3">
          <Button
            variant="blue-light"
            className="w-full sm:w-36 font-semibold"
            disabled={
              loader ||
              selectedPatientIds.length === 0 ||
              !selectedEnrollCampaign
            }
            type="submit"
          >
            {loader ? (
              <LoaderDots />
            ) : selectedPatientIds.length > 0 && !verbalConsent ? (
              "Save"
            ) : selectedPatientsType === "enrolled" ? (
              "Update"
            ) : (
              "Enroll"
            )}
          </Button>
        </div>
      </form>

      {openTemplates && (
        <TemplateModal
          onClose={() => setOpenTemplates(false)}
          onUseTemplate={(data) => {
            dispatch(
              setEnrollFieldsAction({
                ...enrollFields,
                [selectedEnrollCampaign.campaign_id]: {
                  chronicConditions: data.conditions || "",
                  goals: data.goals || "",
                },
              }),
            );
          }}
        />
      )}

      <HistoryModal
        open={openHistory}
        patientHistory={selectedPatientHistory}
        carePlan={carePlan}
        onClose={() => setOpenHistory(false)}
      />
    </div>
  );
};
export default Enroll;
