import { ReactComponent as WarningIcon } from "../../../assets/icons/danger-circle.svg";
import { ReactComponent as NoteIcon } from "../../../assets/icons/note.svg";

const MeasureCard = ({ title, date, tag, warnings }) => {
  return (
    <div className="w-full p-3 rounded-lg border border-gray-300 bg-white text-sm font-medium">
      <div className="flex items-center justify-between space-x-3">
        <div className="flex items-center space-x-2">
          <NoteIcon width="20" height="20" className="flex-none" />
          <p>{title}</p>
        </div>
        <p className="text-tertiary">
          Due Date: <span className="text-primary">{date}</span>
        </p>
      </div>

      <div className="mt-3 w-fit py-1 px-3 rounded-full bg-gray-foreground text-tertiary font-semibold">
        {tag}
      </div>

      {warnings?.length > 0 && (
        <div className="mt-1 ml-2">
          {warnings.map((warning, index) => (
            <div key={index}>
              <div className="h-3 w-0 border my-1 ml-2.5" />
              <div className="flex items-center space-x-3">
                <WarningIcon width="20" height="20" className="flex-none" />
                <p className="text-tertiary">{warning}</p>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default MeasureCard;
