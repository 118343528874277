import { ReactComponent as PatientsIcon } from "../../../../assets/icons/patients-redesign.svg";
import { ReactComponent as DeleteIcon } from "../../../../assets/icons/trash-redesign.svg";
import ActionsButton from "../../../Basic/ActionsButton";
import AvatarStack from "../../../Basic/AvatarStack";
import ProfilePicture from "../../../Basic/ProfilePicture";

const TeamListItem = ({ team, onClick, onClickActions, onDelete }) => {
  return (
    <div className="p-4 rounded-xl space-y-4 bg-white" onClick={onClick}>
      <div className="flex items-center justify-between space-x-2">
        <p className="text-base font-semibold truncate">
          {team.team?.name || team.display_name}
        </p>
        <div className="flex items-center space-x-2">
          <div className="bg-gray-foreground rounded-full flex items-center space-x-1 px-2 py-1">
            <PatientsIcon
              width={16}
              height={16}
              stroke="#667085"
              className="flex-none"
            />
            <p className="text-xs leading-none text-tertiary font-semibold">
              {team.total_patients}
            </p>
          </div>
          <ActionsButton
            onClick={onClickActions}
            actions={[
              {
                icon: (
                  <DeleteIcon width="20" height="20" className="flex-none" />
                ),
                label: "Delete",
                onClick: onDelete,
              },
            ]}
          />
        </div>
      </div>
      <div className="grid grid-cols-2 divide-x border-gray-foreground">
        <div className="flex flex-col space-y-1 font-medium pr-3">
          <p className="text-xs text-tertiary">Clinical Lead</p>
          <div className="flex items-center space-x-2 truncate">
            <ProfilePicture
              firstName={team.first_name}
              lastName={team.last_name}
              src={team.profile_picture}
              flagPadding={false}
            />
            <p className="text-sm font-semibold truncate">
              {team.display_name}
            </p>
          </div>
        </div>
        <div className="flex flex-col space-y-1 font-medium truncate pl-3">
          <p className="text-xs text-tertiary">Members</p>
          <AvatarStack
            users={team.assistants}
            length={4}
            totalNumber={team.assistants ? team.assistants.length : 0}
          />
        </div>
      </div>
    </div>
  );
};

export default TeamListItem;
