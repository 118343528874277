import { z } from "zod";
import {
  baseConversationSchema,
  patientSchema,
  zeroOrOneSchema,
} from "../../helpers/commonValidationSchemas";

export const campaignSchema = z.object({
  break_message: z.string().nullable(),
  campaign: z.string(),
  campaign_id: z.number(),
  campaign_prompt: z.string(),
  campaign_type: z.string(),
  checkin_message: z.string(),
  close_followup: z.number(),
  customer_id: z.number(),
  default_frequency: z.string().nullable(),
  default_time_to_send: z.string().nullable(),
  description: z.string(),
  first_followup: z.number(),
  first_message: z.string(),
  last_message: z.string(),
  max_questions: z.number().min(0),
  min_questions: z.number().min(0),
  reminder_active: zeroOrOneSchema,
  reminder_message: z.string().nullable(),
  reminder_times: z.number().min(0),
  second_followup: z.number(),
  tag_id: z.number(),
  urgent_message: z.string(),
});

export const campaignsSchema = z.array(campaignSchema);

export const campaignConversationSchema = baseConversationSchema.extend({
  codes: z.string().nullable(),
  created_at: z.string(),
  doctor_id: z.number(),
  flag: z.string().nullable(),
  followups: z.number().min(0),
  is_admin: zeroOrOneSchema,
  is_automatic: zeroOrOneSchema,
  is_clinical: zeroOrOneSchema,
  is_priority: zeroOrOneSchema,
  is_urgent: zeroOrOneSchema,
  language: z.string().nullable(),
  last_response_from_patient_date: z.string().nullable(),
  last_sender: z.string().nullable(),
  patient_id: z.number(),
  patient_initiated: zeroOrOneSchema,
  prompt_settings: z.string(),
  questions_queue: z.array(z.string()).nullable(),
  type: z.string(),
  updated_at: z.string(),
});

const nextCheckinSchema = z.object({
  campaign_id: z.number(),
  campaign_patient_id: z.number(),
  conversation: campaignConversationSchema,
  description: z.string().nullable(),
  doctor_id: z.number().nullable(),
  ehr_information: z.string().nullable(),
  frequency: z.string(),
  interval: z.number(),
  next_checkin: z.string(),
  outreach_focus: z.string(),
  outreach_goals: z.string().nullable(),
  patient: patientSchema,
  patient_id: z.number(),
  status: z.string(),
  times_campaign_sent: z.number().min(0),
  verify_completion: zeroOrOneSchema,
});

export const nextCheckinsSchema = z.array(nextCheckinSchema);

export const smartEnrollDataSchema = z.object({
  conditionsToMonitor: z.string(),
  lifestyleHealthGoals: z.string(),
  objectiveMarkers: z.string(),
  providerSummary: z.string(),
  relevantPastMedicalHistory: z.string(),
});
