import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import { styled } from "@mui/material/styles";

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 8,
  borderRadius: 8,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.mode === "light" ? "#EAECF0" : "#EAECF0",
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 8,
    backgroundColor: theme.palette.mode === "light" ? "#2970FF" : "#2970FF",
  },
}));

const ProgressBar = ({ ...props }) => {
  return (
    <div className="w-full grow">
      <BorderLinearProgress {...props} />
    </div>
  );
};

export default ProgressBar;
