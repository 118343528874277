import Button from "../../Basic/Button";
import LoaderDots from "../../Basic/LoaderDots";

type SuggestedAnswerProps = {
  answer: string;
  onCancel: () => void;
  onUse: () => void;
  className?: string;
};

const SuggestedAnswer: React.FC<SuggestedAnswerProps> = ({
  answer,
  onCancel,
  onUse,
  className = "",
}) => {
  return (
    <div
      className={`bg-white rounded-lg border border-gray-foreground p-2 space-y-2 mb-2 ${className}`}
    >
      <div className="rounded-lg py-2.5 px-1 bg-gray-background">
        {answer ? (
          <div className="max-h-34 overflow-y-auto scrollbar px-2 text-xs leading-normal text-tertiary font-medium">
            <p>{answer}</p>
          </div>
        ) : (
          <div className="w-fit mx-auto py-2">
            <LoaderDots />
          </div>
        )}
      </div>
      <div className="flex items-center justify-end space-x-3 text-sm font-semibold">
        <Button
          aria-label="Close response"
          variant="gray"
          size="unset"
          className="h-8 px-4"
          onClick={onCancel}
        >
          Cancel
        </Button>
        <Button
          aria-label="Use response"
          size="unset"
          className="h-8 px-4"
          onClick={onUse}
        >
          Use AI
        </Button>
      </div>
    </div>
  );
};

export default SuggestedAnswer;
