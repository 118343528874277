import dayjs from "dayjs";

export enum Pages {
  MESSAGING = "/messaging",
  PATIENTS = "/patients",
  SCRIBE = "/scribe",
  PATIENT_PROFILE = "/patients/:id",
  ENROLL = "/enroll",
  BILLING_METRICS = "/billing-metrics",
  CAMPAIGNS = "/campaigns",
  SETTINGS_PROFILE = "/settings/profile",
  SETTINGS_ORGANIZATION = "/settings/organization",
  SETTINGS_FACILITIES = "/settings/facilities",
  SETTINGS_NOTES = "/settings/notes",
  SETTINGS_CARE_MANAGEMENT = "/settings/care-management",
  LOGIN = "/login",
  SIGNUP = "/sign-up/:token",
  SIGNUP_V2 = "/sign-up-v2",
  FORGOT_PASS = "/forgot-password",
  RESET_PASS = "/reset-password/:token",
  CHECK_EMAIL = "/check-email",
  CREATE_PASS = "/create-password",
  RESET_PASS_CONFIRM = "/reset-password-confirmation",
  METRICS = "/metrics",
  ONBOARDING = "/onboarding",
}

export const rowsPerPage = 50;

export const TabsOrientation = {
  HORIZONTAL: "horizontal",
  VERTICAL: "vertical",
};

export const OrganizationTabs = {
  USERS: "Users",
  TEAMS: "Teams",
};

export const NotesTabs = {
  GENERAL: "General",
  TEMPLATES: "Templates",
  // FORMS: "Forms",
  // DOT_PHRASES: "Dot Phrases",
};

export const patientPanelTabs = {
  CALL: "Call",
  SCRIBE: "Scribe",
  MESSAGE: "Message",
};

export const patientProfileTabs = {
  NOTES: "Notes",
  ENROLLMENTS: "Care Coordination",
  INFORMATION: "Details",
  HISTORY: "History",
};

export const CampaignTabs = {
  DETAILS: "Details",
  CONTROLS: "Controls",
};

export const NoteTabs = {
  NOTE: "Note",
  TRANSCRIPT: "Transcript",
};

export const settingsTabs = [
  { link: Pages.SETTINGS_PROFILE, text: "Profile" },
  { link: Pages.SETTINGS_ORGANIZATION, text: "Clinic" },
  { link: Pages.SETTINGS_FACILITIES, text: "Facilities" },
  { link: Pages.SETTINGS_CARE_MANAGEMENT, text: "Care Management" },
  { link: Pages.SETTINGS_NOTES, text: "Notes" },
];

export const availableTags = [
  { name: "Patient's Name", value: "{{NAME}}" },
  { name: "Provider's Name", value: "{{DISPLAYNAME}}" },
  { name: "Organization", value: "{{ORGANIZATION}}" },
];

export const InboxTabsInit = [
  { name: "active", conversations: 0 },
  { name: "review", conversations: 0 },
  { name: "complete", conversations: 0 },
];

export const campaignTypes = [
  { value: "Outreach", text: "CCM" },
  { value: "Outreach_PCM", text: "PCM" },
  { value: "Outreach_BHI", text: "BHI" },
];

export const passwordValidationRegEx =
  /(?=[A-Za-z0-9@#$%^&+!=]+$)^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[@#$%^&+!=])(?=.{8,}).*$/;

export const twoConditionsRegex = /\S+\s*\n2\.\s*\S+/m;

export const genderOptions = [
  { value: "", text: "-" },
  { value: "male", text: "Male" },
  { value: "female", text: "Female" },
  { value: "transgender male", text: "Transgender Male" },
  { value: "transgender female", text: "Transgender Female" },
  { value: "non-binary", text: "Non-binary" },
  { value: "notListed", text: "Not Listed: Please specify" },
];

export const sexOptions = [
  { value: "", text: "-" },
  { value: "male", text: "Male" },
  { value: "female", text: "Female" },
  { value: "notListed", text: "Not Listed: Please specify" },
];

export const pronounsOptions = [
  { value: "", text: "-" },
  { value: "he/him/his", text: "He/Him/His" },
  { value: "she/her/hers", text: "She/Her/Hers" },
  { value: "they/them/theirs", text: "They/Them/Theirs" },
];

export const genders = [
  { value: "male", text: "Male" },
  { value: "female", text: "Female" },
  { value: "other", text: "Other" },
];

export const ethnicityOptions = [
  { value: "", text: "-" },
  { value: "HispanicOrLatino", text: "Hispanic or latino" },
  { value: "NotHispanicOrLatino", text: "Not hispanic or latino" },
  { value: "OtherEthnicity", text: "Other ethnicity" },
];

export const raceOptions = [
  { value: "", text: "-" },
  { value: "Asian", text: "Asian" },
  { value: "AmericanIndian", text: "American indian" },
  {
    value: "AmericanIndianOrAlaskanNative",
    text: "American indian or alaskan native",
  },
  {
    value: "BlackOrAfricanAmerican",
    text: "Black or african american",
  },
  {
    value: "Black",
    text: "Black",
  },
  {
    value: "NativeHawaiianOrOtherPacificIslander",
    text: "Native hawaiian or other pacificIslander",
  },
  { value: "White", text: "White" },
  { value: "OtherRace", text: "Other race" },
];

export const sexualOrientationOptions = [
  { value: "", text: "-" },
  { value: "Straight or heterosexual", text: "Straight or heterosexual" },
  { value: "Gay", text: "Gay" },
  { value: "Bisexual", text: "Bisexual" },
  { value: "Asexual", text: "Asexual" },
  { value: "Prefer not to say", text: "Prefer not to say" },
];

export const languages = [
  { value: "", text: "-" },
  { value: "English", text: "English" },
  { value: "French", text: "French" },
  { value: "Spanish", text: "Spanish" },
  { value: "Chinese", text: "Chinese" },
  { value: "Other", text: "Other" },
];

export const InsuranceOptions = [
  { value: "medicare", text: "Medicare" },
  { value: "medicaid", text: "Medicaid" },
  { value: "private", text: "Private" },
  { value: "other", text: "Other" },
  { value: "none", text: "None" },
];

export const reasonsToAddTime = [
  { value: "Patient Chart Review", text: "Patient Chart Review" },
  { value: "In-person communication", text: "In-person communication" },
  {
    value: "Communication between care team",
    text: "Communication between care team",
  },
  { value: "Other", text: "Other" },
];

export const careManagementTabs = [
  { link: Pages.ENROLL, text: "Enroll" },
  { link: Pages.BILLING_METRICS, text: "Time Tracking" },
];

// launch campaign
export const frequency = [
  { text: "Once", value: "once" },
  { text: "Daily", value: "daily" },
  { text: "Every 3 days", value: "every_3_days" },
  { text: "Weekly", value: "weekly" },
  { text: "Biweekly", value: "bi_weekly" },
  { text: "Monthly", value: "monthly" },
  { text: "Every 2 months", value: "every_2_months" },
  { text: "Every 3 months", value: "every_3_months" },
  { text: "Every 6 months", value: "every_6_months" },
  { text: "Yearly", value: "yearly" },
];

export const frequencyCCM = [
  { text: "Weekly", value: "weekly" },
  { text: "Biweekly", value: "bi_weekly" },
  { text: "Monthly", value: "monthly" },
];

export const chronicPostDischargePlaceholder = `1. Heart failure with reduced ejection fraction - Week 3 post discharge
2. Aspiration pneumonia (developed in the same admission as above)`;
export const chronicCcmPlaceholder = `1. insulin-dependent Type 2 diabetes
2. severe GERD`;
export const goalsPostDischargePlaceholder =
  "Assess adherence to post-discharge medications, Exercise 15 minutes a day, less than 2g/day salt diet, avoid alcohol and stay upright when eating";
export const goalsCcmPlaceholder =
  "Exercise 20 minutes a day, measure blood sugar daily, 4 grams sodium diet, reduce alcohol consumption to below 5 drinks a week.";
export const medicalPostDischargePlaceholder =
  "Stenting for double vessel coronary artery disease was performed 5 weeks ago, 40 pack year history smoking";
export const medicalCcmPlaceholder =
  "10 years esophageal ulcers, diabetic foot ulcers, obesity with bmi 35";

export const shortcutsItems = [
  {
    label: "Now",
    getValue: () => {
      return dayjs();
    },
  },
  {
    label: "Tomorrow",
    getValue: () => {
      return dayjs().add(1, "day");
    },
  },
  // {
  //   label: "Next week",
  //   getValue: () => {
  //     return dayjs().add(1, "week");
  //   },
  // },
  {
    label: "Next month",
    getValue: () => {
      return dayjs(new Date()).add(1, "month");
    },
  },
];

// settings
export const occupations = [
  { text: "Physician", value: "Physician" },
  { text: "Registered Nurse", value: "Registered Nurse" },
  { text: "Nurse Practitioner", value: "Nurse Practitioner" },
  { text: "Medical Assistant", value: "Medical Assistant" },
  { text: "Occupational Therapist", value: "Occupational Therapist" },
  { text: "Physical Therapist", value: "Physical Therapist" },
  { text: "Physician Assistant", value: "Physician Assistant" },
  { text: "Social Worker", value: "Social Worker" },
  { text: "Other", value: "other" },
];

//conversations
export const convPerPage = 30;

export const inboxes = {
  REVIEW: "review",
  ACTIVE: "active",
  COMPLETE: "complete",
};

export const statusReviewOptions = [];

export const typeOptions = [{ value: "urgent", text: "Urgent Only" }];

export const categoryOptions = [
  { value: "medication-refill", text: "Medication Refill" },
  { value: "scheduling", text: "Scheduling" },
  { value: "insurance-billing", text: "Insurance Billing" },
  { value: "sick-note", text: "Sick Note" },
  { value: "test-result-inquiry", text: "Test Result Inquiry" },
  { value: "referral-requests", text: "Referral Requests" },
  { value: "medical-question", text: "Medical Question" },
  { value: "other", text: "Other" },
];

export const statusActiveOptions = [
  { value: "inProgress", text: "In Progress" },
  { value: "noResponse", text: "No Response" },
];

export const statusCompleteOptions = [
  { value: "all", text: "Finalized" },
  { value: "paused", text: "Paused" },
  { value: "noResponse", text: "No Response" },
];

export const timeOptions = [
  { value: "all", text: "All" },
  { value: 1, text: "Today" },
  { value: 7, text: "Last 7 days" },
  { value: 30, text: "Last 30 days" },
];

export const medicalSpecialtiesOptions = [
  { text: "Addiction medicine", value: "addictionMedicine" },
  {
    text: "Adolescent and young adult medicine",
    value: "adolescentAndYoungAdultMedicine",
  },
  { text: "Allergy", value: "allergy" },
  { text: "Anesthesiology", value: "anesthesiology" },
  { text: "Behavioral health", value: "behavioralHealth" },
  { text: "Cardiology", value: "cardiology" },
  { text: "Chiropractic", value: "chiropractic" },
  { text: "Dentistry", value: "dentistry" },
  { text: "Dermatology", value: "dermatology" },
  { text: "Diabetology", value: "diabetology" },
  { text: "Diet", value: "diet" },
  { text: "Ear, nose and throat medicine", value: "earNoseAndThroatMedicine" },
  { text: "Emergency medicine", value: "emergencyMedicine" },
  { text: "Endocrinology", value: "endocrinology" },
  { text: "Family medicine", value: "familyMedicine" },
  { text: "Forensic medicine", value: "forensicMedicine" },
  { text: "Gastroenterology", value: "gastroenterology" },
  { text: "General medicine", value: "generalMedicine" },
  { text: "Genetics", value: "genetics" },
  { text: "Geriatrics", value: "geriatrics" },
  { text: "Hematology", value: "hematology" },
  { text: "Hepatology", value: "hepatology" },
  { text: "Hospital medicine", value: "hospitalMedicine" },
  { text: "Immunology", value: "immunology" },
  { text: "Infectious diseases", value: "infectiousDiseases" },
  { text: "Internal medicine", value: "internalMedicine" },
  { text: "Lactation consultant", value: "lactationConsultant" },
  { text: "Lifestyle medicine", value: "lifestyleMedicine" },
  { text: "Mental health", value: "mentalHealth" },
  { text: "Midwifery", value: "midwifery" },
  { text: "Nephrology", value: "nephrology" },
  { text: "Neurology", value: "neurology" },
  { text: "Neurosurgery", value: "neurosurgery" },
  { text: "Nursing", value: "nursing" },
  { text: "Nutrition", value: "nutrition" },
  { text: "Obstetrics/Gynecology", value: "obstetricsGynecology" },
  { text: "Occupational medicine", value: "occupationalMedicine" },
  { text: "Oncology", value: "oncology" },
  { text: "Ophthalmology", value: "ophthalmology" },
  { text: "Orthopedics", value: "orthopedics" },
  { text: "Osteopathy", value: "osteopathy" },
  { text: "Pain medicine", value: "painMedicine" },
  { text: "Pediatrics", value: "pediatrics" },
  {
    text: "Physical rehabilitation medicine",
    value: "physicalRehabilitationMedicine",
  },
  {
    text: "Physiotherapy / Physical Therapy (PT)",
    value: "physiotherapyPhysicalTherapyPt",
  },
  { text: "Plastic Surgery", value: "plasticSurgery" },
  { text: "Podiatry", value: "podiatry" },
  { text: "Preventive medicine", value: "preventiveMedicine" },
  { text: "Psychiatry", value: "psychiatry" },
  { text: "Psychology", value: "psychology" },
  { text: "Psychotherapy", value: "psychotherapy" },
  { text: "Public health", value: "publicHealth" },
  { text: "Pulmonology", value: "pulmonology" },
  { text: "Radiology", value: "radiology" },
  { text: "Rheumatology", value: "rheumatology" },
  { text: "Sexology", value: "sexology" },
  { text: "Sport medicine", value: "sportMedicine" },
  { text: "Substance use disorder", value: "substanceUseDisorder" },
  { text: "Surgery", value: "surgery" },
  { text: "Toxicology", value: "toxicology" },
  { text: "Urology", value: "urology" },
  { text: "Vascular Medicine", value: "vascularMedicine" },
  { text: "Veterinary Medicine", value: "veterinaryMedicine" },
  { text: "Wound Medicine", value: "woundMedicine" },
  { text: "Other", value: "other" },
];

export const ehrOptions = [
  { text: "Allscripts", value: "allscripts" },
  { text: "Athena", value: "athena" },
  { text: "Cerner", value: "cerner" },
  { text: "Charm Health", value: "charmHealth" },
  { text: "ChartJoy", value: "chartJoy" },
  { text: "Coviu", value: "coviu" },
  { text: "CPSI", value: "cpsi" },
  { text: "CureMD", value: "cureMd" },
  { text: "DrChrono", value: "drChrono" },
  { text: "eClinicalWorks", value: "eClinicalWorks" },
  { text: "Elation", value: "elation" },
  { text: "eMDs", value: "eMds" },
  { text: "Epic", value: "epic" },
  { text: "Full Script", value: "fullScript" },
  { text: "GE Healthcare", value: "geHealthcare" },
  { text: "Greenway", value: "greenway" },
  { text: "Kareo", value: "kareo" },
  { text: "Medhost", value: "medhost" },
  { text: "Meditech", value: "meditech" },
  { text: "ModMed", value: "modMed" },
  { text: "NextGen", value: "nextGen" },
  { text: "Opus", value: "opus" },
  { text: "Practice Fusion", value: "practiceFusion" },
  { text: "SimplePractice", value: "simplePractice" },
  { text: "Touchworks", value: "touchworks" },
  { text: "None", value: "none" },
  { text: "Other", value: "other" },
];

export const flags = [
  { value: "grey", color: "#848488" },
  { value: "red", color: "#E24636" },
  { value: "orange", color: "#FD9423" },
  { value: "purple", color: "#8F5AFF" },
  { value: "blue", color: "#4CBFDE" },
  { value: "yellow", color: "#F4C01D" },
  { value: "green", color: "#12B76A" },
];

export const commonCampaigns = [
  { value: "patientInitiated", text: "Patient Initiated" },
  // { value: "staffInitiated", text: "Staff Initiated" },
];

export const filtersClear = {
  campaigns: [],
  status: {
    [inboxes.REVIEW]: [],
    [inboxes.ACTIVE]: [],
    [inboxes.COMPLETE]: [],
  },
  time: "all",
  category: [],
  type: [],
  other: {
    unread: false,
    staffEngaging: false,
    flag: [],
  },
};

export const getOptionValues = (options) => {
  return options.map((item) => item.value);
};

export const getFiltersInit = (campaignOptions) => {
  return {
    campaigns: getOptionValues(campaignOptions),
    status: {
      [inboxes.REVIEW]: getOptionValues(statusReviewOptions),
      [inboxes.ACTIVE]: getOptionValues(statusActiveOptions),
      [inboxes.COMPLETE]: getOptionValues(statusCompleteOptions),
    },
    time: "all",
    category: getOptionValues(categoryOptions),
    type: [],
    other: {
      unread: false,
      staffEngaging: false,
      flag: [],
    },
  };
};

export const twilioDeviceStatuses = {
  OFFLINE: "Offline",
  READY: "Requesting",
  RINGING: "Ringing",
  IN_PROGRESS: "In progress",
  INCOMING: "Incoming",
};

export const sectionDetailLevels = {
  HIGH: "High",
  NORMAL: "Normal",
};

export const sectionStyles = {
  AUTO: "Auto",
  BULLET: "Bullet",
  PARAGRAPH: "Paragraph",
};

export const frequentlyAskedQuestions = [
  {
    question: "What is Quadrant Health's AI medical scribe?",
    answer: `Quadrant Health's AI medical scribe is an advanced tool that uses proprietary
      large medical language models to generate accurate medical dictations, transcriptions,
      and chart notes. It can transcribe your voice and conversations with patients into detailed
      medical notes, which can be used to generate various outputs like SOAP notes, letters, and
      forms based on templates.`,
  },
  {
    question: "How does the scribe work?",
    answer: `Simply speak into the app, and it will transcribe your voice into accurate
      and detailed medical notes. The AI scribe can evaluate the entire transcription context
      to effectively eliminate errors.`,
  },
  {
    question: "How accurate are the transcriptions?",
    answer: `Our transcription model is over 99% accurate. Our proprietary large
      language model (LLM) evaluates the entire transcription context to effectively eliminate
      errors and suppress background noise. `,
  },
  {
    question: "Does this work for all medical specialties?",
    answer: `Yes, our AI scribe is trained on encounters from over 35 specialties.
      We offer over 30 pre-made templates, and you can customize sections to fit your specific needs.`,
  },
  {
    question: "Can I use dictation, dot phrases, and macros?",
    answer: `Absolutely! Our AI scribe supports dictation, dot phrases, and macros.
      You can even use voice triggers for dot phrases and macros, streamlining your workflow.`,
  },
  {
    question:
      "How does Quadrant AI handle pre-populated normals and form filling?",
    answer: `You can add forms and pre-completed notes to our system. The AI detects any
      discrepancies between the form/note and the encounter and updates the note accordingly.
      If nothing is mentioned about a pre-populated section, it remains unchanged. For forms,
      you can specify where the AI is allowed to fill out and what choices it has. We will not
      modify anything you don't want us to. `,
  },
  {
    question: "Is my data secure and private?",
    answer: `At Quadrant Health, we take data security and privacy extremely seriously.
      All data is end-to-end encrypted on the user's device. We have implemented strong security
      measures to ensure the confidentiality and integrity of your data. We are fully HIPAA, SOC2,
      and GDPR compliant, and we never share data with third parties. We own the entire data pipeline,
      ensuring every note created is securely stored. You have the ability to clear all data at any time.`,
  },
  {
    question: "Can it integrate with my existing EMR system?",
    answer: `For our free and pro versions, we don't offer direct EMR integration, but our
      Chrome extension can be used on top of your EMR for easy copy-and-paste functionality. For
      enterprise clients, please contact us to discuss full integration options with your EMR.`,
  },
  {
    question: "What if I encounter technical issues or need support?",
    answer: `If you experience any technical issues or require assistance, please reach out to
      us by emailing support@quadrant.health. We'll promptly address your concerns and provide the
      necessary guidance.`,
  },
];

export const countryCodes = ["us", "ca", "gb", "ar", "br", "in"];
