import { type PayloadAction, createSlice } from "@reduxjs/toolkit";
import { LOGOUT } from "./types";

const initialState = {
  currentTime: new Date(),
};

const timerSlice = createSlice({
  name: "timer",
  initialState: initialState,
  reducers: {
    setCurrentTime: (state, action: PayloadAction<Date>) => {
      state.currentTime = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(LOGOUT, () => {
      return { ...initialState };
    });
  },
});

export const { setCurrentTime } = timerSlice.actions;

export default timerSlice.reducer;
