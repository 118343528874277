import OrganizationUsers from "../../components/Settings/Organization/OrganizationUsers";
import SettingsContainer from "../../components/Settings/SettingsContainer";

const OrganizationSettings = () => {
  return (
    <SettingsContainer>
      <OrganizationUsers />
    </SettingsContainer>
  );
};

export default OrganizationSettings;
