import dayjs, { type Dayjs } from "dayjs";
import { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { ReactComponent as RemoveIcon } from "../../assets/icons/close.svg";
import {
  convPerPage,
  countryCodes,
  ethnicityOptions,
  genderOptions,
  languages,
  pronounsOptions,
  raceOptions,
  rowsPerPage,
  sexOptions,
  sexualOrientationOptions,
} from "../../helpers/constants";
import { useAppDispatch, useAppSelector } from "../../store";
import { setSelectedEnrollPatientsAction } from "../../store/campaign/actions";
import {
  getConversation,
  searchConversationsWithFilters,
} from "../../store/conversation/thunks";
import { setPatientExistsErrorAction } from "../../store/patient/actions";
import type { Patient } from "../../store/patient/interfaces";
import { createPatient, updatePatient } from "../../store/patient/thunks";
import AutocompleteVirtualized from "./Autocomplete/AutocompleteVirtualized";
import Button from "./Button";
import DateTimeShortcutsInput from "./DateTimeShortcutsInput";
import Input from "./Input";
import CountryPhoneInput from "./PhoneInput/CountryPhoneInput";
import ProfilePicture from "./ProfilePicture";
import Select from "./Select";
import TabButtonRound from "./TabButtonRound";

const PatientTabs = {
  MAIN: "Main",
  DEMOGRAPHICS: "Demographics",
  INSURANCE: "Insurance",
  CONTACT: "Contact",
  CARE_LOCATIONS: "Care Locations",
};

const subtitleClasses = "text-sm font-medium uppercase tracking-wider -mb-2";

type AddEditPatientForm = {
  onClose: () => void;
  onUpdate: () => void;
  patientInfo: Patient;
  className?: string;
};

type FormValues = Patient & {
  primary_policy_holder_name?: string;
  primary_holder_dob?: string | Dayjs;
  primary_holder_ssn?: string;
  primary_relationship_to_holder?: string;
  primary_group_number?: string;
  primary_policy_number?: string;
  primary_insurance_name?: string;
  secondary_policy_holder_name?: string;
  secondary_holder_dob?: string | Dayjs;
  secondary_holder_ssn?: string;
  secondary_relationship_to_holder?: string;
  secondary_group_number?: string;
  secondary_policy_number?: string;
  secondary_insurance_name?: string;
  emergency_contact_first_name?: string;
  emergency_contact_last_name?: string;
  emergency_contact_phone_number?: string;
  emergency_contact_relationship?: string;
};

const AddEditPatientForm: React.FC<AddEditPatientForm> = ({
  onClose,
  onUpdate = () => {},
  patientInfo,
  className = "",
}) => {
  const { MAIN, DEMOGRAPHICS, INSURANCE, CONTACT, CARE_LOCATIONS } =
    PatientTabs;
  const {
    patientExistsError,
    searchValue,
    currPage,
    selectedDoctorPatients,
    selectedPatientCampaigns,
    selectedPatientStatuses,
  } = useAppSelector((state) => state.patient);
  const {
    currentConversation,
    conversationSearchInput,
    activeInbox,
    activeFilters,
    convCurrPage,
    selectedDoctorInbox,
  } = useAppSelector((state) => state.conversation);
  const { selectedEnrollPatients } = useAppSelector((state) => state.campaign);
  const { users } = useAppSelector((state) => state.user);
  const { facilities } = useAppSelector((state) => state.facility);
  const [activeTab, setActiveTab] = useState(MAIN);
  const [showInputSex, setShowInputSex] = useState(false);
  const [sexNotListed, setSexNotListed] = useState("");
  const [sexError, setSexError] = useState(null);
  const [showInputGender, setShowInputGender] = useState(false);
  const [genderNotListed, setGenderNotListed] = useState("");
  const [facilitySelectOptions, setFacilitySelectOptions] = useState([]);
  const [selectedProvider, setSelectedProvider] = useState(null);
  const [providerError, setProviderError] = useState(null);
  const [initialMount, setInitialMount] = useState(true);

  const dispatch = useAppDispatch();
  const edit = !!patientInfo;

  const {
    register,
    handleSubmit,
    control,
    watch,
    setValue,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm<FormValues>({
    defaultValues: {
      // main
      first_name: edit ? patientInfo.first_name : "",
      middle_name: edit ? patientInfo.middle_name : "",
      last_name: edit ? patientInfo.last_name : "",
      birthdate: edit
        ? patientInfo.birthdate
          ? dayjs(patientInfo.birthdate)
          : null
        : null,
      sex: edit ? patientInfo.sex : "",
      phone_number: edit ? patientInfo.phone_number : "",
      primary_language: edit ? patientInfo.primary_language : "",

      // demographics
      preferred_name: edit ? patientInfo.preferred_name : "",
      ethnicity: edit ? patientInfo.ethnicity : "",
      race: edit ? patientInfo.race : "",
      gender: edit ? patientInfo.gender : "",
      pronouns: edit ? patientInfo.pronouns : "",
      sexual_orientation: edit ? patientInfo.sexual_orientation : "",
      social_security_number: edit ? patientInfo.social_security_number : "",
      address_line_1: edit ? patientInfo.address_line_1 : "",
      address_line_2: edit ? patientInfo.address_line_2 : "",
      country: edit ? patientInfo.country : "",
      state: edit ? patientInfo.state : "",
      city: edit ? patientInfo.city : "",
      zipcode: edit ? patientInfo.zipcode : "",

      // insurance
      primary_policy_holder_name: edit
        ? patientInfo.insurance?.length > 0
          ? patientInfo.insurance[0].policy_holder_name
          : ""
        : "",
      primary_holder_dob: edit
        ? patientInfo.insurance?.length > 0
          ? patientInfo.insurance[0].holder_dob
            ? dayjs(patientInfo.insurance[0].holder_dob)
            : null
          : null
        : null,
      primary_holder_ssn: edit
        ? patientInfo.insurance?.length > 0
          ? patientInfo.insurance[0].holder_ssn
          : ""
        : "",
      primary_relationship_to_holder: edit
        ? patientInfo.insurance?.length > 0
          ? patientInfo.insurance[0].relationship_to_holder
          : ""
        : "",
      primary_group_number: edit
        ? patientInfo.insurance?.length > 0
          ? patientInfo.insurance[0].group_number
          : ""
        : "",
      primary_policy_number: edit
        ? patientInfo.insurance?.length > 0
          ? patientInfo.insurance[0].policy_number
          : ""
        : "",
      primary_insurance_name: edit
        ? patientInfo.insurance?.length > 0
          ? patientInfo.insurance[0].insurance_name
          : ""
        : "",
      secondary_policy_holder_name: edit
        ? patientInfo.insurance?.length > 1
          ? patientInfo.insurance[1].policy_holder_name
          : ""
        : "",
      secondary_holder_dob: edit
        ? patientInfo.insurance?.length > 1
          ? patientInfo.insurance[1].holder_dob
            ? dayjs(patientInfo.insurance[1].holder_dob)
            : null
          : null
        : null,
      secondary_holder_ssn: edit
        ? patientInfo.insurance?.length > 1
          ? patientInfo.insurance[1].holder_ssn
          : ""
        : "",
      secondary_relationship_to_holder: edit
        ? patientInfo.insurance?.length > 1
          ? patientInfo.insurance[1].relationship_to_holder
          : ""
        : "",
      secondary_group_number: edit
        ? patientInfo.insurance?.length > 1
          ? patientInfo.insurance[1].group_number
          : ""
        : "",
      secondary_policy_number: edit
        ? patientInfo.insurance?.length > 1
          ? patientInfo.insurance[1].policy_number
          : ""
        : "",
      secondary_insurance_name: edit
        ? patientInfo.insurance?.length > 1
          ? patientInfo.insurance[1].insurance_name
          : ""
        : "",

      // contact
      email: edit ? patientInfo.email : "",
      emergency_contact_first_name: edit
        ? patientInfo.emergency_contact?.length > 0
          ? patientInfo.emergency_contact[0].emergency_first_name
          : ""
        : "",
      emergency_contact_last_name: edit
        ? patientInfo.emergency_contact?.length > 0
          ? patientInfo.emergency_contact[0].emergency_last_name
          : ""
        : "",
      emergency_contact_phone_number: edit
        ? patientInfo.emergency_contact?.length > 0
          ? patientInfo.emergency_contact[0].emergency_phone_number
          : ""
        : "",
      emergency_contact_relationship: edit
        ? patientInfo.emergency_contact?.length > 0
          ? patientInfo.emergency_contact[0].emergency_relationship
          : ""
        : "",

      // care locations
      customer_id: edit
        ? patientInfo.customer_id
          ? Number(patientInfo.customer_id)
          : ""
        : "",
      chart_number: edit ? patientInfo.chart_number : "",
      pharmacy_name: edit ? patientInfo.pharmacy_name : "",
      pharmacy_phone: edit ? patientInfo.pharmacy_phone : "",
      pharmacy_address: edit ? patientInfo.pharmacy_address : "",
    },
  });

  const watchSex = watch("sex");
  const watchGender = watch("gender");

  useEffect(() => {
    dispatch(setPatientExistsErrorAction(false));
  }, [dispatch]);

  useEffect(() => {
    if (patientExistsError) {
      setError("phone_number", {
        message: "Phone number already added",
      });
    } else {
      clearErrors("phone_number");
    }
  }, [patientExistsError, setError, clearErrors]);

  const setDefaultValues = useCallback(() => {
    if (!patientInfo) {
      if (initialMount && users?.length > 0 && !selectedProvider) {
        setSelectedProvider(users[0]);
      }

      setInitialMount(false);

      return;
    }

    // main
    setValue("first_name", patientInfo.first_name);
    setValue("middle_name", patientInfo.middle_name);
    setValue("last_name", patientInfo.last_name);
    setValue("birthdate", dayjs(patientInfo.birthdate));
    setValue("phone_number", patientInfo.phone_number);
    setValue("primary_language", patientInfo.primary_language);

    setSelectedProvider(
      users.find((user) => user.doctor_id === patientInfo.doctor_id),
    );

    // demographics
    setValue("preferred_name", patientInfo.preferred_name);
    setValue("ethnicity", patientInfo.ethnicity);
    setValue("race", patientInfo.race);
    setValue("pronouns", patientInfo.pronouns);
    setValue("sexual_orientation", patientInfo.sexual_orientation);
    setValue("social_security_number", patientInfo.social_security_number);
    setValue("address_line_1", patientInfo.address_line_1);
    setValue("address_line_2", patientInfo.address_line_2);
    setValue("country", patientInfo.country);
    setValue("state", patientInfo.state);
    setValue("city", patientInfo.city);
    setValue("zipcode", patientInfo.zipcode);

    // insurance
    if (patientInfo.insurance?.length > 0) {
      setValue(
        "primary_policy_holder_name",
        patientInfo.insurance[0].policy_holder_name,
      );
      setValue(
        "primary_holder_dob",
        patientInfo.insurance[0].holder_dob
          ? dayjs(patientInfo.insurance[0].holder_dob)
          : null,
      );
      setValue("primary_holder_ssn", patientInfo.insurance[0].holder_ssn);
      setValue(
        "primary_relationship_to_holder",
        patientInfo.insurance[0].relationship_to_holder,
      );
      setValue("primary_group_number", patientInfo.insurance[0].group_number);
      setValue("primary_policy_number", patientInfo.insurance[0].policy_number);
      setValue(
        "primary_insurance_name",
        patientInfo.insurance[0].insurance_name,
      );
    }
    if (patientInfo.insurance?.length > 1) {
      setValue(
        "secondary_policy_holder_name",
        patientInfo.insurance[1].policy_holder_name,
      );
      setValue(
        "secondary_holder_dob",
        patientInfo.insurance[1].holder_dob
          ? dayjs(patientInfo.insurance[1].holder_dob)
          : null,
      );
      setValue("secondary_holder_ssn", patientInfo.insurance[1].holder_ssn);
      setValue(
        "secondary_relationship_to_holder",
        patientInfo.insurance[1].relationship_to_holder,
      );
      setValue("secondary_group_number", patientInfo.insurance[1].group_number);
      setValue(
        "secondary_policy_number",
        patientInfo.insurance[1].policy_number,
      );
      setValue(
        "secondary_insurance_name",
        patientInfo.insurance[1].insurance_name,
      );
    }

    // contact
    setValue("email", patientInfo.email);
    if (patientInfo.emergency_contact?.length > 0) {
      setValue(
        "emergency_contact_first_name",
        patientInfo.emergency_contact[0].emergency_first_name,
      );
      setValue(
        "emergency_contact_last_name",
        patientInfo.emergency_contact[0].emergency_last_name,
      );
      setValue(
        "emergency_contact_phone_number",
        patientInfo.emergency_contact[0].emergency_phone_number,
      );
      setValue(
        "emergency_contact_relationship",
        patientInfo.emergency_contact[0].emergency_relationship,
      );
    }

    // care locations
    setValue(
      "customer_id",
      patientInfo.customer_id ? Number(patientInfo.customer_id) : "",
    );
    setValue("chart_number", patientInfo.chart_number);
    setValue("pharmacy_name", patientInfo.pharmacy_name);
    setValue("pharmacy_phone", patientInfo.pharmacy_phone);
    setValue("pharmacy_address", patientInfo.pharmacy_address);

    if (patientInfo.sex) {
      if (!sexOptions.some((option) => option.value === patientInfo.sex)) {
        setShowInputSex(true);
        setValue("sex", "notListed");
      } else {
        setValue("sex", patientInfo.sex);
      }
    }

    if (patientInfo.gender) {
      if (
        !genderOptions.some((option) => option.value === patientInfo.gender)
      ) {
        setShowInputGender(true);
        setValue("gender", "notListed");
      } else {
        setValue("gender", patientInfo.gender);
      }
    }
  }, [patientInfo, initialMount, selectedProvider, setValue, users]);

  useEffect(() => {
    setDefaultValues();
  }, [setDefaultValues]);

  useEffect(() => {
    if (watchSex === "notListed") {
      setShowInputSex(true);
    } else {
      setSexNotListed("");
      setShowInputSex(false);
    }

    if (!edit) {
      if (watchSex === "male") {
        setValue("pronouns", "he/him/his");
      }
      if (watchSex === "female") {
        setValue("pronouns", "she/her/hers");
      }
      if (watchSex === "notListed" || watchSex === "") {
        setValue("pronouns", "");
      }
    }
  }, [watchSex, setValue, edit]);

  useEffect(() => {
    if (watchGender === "notListed") {
      setShowInputGender(true);
    } else {
      setGenderNotListed("");
      setShowInputGender(false);
    }
  }, [watchGender]);

  const checkInputSexOther = useCallback(() => {
    if (watchSex === "notListed" && !sexNotListed) {
      setSexError({ message: "Biological Sex is required" });
    } else {
      setSexError(null);
    }
  }, [sexNotListed, watchSex]);

  useEffect(() => {
    checkInputSexOther();
  }, [checkInputSexOther]);

  useEffect(() => {
    if (patientInfo) {
      setValue(
        "customer_id",
        patientInfo.customer_id ? Number(patientInfo.customer_id) : "",
      );
    }
  }, [patientInfo, setValue]);

  useEffect(() => {
    if (facilities?.length > 0) {
      setFacilitySelectOptions([
        { value: "", text: "-" },
        ...facilities.map((facility) => ({
          value: Number(facility.customer_id),
          text: facility.name,
        })),
      ]);
    }
  }, [facilities]);

  useEffect(() => {
    if (showInputSex && patientInfo) {
      setSexNotListed(patientInfo.sex);
    } else {
      setSexNotListed("");
    }
  }, [showInputSex, patientInfo]);

  useEffect(() => {
    if (showInputGender && patientInfo) {
      setGenderNotListed(patientInfo.gender);
    } else {
      setGenderNotListed("");
    }
  }, [showInputGender, patientInfo]);

  const handleSelectProvider = (provider) => {
    setSelectedProvider(provider);
    setProviderError(null);
  };

  const removeSelectedEnrollPatients = (patientId) => {
    const updatedEnrollState = { ...selectedEnrollPatients };
    for (const key in updatedEnrollState) {
      if (Object.hasOwn(updatedEnrollState, key)) {
        updatedEnrollState[key] = updatedEnrollState[key].filter(
          (patient) => patient.patient_id !== patientId,
        );
      }
    }
    dispatch(setSelectedEnrollPatientsAction(updatedEnrollState));
  };

  const onSubmit = (data) => {
    if (!selectedProvider) {
      setProviderError("Provider is required");
      return;
    }

    if (patientInfo) {
      // add patient_id to data
      data = {
        ...data,
        patient_id: patientInfo.patient_id,
      };
    }

    // add notListed values
    if (data.sex === "notListed") {
      data.sex = sexNotListed;
    }
    if (data.gender === "notListed") {
      data.gender = genderNotListed;
    }

    data = {
      ...data,
      // convert fields to correct format
      birthdate: data.birthdate.format("YYYY-MM-DD"),
      doctor_id: selectedProvider ? Number(selectedProvider.doctor_id) : null,
      phone_number: data.phone_number
        ? !data.phone_number.startsWith("+")
          ? `+${data.phone_number}`
          : data.phone_number
        : null,
      customer_id: data.customer_id ? Number(data.customer_id) : "",

      // convert fields to array
      insurance: [
        {
          policy_holder_name: data.primary_policy_holder_name,
          holder_dob: data.primary_holder_dob
            ? data.primary_holder_dob.format("YYYY-MM-DD")
            : null,
          holder_ssn: data.primary_holder_ssn,
          relationship_to_holder: data.primary_relationship_to_holder,
          group_number: data.primary_group_number,
          policy_number: data.primary_policy_number,
          insurance_name: data.primary_insurance_name,
        },
        {
          policy_holder_name: data.secondary_policy_holder_name,
          holder_dob: data.secondary_holder_dob
            ? data.secondary_holder_dob.format("YYYY-MM-DD")
            : null,
          holder_ssn: data.secondary_holder_ssn,
          relationship_to_holder: data.secondary_relationship_to_holder,
          group_number: data.secondary_group_number,
          policy_number: data.secondary_policy_number,
          insurance_name: data.secondary_insurance_name,
        },
      ],
      emergency_contact: [
        {
          first_name: data.emergency_contact_first_name,
          last_name: data.emergency_contact_last_name,
          phone_number: data.emergency_contact_phone_number
            ? !data.emergency_contact_phone_number.startsWith("+")
              ? `+${data.emergency_contact_phone_number}`
              : data.emergency_contact_phone_number
            : null,
          relationship: data.emergency_contact_relationship,
        },
      ],
    };

    // remove converted fields
    delete data.primary_policy_holder_name;
    delete data.primary_holder_dob;
    delete data.primary_holder_ssn;
    delete data.primary_relationship_to_holder;
    delete data.primary_group_number;
    delete data.primary_policy_number;
    delete data.primary_insurance_name;
    delete data.secondary_policy_holder_name;
    delete data.secondary_holder_dob;
    delete data.secondary_holder_ssn;
    delete data.secondary_relationship_to_holder;
    delete data.secondary_group_number;
    delete data.secondary_policy_number;
    delete data.secondary_insurance_name;
    delete data.emergency_contact_first_name;
    delete data.emergency_contact_last_name;
    delete data.emergency_contact_phone_number;
    delete data.emergency_contact_relationship;

    if (!sexError && !providerError) {
      if (edit) {
        dispatch(
          updatePatient(
            data,
            1,
            rowsPerPage * currPage,
            searchValue,
            selectedDoctorPatients,
            () => {
              if (currentConversation) {
                dispatch(getConversation(currentConversation.conversation_id));
              }
              dispatch(
                searchConversationsWithFilters(
                  conversationSearchInput,
                  activeFilters,
                  1,
                  convPerPage * convCurrPage,
                  activeInbox,
                  selectedDoctorInbox,
                ),
              );
              removeSelectedEnrollPatients(data.patient_id);
              onUpdate();
              onClose();
            },
            selectedPatientCampaigns,
            selectedPatientStatuses,
          ),
        );
      } else {
        dispatch(
          createPatient(
            data,
            1,
            rowsPerPage * currPage,
            searchValue,
            selectedDoctorPatients,
            () => onClose(),
            selectedPatientCampaigns,
            selectedPatientStatuses,
          ),
        );
      }
    }
  };

  return (
    <div
      className={`w-full grid grid-rows-chat-layout overflow-hidden ${className}`}
    >
      {edit && (
        <div
          className="flex flex-none min-w-0 h-fit space-x-2 whitespace-nowrap overflow-x-auto scrollbar
          pt-3 md:pt-5 pb-1 mx-5"
        >
          <TabButtonRound
            label={MAIN}
            active={activeTab}
            error={
              errors.first_name ||
              errors.last_name ||
              errors.birthdate ||
              errors.sex ||
              errors.phone_number ||
              providerError ||
              sexError
            }
            onClick={() => setActiveTab(MAIN)}
          />
          <TabButtonRound
            label={DEMOGRAPHICS}
            active={activeTab}
            onClick={() => setActiveTab(DEMOGRAPHICS)}
          />
          <TabButtonRound
            label={CONTACT}
            active={activeTab}
            error={errors.email}
            onClick={() => setActiveTab(CONTACT)}
          />
          <TabButtonRound
            label={INSURANCE}
            active={activeTab}
            onClick={() => setActiveTab(INSURANCE)}
          />
          <TabButtonRound
            label={CARE_LOCATIONS}
            active={activeTab}
            onClick={() => setActiveTab(CARE_LOCATIONS)}
          />
        </div>
      )}

      <form
        id="patient-form"
        onSubmit={handleSubmit(onSubmit)}
        className="h-full min-h-0 width-height-md:min-h-[380px] md:max-h-[380px] py-5 pl-5 pr-2 mr-2
          flex flex-col justify-between overflow-y-auto scrollbar"
      >
        <div
          className={`md:grid-cols-6 gap-5
            ${activeTab === MAIN ? "grid" : "hidden"}`}
        >
          <Input
            className="md:col-span-2"
            register={register}
            name="first_name"
            label="First Name"
            placeholder="Enter first name"
            required="First name is required"
            error={errors.first_name}
          />
          <Input
            className="md:col-span-2"
            register={register}
            name="middle_name"
            label="Middle Name"
            placeholder="Enter middle name"
          />
          <Input
            className="md:col-span-2"
            register={register}
            name="last_name"
            label="Last Name"
            placeholder="Enter last name"
            required="Last name is required"
            error={errors.last_name}
          />

          <DateTimeShortcutsInput
            className="md:col-span-3"
            name="birthdate"
            label="DOB"
            control={control}
            required="Date of birth is required"
            error={errors.birthdate}
            validateFunc={(value) => {
              const currentDate = dayjs();
              const selectedDate = dayjs(value);
              if (selectedDate.isAfter(currentDate, "day")) {
                return "Please choose a date in the past";
              }
              return true;
            }}
            maxDateTime={dayjs()}
          />

          <div
            className={`md:col-span-3 ${showInputSex ? "row-span-2 space-y-5" : ""}`}
          >
            <Select
              className=""
              label="Sex"
              placeholder="Select Sex"
              name="sex"
              options={sexOptions}
              register={register}
              required="Biological Sex is required"
              error={errors.sex}
            />
            {showInputSex && (
              <Input
                value={sexNotListed}
                onChange={(e) => setSexNotListed(e.target.value)}
                label="Specify Sex"
                placeholder="Enter Sex"
                required="Biological Sex is required"
                error={sexError}
              />
            )}
          </div>

          <CountryPhoneInput
            name="phone_number"
            label="Phone Number"
            className="md:col-span-3"
            countries={countryCodes}
            control={control}
            error={errors.phone_number}
            resizeDeps={[activeTab]}
          />

          <Select
            className="md:col-span-3 truncate"
            label="Primary Language"
            placeholder="Select language"
            name="primary_language"
            options={languages}
            register={register}
          />

          <div className="md:col-span-6 w-full">
            <p className="text-sm font-semibold mb-1.5">
              Provider<span className="text-red-600">*</span>
            </p>
            {selectedProvider ? (
              <div
                className="grid grid-cols-conv-layout items-center justify-between gap-2.5 w-full min-w-0 py-2 px-2.5
                  rounded-lg border border-gray-300"
              >
                <div className="flex items-center space-x-2.5 min-w-0">
                  <ProfilePicture
                    firstName={selectedProvider.first_name}
                    lastName={selectedProvider.last_name}
                    src={selectedProvider.profile_picture}
                    flagPadding={false}
                  />
                  <div className="truncate">
                    <p className="text-sm font-semibold truncate">
                      {selectedProvider.display_name}
                    </p>
                    <p className="text-xs font-medium text-tertiary truncate">
                      {selectedProvider.occupation}
                    </p>
                  </div>
                </div>
                <button
                  type="button"
                  className="w-8 h-8 bg-white rounded-lg flex items-center justify-center"
                  onClick={() => setSelectedProvider(null)}
                >
                  <RemoveIcon stroke="#667085" width="16" height="16" />
                </button>
              </div>
            ) : (
              <AutocompleteVirtualized
                options={users}
                onSelect={handleSelectProvider}
                className="w-full bg-white rounded-lg"
                placeholder="Select provider"
                isDoctors
                required
                error={providerError}
              />
            )}
          </div>
        </div>

        <div
          className={`md:grid-cols-2 gap-5
            ${activeTab === DEMOGRAPHICS ? "grid" : "hidden"}`}
        >
          <Input
            className="md:col-span-2"
            register={register}
            name="preferred_name"
            label="Preferred First Name"
            placeholder="Enter preferred name"
          />

          <Select
            label="Ethnicity"
            placeholder="Select Ethnicity"
            name="ethnicity"
            options={ethnicityOptions}
            register={register}
          />

          <Select
            label="Race"
            placeholder="Select Race"
            name="race"
            options={raceOptions}
            register={register}
          />

          <div className={showInputGender ? "row-span-2 space-y-5" : ""}>
            <Select
              label="Gender"
              placeholder="Select Gender"
              name="gender"
              options={genderOptions}
              register={register}
            />
            {showInputGender && (
              <Input
                value={genderNotListed}
                onChange={(e) => setGenderNotListed(e.target.value)}
                label="Specify Gender"
                placeholder="Enter Gender"
              />
            )}
          </div>

          <Select
            label="Pronouns"
            placeholder="Select Pronouns"
            name="pronouns"
            options={pronounsOptions}
            register={register}
          />

          <Select
            label="Sexual Orientation"
            placeholder="Select Sexual Orientation"
            name="sexual_orientation"
            options={sexualOrientationOptions}
            register={register}
          />

          <Input
            register={register}
            name="social_security_number"
            label="Social Security"
            placeholder="Enter social security number"
          />

          <p className={`${subtitleClasses} md:col-span-2`}>Address</p>
          <Input
            className="md:col-span-2"
            register={register}
            name="address_line_1"
            label="Line 1"
            placeholder="Enter address"
          />
          <Input
            className="md:col-span-2"
            register={register}
            name="address_line_2"
            label="Line 2"
            placeholder="Enter address line 2"
          />
          <Input
            register={register}
            name="city"
            label="City"
            placeholder="Enter city"
          />
          <Input
            register={register}
            name="state"
            label="State"
            placeholder="Enter state"
          />
          <Input
            register={register}
            name="country"
            label="Country"
            placeholder="Enter country"
          />
          <Input
            register={register}
            name="zipcode"
            label="Zip Code"
            placeholder="Enter zip code"
          />
        </div>

        <div
          className={`md:grid-cols-2 gap-5
            ${activeTab === INSURANCE ? "grid" : "hidden"}`}
        >
          <p className={`${subtitleClasses} md:col-span-2`}>
            Primary Insurance
          </p>
          <Input
            className="md:col-span-2"
            register={register}
            name="primary_policy_holder_name"
            label="Policy Holder’s Name"
            placeholder="Enter policy holder’s Name"
          />
          <DateTimeShortcutsInput
            name="primary_holder_dob"
            label="Holder’s DOB"
            control={control}
            error={errors.primary_holder_dob}
            validateFunc={(value) => {
              const currentDate = dayjs();
              const selectedDate = dayjs(value);
              if (selectedDate.isAfter(currentDate, "day")) {
                return "Please choose a date in the past";
              }
              return true;
            }}
            maxDateTime={dayjs()}
          />
          <Input
            register={register}
            name="primary_holder_ssn"
            label="Holder’s SSN"
            placeholder="Enter holder’s SSN"
          />
          <Input
            register={register}
            name="primary_relationship_to_holder"
            label="Relation to Holder"
            placeholder="Enter insurance carrier"
          />
          <Input
            register={register}
            name="primary_group_number"
            label="Group Number"
            placeholder="Enter group number"
          />
          <Input
            register={register}
            name="primary_policy_number"
            label="Policy Number"
            placeholder="Enter policy number"
          />
          <Input
            register={register}
            name="primary_insurance_name"
            label="Insurance"
            placeholder="Enter insurance"
          />

          <p className={`${subtitleClasses} md:col-span-2`}>
            Secondary Insurance
          </p>
          <Input
            className="md:col-span-2"
            register={register}
            name="secondary_policy_holder_name"
            label="Policy Holder’s Name"
            placeholder="Enter policy holder’s Name"
          />
          <DateTimeShortcutsInput
            name="secondary_holder_dob"
            label="Holder’s DOB"
            control={control}
            error={errors.secondary_holder_dob}
            validateFunc={(value) => {
              const currentDate = dayjs();
              const selectedDate = dayjs(value);
              if (selectedDate.isAfter(currentDate, "day")) {
                return "Please choose a date in the past";
              }
              return true;
            }}
            maxDateTime={dayjs()}
          />
          <Input
            register={register}
            name="secondary_holder_ssn"
            label="Holder’s SSN"
            placeholder="Enter holder’s SSN"
          />
          <Input
            register={register}
            name="secondary_relationship_to_holder"
            label="Relation to Holder"
            placeholder="Enter insurance carrier"
          />
          <Input
            register={register}
            name="secondary_group_number"
            label="Group Number"
            placeholder="Enter group number"
          />
          <Input
            register={register}
            name="secondary_policy_number"
            label="Policy Number"
            placeholder="Enter policy number"
          />
          <Input
            register={register}
            name="secondary_insurance_name"
            label="Insurance"
            placeholder="Enter insurance"
          />
        </div>

        <div
          className={`md:grid-cols-2 gap-5
            ${activeTab === CONTACT ? "grid" : "hidden"}`}
        >
          <Input
            register={register}
            name="email"
            label="Email"
            placeholder="Enter email"
            type="email"
            error={errors.email}
            validationPattern={{
              value: /\S+@\S+\.\S+/,
              message: "Enter a valid email address",
            }}
          />
          <CountryPhoneInput
            name="secondary_phone_number"
            label="Secondary Phone Number"
            countries={countryCodes}
            control={control}
            resizeDeps={[activeTab]}
          />

          <p className={`${subtitleClasses} md:col-span-2`}>
            Emergency Contact
          </p>
          <Input
            register={register}
            name="emergency_contact_first_name"
            label="First Name"
            placeholder="Enter contact first name"
          />
          <Input
            register={register}
            name="emergency_contact_last_name"
            label="Last Name"
            placeholder="Enter contact last name"
          />
          <CountryPhoneInput
            name="emergency_contact_phone_number"
            label="Phone Number"
            countries={countryCodes}
            control={control}
            error={errors.emergency_contact_phone_number}
            resizeDeps={[activeTab]}
          />
          <Input
            register={register}
            name="emergency_contact_relationship"
            label="Relationship"
            placeholder="Enter relationship to patient"
          />
        </div>

        <div
          className={`md:grid-cols-2 gap-5
            ${activeTab === CARE_LOCATIONS ? "grid" : "hidden"}`}
        >
          <Select
            className="truncate"
            label="Clinic Name"
            placeholder="Select clinic"
            name="customer_id"
            options={facilitySelectOptions}
            register={register}
          />
          <Input
            register={register}
            name="chart_number"
            label="Chart Number"
            placeholder="Enter chart number"
          />
          <Input
            register={register}
            name="pharmacy_name"
            label="Pharmacy Name"
            placeholder="Enter pharmacy name"
          />
          <Input
            register={register}
            name="pharmacy_phone"
            label="Pharmacy Phone"
            placeholder="Enter pharmacy phone"
          />
          <Input
            className="md:col-span-2"
            register={register}
            name="pharmacy_address"
            label="Pharmacy Address"
            placeholder="Enter pharmacy address"
          />
        </div>
      </form>

      <div className="w-full bg-gray-background py-4 px-5 flex justify-end space-x-4 text-sm font-semibold">
        <Button
          variant="gray"
          onClick={(e) => {
            e.preventDefault();
            onClose();
          }}
        >
          Cancel
        </Button>
        <Button
          className="whitespace-nowrap"
          onClick={checkInputSexOther}
          type="submit"
          form="patient-form"
        >
          Save {edit ? "Changes" : ""}
        </Button>
      </div>
    </div>
  );
};

export default AddEditPatientForm;
