import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { ReactComponent as ArrowIcon } from "../../assets/icons/arrow-down.svg";
import { ReactComponent as BuildingIcon } from "../../assets/icons/building.svg";
import { ReactComponent as InboxIcon } from "../../assets/icons/inbox-type.svg";
import ModalMobile from "./ModalMobile";

const DoctorButton = ({
  title,
  className,
  doctorOptions,
  listClassName = "w-full",
  selectedDoctor,
  onSelectDoctor = () => {},
  getCountComponent = () => {},
  unassignedOption,
  inbox,
  isPatients,
  setCancelSelection,
}) => {
  const { user } = useSelector((state) => state.user);
  const [openDropdown, setOpenDropdown] = useState(false);
  const isDesktop = useMediaQuery({ minWidth: 768 });
  const hasMounted = useRef(false);

  useEffect(() => {
    if (!hasMounted.current) {
      hasMounted.current = true;
      onSelectDoctor(selectedDoctor);
    }
  }, [onSelectDoctor, selectedDoctor]);

  const onSelect = (doctor) => {
    if (isPatients) {
      setCancelSelection(true);
    }
    onSelectDoctor(doctor);
    setOpenDropdown(false);
  };

  const getOptions = () => {
    return (
      <div
        className={`flex flex-col items-start space-y-1 md:max-w-md
          ${inbox ? "max-w-[80vw] p-2" : " max-w-full w-full md:p-2"}`}
      >
        {inbox && (
          <button
            type="button"
            key="all_inbox"
            onClick={() => onSelect("clinic_all")}
            className={`p-3 w-full text-left flex items-center rounded-lg hover:bg-indigo-50
            ${selectedDoctor === "clinic_all" ? "text-primary-blue bg-indigo-50" : "text-zinc-700"}`}
          >
            <BuildingIcon
              width="17"
              height="17"
              className="mr-2 flex-none"
              stroke={selectedDoctor === "clinic_all" ? "#2970FF" : "#3F3F46"}
            />
            <span>{user.customer.display_name || user.customer.name}</span>
            <span
              className={`ml-auto h-5 min-w-[28px] rounded-full text-white text-xs font-medium px-1.5
              flex items-center justify-center
              ${selectedDoctor === "clinic_all" ? "bg-primary-blue" : "bg-slate-400"}`}
            >
              {getCountComponent("clinic_all")}
            </span>
          </button>
        )}

        <button
          type="button"
          key="all"
          onClick={() => onSelect(null)}
          className={`p-3 w-full text-left flex items-center rounded-lg hover:bg-indigo-50
            ${!selectedDoctor ? "text-primary-blue bg-indigo-50" : "text-zinc-700"}`}
        >
          {inbox && (
            <InboxIcon
              className="mr-2 flex-none"
              stroke={!selectedDoctor ? "#2970FF" : "#3F3F46"}
              width="17"
              height="17"
            />
          )}
          {!inbox && (
            <span>{user.customer.display_name || user.customer.name}</span>
          )}
          {inbox && <span>All Inboxes</span>}
          <span
            className={`ml-auto h-5 min-w-[28px] rounded-full text-white text-xs font-medium px-1.5
              flex items-center justify-center
              ${!selectedDoctor ? "bg-primary-blue" : "bg-slate-400"}`}
          >
            {getCountComponent(null)}
          </span>
        </button>

        {doctorOptions.map((doctor) => (
          <button
            type="button"
            key={doctor.doctor_id}
            onClick={() => onSelect(doctor)}
            className={`p-3 w-full text-left flex flex-none items-center rounded-lg hover:bg-indigo-50 truncate
              ${selectedDoctor?.doctor_id === doctor.doctor_id ? "text-primary-blue bg-indigo-50" : "text-zinc-700"}`}
          >
            {inbox && (
              <InboxIcon
                className="mr-2 flex-none"
                stroke={
                  selectedDoctor?.doctor_id === doctor.doctor_id
                    ? "#2970FF"
                    : "#3F3F46"
                }
                width="17"
                height="17"
              />
            )}
            <span className="truncate mr-2">{doctor.team.name}</span>
            <span
              className={`ml-auto h-5 min-w-[28px] rounded-full text-white text-xs font-medium px-1.5
                flex items-center justify-center
                ${selectedDoctor?.doctor_id === doctor.doctor_id ? "bg-primary-blue" : "bg-slate-400"}`}
            >
              {getCountComponent(doctor.doctor_id)}
            </span>
          </button>
        ))}

        {unassignedOption && (
          <button
            type="button"
            key="unassigned"
            onClick={() => onSelect("unassigned")}
            className={`p-3 w-full text-left flex items-center rounded-lg hover:bg-indigo-50
              ${selectedDoctor === "unassigned" ? "text-primary-blue bg-indigo-50" : "text-zinc-700"}`}
          >
            {inbox && (
              <InboxIcon
                className="mr-2 flex-none"
                stroke={selectedDoctor === "unassigned" ? "#2970FF" : "#3F3F46"}
                width="17"
                height="17"
              />
            )}
            Unassigned
            <span
              className={`ml-auto h-5 min-w-[28px] rounded-full text-white text-xs font-medium px-1.5
                flex items-center justify-center
                ${selectedDoctor === "unassigned" ? "bg-primary-blue" : "bg-slate-400"}`}
            >
              {getCountComponent("unassigned")}
            </span>
          </button>
        )}
      </div>
    );
  };

  return (
    <div className={`relative ${className}`}>
      <button
        type="button"
        className="max-w-full flex items-center space-x-3 truncate"
        onClick={() => setOpenDropdown(!openDropdown)}
      >
        <div className="font-semibold text-lg flex truncate">
          {selectedDoctor && title && selectedDoctor !== "clinic_all" && (
            <span>{title}</span>
          )}
          {selectedDoctor &&
            selectedDoctor !== "unassigned" &&
            selectedDoctor !== "clinic_all" && (
              <span className="ml-1.5 truncate">
                {title && <span>- </span>}
                {selectedDoctor.team.name}
              </span>
            )}
          {selectedDoctor && selectedDoctor === "unassigned" && (
            <span className="ml-1.5 truncate">
              {title && <span>- </span>}Unassigned
            </span>
          )}
          {!selectedDoctor && !inbox && (
            <span className="truncate">
              {user.customer.display_name || user.customer.name}
            </span>
          )}
          {selectedDoctor && selectedDoctor === "clinic_all" && inbox && (
            <span className="truncate">
              {user.customer.display_name || user.customer.name}
            </span>
          )}
          {!selectedDoctor && inbox && (
            <span className="truncate">All Inboxes</span>
          )}
        </div>
        <ArrowIcon
          width="10"
          height="7"
          className={`mt-0.5 transition-all duration-300 flex-none
            ${openDropdown ? "-rotate-180" : ""}`}
        />
      </button>

      {openDropdown && (isDesktop || inbox) && (
        <div
          className={`absolute z-20 left-0 top-full bg-white rounded-2.5 shadow-md overflow-hidden
            leading-tight text-sm font-medium max-h-70vh-dynamic overflow-y-auto scrollbar ${listClassName}`}
        >
          {getOptions()}
        </div>
      )}

      {!isDesktop && !inbox && (
        <ModalMobile
          open={openDropdown}
          childHeight={450}
          header={`Select Team (${doctorOptions.length + 2})`}
          onClickAway={() => setOpenDropdown(false)}
        >
          {getOptions()}
        </ModalMobile>
      )}
    </div>
  );
};

export default DoctorButton;
