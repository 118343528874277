import {
  LOGOUT,
  SET_FACILITIES,
  SET_FACILITIES_LOADING,
  SET_FACILITY_SEARCH_VALUE,
  SET_USER_FACILITIES,
} from "../types";

export type Facility = {
  customer_id: number;
  display_name: string;
  name: string;
};

export type FacilityState = {
  facilities: Facility[];
  facilitySearchValue: string;
  facilitiesLoading: boolean;
  userFacilities: Record<number, Facility[]>;
};

const init: FacilityState = {
  facilities: [],
  facilitySearchValue: "",
  facilitiesLoading: true,
  userFacilities: {},
};

type FacilityAction = {
  type: string;
  payload: any;
};

export default function facilityReducer(
  state = init,
  action: FacilityAction,
): FacilityState {
  const { type, payload } = action;

  switch (type) {
    case LOGOUT:
      return { ...init };
    case SET_FACILITIES:
      return { ...state, facilities: payload.facilities };
    case SET_FACILITY_SEARCH_VALUE:
      return { ...state, facilitySearchValue: payload.facilitySearchValue };
    case SET_FACILITIES_LOADING:
      return { ...state, facilitiesLoading: payload.facilitiesLoading };
    case SET_USER_FACILITIES:
      return {
        ...state,
        userFacilities: {
          ...state.userFacilities,
          [payload.id]: payload.facilities,
        },
      };
    default:
      return state;
  }
}
