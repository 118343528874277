const InputBlock = ({
  className = "",
  titleClassName = "text-base",
  title,
  subtitle = "",
  children,
}: {
  className?: string;
  titleClassName?: string;
  title: string;
  subtitle?: string | React.ReactNode;
  children: React.ReactNode;
}) => {
  return (
    <div
      className={`flex flex-col md:grid grid-cols-3 gap-3 md:gap-5 max-w-5xl ${className}`}
    >
      <div>
        <p className={`font-semibold ${titleClassName}`}>{title}</p>
        <div className="text-tertiary text-xs">{subtitle}</div>
      </div>
      {children}
    </div>
  );
};

export default InputBlock;
