import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { ReactComponent as CheckIcon } from "../../../assets/icons/check-circle-opacity.svg";
import { convPerPage } from "../../../helpers/constants";
import { setConversationsCompletedTempAction } from "../../../store/conversation/actions";
import { completeConversation } from "../../../store/conversation/thunks";
import BeamLine from "../../Basic/BeamLine";

const CompleteButton = ({ conversation, className = "bg-white" }) => {
  const {
    conversationsCompletedTemp,
    conversationSearchInput,
    activeInbox,
    activeFilters,
    convCurrPage,
    selectedDoctorInbox,
  } = useSelector((state) => state.conversation);
  const [startAnimation, setStartAnimation] = useState(false);
  const dispatch = useDispatch();
  const isDesktop = useMediaQuery({ minWidth: 768 });

  useEffect(() => {
    if (conversation?.conversation_id && conversation?.completed) {
      setStartAnimation(false);
    }
  }, [conversation?.completed, conversation?.conversation_id]);

  const onCompleteConversation = () => {
    setTimeout(() => {
      if (isDesktop) {
        dispatch(
          setConversationsCompletedTempAction([
            ...conversationsCompletedTemp,
            conversation.conversation_id,
          ]),
        );
      }
      dispatch(
        completeConversation(
          conversation.conversation_id,
          conversationSearchInput,
          activeFilters,
          1,
          convPerPage * convCurrPage,
          activeInbox,
          selectedDoctorInbox,
        ),
      );
    }, 500);
  };

  return (
    <div
      className={`w-full flex items-center justify-center md:justify-between text-sm px-4
        transition-all overflow-hidden
        ${conversation.completed || conversation.messages.length === 0 ? "h-0" : "h-10 lg:h-11"} ${className}`}
    >
      <p className="hidden md:flex font-medium text-tertiary">
        Finished review?
      </p>

      {conversation.completed ? (
        <CheckIcon width="20" height="20" className="flex-none" />
      ) : (
        <button
          type="button"
          className={`flex items-center justify-center relative whitespace-nowrap
            font-bold text-system-green-dark h-10 w-[140px]
            ${startAnimation ? "animate-complete-mark" : ""}`}
          onClick={() => {
            setStartAnimation(true);
            onCompleteConversation();
          }}
        >
          {startAnimation ? (
            <>
              <CheckIcon width="20" height="20" className="flex-none" />
              <BeamLine rotate="0" />
              <BeamLine rotate="30" />
              <BeamLine rotate="-30" />
              <BeamLine rotate="60" />
              <BeamLine rotate="-60" />
              <BeamLine rotate="90" />
            </>
          ) : (
            <div className="flex items-center space-x-1">
              <CheckIcon width="20" height="20" className="flex-none" />
              <p>Mark as complete</p>
            </div>
          )}
        </button>
      )}
    </div>
  );
};

export default CompleteButton;
