import { useEffect, useMemo, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { availableTags } from "../../helpers/constants";
import { insertTag } from "../../helpers/helpers";
import {
  getCampaign,
  updateCampaignDetails,
} from "../../store/campaign/thunks";
import Button from "../Basic/Button";
import Input from "../Basic/Input";
import LoaderDots from "../Basic/LoaderDots";
import TextareaAutosizeForm from "../Basic/TextareaAutosizeForm";
import TextareaTags from "../Basic/TextareaTags";

const DetailsTab = () => {
  const { selectedEnrollCampaign, loader } = useSelector(
    (state) => state.campaign,
  );
  const [focusedFieldName, setFocusedFieldName] = useState("firstMessage");
  const [messagesUpdated, setMessagesUpdated] = useState(false);
  const messagesRef = useRef(null);
  const dispatch = useDispatch();

  const [messagesValues, setMessagesValues] = useState({
    firstMessage: selectedEnrollCampaign?.first_message || "",
    checkinMessage: selectedEnrollCampaign?.checkin_message || "",
    lastMessage: selectedEnrollCampaign?.last_message || "",
    breakMessage: selectedEnrollCampaign?.break_message || "",
  });

  const contentRefs = {
    firstMessage: useRef(null),
    checkinMessage: useRef(null),
    lastMessage: useRef(null),
    breakMessage: useRef(null),
  };

  const defaultValues = useMemo(() => {
    return {
      name: selectedEnrollCampaign?.campaign || "",
      description: selectedEnrollCampaign?.description || "",
    };
  }, [selectedEnrollCampaign]);

  const {
    handleSubmit,
    register,
    reset,
    formState: { isDirty },
  } = useForm({
    defaultValues: defaultValues,
  });

  useEffect(() => {
    reset(defaultValues);
    setMessagesValues({
      firstMessage: selectedEnrollCampaign?.first_message || "",
      checkinMessage: selectedEnrollCampaign?.checkin_message || "",
      lastMessage: selectedEnrollCampaign?.last_message || "",
      breakMessage: selectedEnrollCampaign?.break_message || "",
    });
    setMessagesUpdated(false);
  }, [selectedEnrollCampaign, reset, defaultValues]);

  const onFocusMessageField = (_e, fieldName) => {
    setFocusedFieldName(fieldName);
  };

  const onSubmit = (data) => {
    const plainTextValues = { ...messagesValues };

    for (const key in contentRefs) {
      const contentRef = contentRefs[key];
      if (contentRef.current) {
        const content = contentRef.current.innerHTML;
        plainTextValues[key] = content
          .replace(/<span[^>]*>([^<]+)<\/span>/g, (_, text) => {
            const tag = availableTags.find((tag) => tag.name === text);
            if (tag) {
              return tag.value;
            }
            return `{{${text.toUpperCase()}}}`;
          })
          .replace(/<\/div>\s*<div>/g, "\n")
          .replace(/<\/div>([^<]+)/g, "</div>\n$1")
          .replace(/([^<]+)<div>/g, "$1\n<div>")
          .replace(/<\/?[^>]+>/g, "");
      }
    }

    setMessagesUpdated(false);

    dispatch(
      updateCampaignDetails(
        selectedEnrollCampaign.campaign_id,
        data.name,
        data.description,
        plainTextValues.firstMessage,
        plainTextValues.checkinMessage,
        plainTextValues.lastMessage,
        plainTextValues.breakMessage,
        () => dispatch(getCampaign(selectedEnrollCampaign.campaign_id)),
      ),
    );
  };

  const handleTextareaChange = () => {
    setMessagesUpdated(true);
  };

  const onClickTag = (tag, contentRefKey) => {
    const contentRef = contentRefs[contentRefKey];
    if (contentRef.current) {
      setMessagesUpdated(true);
      const selection = window.getSelection();
      insertTag(selection, tag);
    }
  };

  const getTagButtons = (fieldName) => {
    return (
      <div className="space-y-1.5 flex flex-col w-fit">
        <p className="font-semibold whitespace-nowrap">Available tags</p>
        <div className="flex flex-wrap gap-1.5">
          {availableTags.map((tag, index) => (
            <button
              key={index}
              type="button"
              className="py-1 px-2 rounded-md text-xs w-fit bg-gray-140 hover:bg-gray-foreground
                disabled:hover:bg-gray-200 disabled:bg-gray-200 whitespace-nowrap"
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                if (fieldName) {
                  onClickTag(tag, fieldName);
                }
              }}
            >
              {tag.name}
            </button>
          ))}
        </div>
      </div>
    );
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="w-full md:max-w-2xl text-sm border-gray-300 grid gap-6 lg:grid-cols-conv-layout grid-rows-auto relative"
    >
      <Input
        register={register}
        name="name"
        label="Name"
        placeholder="Name"
        className="col-start-1 row-start-1"
      />
      <TextareaAutosizeForm
        name="description"
        label="Description"
        placeholder="Description"
        register={register}
        className="col-start-1 row-start-2"
      />

      <div
        ref={messagesRef}
        className="space-y-6 w-full col-start-1 row-start-3"
      >
        {focusedFieldName === "firstMessage" && getTagButtons("firstMessage")}
        <TextareaTags
          label="First Message"
          placeholder="Type first message"
          contentRef={
            contentRefs.firstMessage !== null ? contentRefs.firstMessage : ""
          }
          value={
            messagesValues.firstMessage !== null
              ? messagesValues.firstMessage
              : ""
          }
          onChange={handleTextareaChange}
          onFocus={(e) => onFocusMessageField(e, "firstMessage")}
        />

        {(selectedEnrollCampaign?.campaign_type === "Outreach" ||
          selectedEnrollCampaign?.campaign_type === "Outreach_PCM") && (
          <>
            {focusedFieldName === "checkinMessage" &&
              getTagButtons("checkinMessage")}
            <TextareaTags
              label="Checkin Message"
              placeholder="Type checkin message"
              contentRef={
                contentRefs.checkinMessage !== null
                  ? contentRefs.checkinMessage
                  : ""
              }
              value={
                messagesValues.checkinMessage !== null
                  ? messagesValues.checkinMessage
                  : ""
              }
              onChange={handleTextareaChange}
              onFocus={(e) => onFocusMessageField(e, "checkinMessage")}
            />
          </>
        )}

        {focusedFieldName === "lastMessage" && getTagButtons("lastMessage")}
        <TextareaTags
          label="Review Message"
          placeholder="Type review message"
          contentRef={
            contentRefs.lastMessage !== null ? contentRefs.lastMessage : ""
          }
          value={
            messagesValues.lastMessage !== null
              ? messagesValues.lastMessage
              : ""
          }
          onChange={handleTextareaChange}
          onFocus={(e) => onFocusMessageField(e, "lastMessage")}
        />

        {focusedFieldName === "breakMessage" && getTagButtons("breakMessage")}
        <TextareaTags
          label="Break Message"
          placeholder="Type break message"
          contentRef={
            contentRefs.breakMessage !== null ? contentRefs.breakMessage : ""
          }
          value={
            messagesValues.breakMessage !== null
              ? messagesValues.breakMessage
              : ""
          }
          onChange={handleTextareaChange}
          onFocus={(e) => onFocusMessageField(e, "breakMessage")}
        />
      </div>

      <Button
        type="submit"
        variant="blue-light"
        className="w-36 text-base font-semibold ml-auto col-start-1"
        disabled={
          loader || (!messagesUpdated && !isDirty) || !selectedEnrollCampaign
        }
      >
        {loader ? <LoaderDots /> : "Update"}
      </Button>
    </form>
  );
};

export default DetailsTab;
