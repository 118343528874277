import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createUser } from "../../../../store/user/thunks";
import Button from "../../../Basic/Button";
import FacilityMultipleSelect from "../../../Basic/FacilityMultipleSelect";
import InputMultipleEmails from "../../../Basic/InputMultipleEmails";
import Select from "../../../Basic/Select";

const AddMultipleUsersForm = ({ onClose, className = "" }) => {
  const { userSearchValue, roleOptions } = useSelector((state) => state.user);
  const [emails, setEmails] = useState([]);
  const [emailsError, setEmailsError] = useState("");
  const [selectedRole, setSelectedRole] = useState(1);
  const [selectedFacilities, setSelectedFacilities] = useState([]);
  const [facilitiesError, setFacilitiesError] = useState(null);
  const dispatch = useDispatch();

  const onInvite = () => {
    if (emails.length === 0) {
      setEmailsError("Email can’t be blank");
      return;
    }

    if (selectedFacilities.length === 0) {
      setFacilitiesError("Please select at least one clinic");
      return;
    }

    const providers = [];
    emails.map((email) => {
      providers.push({
        email: email,
        role_id: Number(selectedRole),
        facilities: selectedFacilities.map((facility) => facility.customer_id),
      });
    });

    dispatch(createUser(providers, onClose, userSearchValue));
    onClose();
  };

  return (
    <div
      className={`w-full grid grid-rows-note-layout overflow-hidden ${className}`}
    >
      <form className="px-5 space-y-3 flex flex-col min-h-0 overflow-y-auto scrollbar">
        <InputMultipleEmails
          label="Email"
          required
          withoutAsterisk
          placeholder="Input email"
          emails={emails}
          setEmails={setEmails}
          error={emailsError}
          setError={setEmailsError}
        />
        <Select
          label="Role"
          placeholder="Select role"
          options={roleOptions}
          value={selectedRole}
          onChange={(e) => setSelectedRole(e.target.value)}
        />
        <div className="space-y-1.5">
          <p className="font-semibold text-sm">Clinics</p>
          <FacilityMultipleSelect
            displaySelect
            selectedFacilities={selectedFacilities}
            setSelectedFacilities={setSelectedFacilities}
            error={facilitiesError}
            setError={setFacilitiesError}
          />
        </div>
      </form>

      <div className="flex justify-end gap-4 font-semibold text-sm bg-gray-background py-4 px-5 mt-5">
        <Button className="w-full md:w-fit" variant="gray" onClick={onClose}>
          Cancel
        </Button>
        <Button className="w-full md:w-fit" onClick={onInvite}>
          Invite
        </Button>
      </div>
    </div>
  );
};

export default AddMultipleUsersForm;
