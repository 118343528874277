import { useEffect, useState } from "react";
import { ReactComponent as ArrowIcon } from "../../../../assets/icons/chevron-down.svg";

const DropdownIconButton = ({
  icon,
  label,
  onClick,
  selectedItem,
  clicked,
  onUpdateTitle,
  inputWithBorder,
  createNew,
  itemName,
}) => {
  const [newTitle, setNewTitle] = useState(
    createNew ? `New ${itemName}` : selectedItem?.name?.toString() || label,
  );

  useEffect(() => {
    if (selectedItem) {
      setNewTitle(selectedItem.name?.toString() || label);
    } else if (!createNew) {
      setNewTitle(label);
    } else {
      setNewTitle(`New ${itemName}`);
    }
  }, [selectedItem, createNew, itemName, label]);

  return (
    <div
      className="w-full flex items-center justify-between space-x-3 px-5 py-4 rounded-lg bg-white
        border border-gray-foreground"
    >
      <div className="w-full flex items-center space-x-2 truncate">
        {icon && (
          <div className="h-10 w-10 border rounded-lg flex items-center justify-center flex-none">
            {icon}
          </div>
        )}
        <input
          disabled={!selectedItem && !createNew}
          value={newTitle}
          onChange={(e) => setNewTitle(e.target.value)}
          onBlur={() => onUpdateTitle(newTitle)}
          className={`w-full h-10 outline-none text-base font-semibold rounded-lg
            ${inputWithBorder ? "border border-gray-foreground px-3" : ""}`}
        />
      </div>
      <button
        type="button"
        className="h-full w-5 flex items-center justify-center"
        onClick={onClick}
      >
        <ArrowIcon
          width="20"
          height="20"
          stroke="#121212"
          className={`flex-none transition-all ${clicked ? "-rotate-180" : ""}`}
        />
      </button>
    </div>
  );
};

export default DropdownIconButton;
