import teamIllustration from "../../../../assets/team-success.svg";
import Button from "../../../Basic/Button";
import Modal from "../../../Basic/Modal";

const SuccessModal = ({ onClose, teamName, leadName, numOfPatients }) => {
  return (
    <Modal className="pt-5 rounded-2xl">
      <div className="sm:w-128">
        <div className="flex flex-col items-center mb-10">
          <img src={teamIllustration} width="456" height="180" alt="Team" />
          <p className="text-xl font-semibold text-center mb-6 px-5">
            Successfully Created Team {teamName}!
          </p>
          <p className="text-sm px-5 text-center">
            {leadName}'s {numOfPatients} patients have been auto added
          </p>
        </div>
        <div className="flex justify-end font-semibold text-sm bg-gray-background py-4 px-5">
          <Button
            type="button"
            onClick={(e) => {
              e.preventDefault();
              onClose();
            }}
          >
            Okay
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default SuccessModal;
