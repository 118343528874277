import Skeleton from "@mui/material/Skeleton";
import { ReactComponent as DotsIcon } from "../../assets/icons/ellipsis-horizontal.svg";
import IndeterminateCheckbox from "../Patients/IndeterminateCheckbox";
import UserTable from "../Settings/Organization/UserTable";

const PatientTableSkeleton = ({
  rows = 15,
  bgColor = "#EAEDF3",
  animation = "wave",
}) => {
  const columns = [
    { label: <IndeterminateCheckbox />, accessor: "checkbox" },
    { label: "Preferred First Name", accessor: "preferred_name" },
    { label: "Last Name", accessor: "last_name" },
    { label: "Date of Birth", accessor: "birthdate" },
    { label: "Age", accessor: "age" },
    { label: "Sex", accessor: "sex" },
    { label: "Phone Number", accessor: "phone_number" },
    { label: "Care Coordination", accessor: "care_coordination" },
    { label: "", accessor: "actions" },
  ];

  const getTableData = () => {
    const tableData = [];
    for (let i = 0; i < rows; i++) {
      tableData.push({
        checkbox: <IndeterminateCheckbox />,
        preferred_name: (
          <Skeleton
            animation={animation}
            variant="rounded"
            width={80}
            height={16}
            sx={{ bgcolor: bgColor, borderRadius: "16px" }}
          />
        ),
        last_name: (
          <Skeleton
            animation={animation}
            variant="rounded"
            width={80}
            height={16}
            sx={{ bgcolor: bgColor, borderRadius: "16px" }}
          />
        ),
        birthdate: (
          <Skeleton
            animation={animation}
            variant="rounded"
            width={80}
            height={16}
            sx={{ bgcolor: bgColor, borderRadius: "16px" }}
          />
        ),
        age: (
          <Skeleton
            animation={animation}
            variant="rounded"
            width={34}
            height={16}
            sx={{ bgcolor: bgColor, borderRadius: "16px" }}
          />
        ),
        sex: (
          <Skeleton
            animation={animation}
            variant="rounded"
            width={34}
            height={16}
            sx={{ bgcolor: bgColor, borderRadius: "16px" }}
          />
        ),
        phone_number: (
          <Skeleton
            animation={animation}
            variant="rounded"
            width={120}
            height={16}
            sx={{ bgcolor: bgColor, borderRadius: "16px" }}
          />
        ),
        care_coordination: (
          <Skeleton
            animation={animation}
            variant="rounded"
            width={120}
            height={16}
            sx={{ bgcolor: bgColor, borderRadius: "16px" }}
          />
        ),
        actions: (
          <div className="flex items-center justify-center w-8 h-8 rounded-md">
            <DotsIcon width="20" height="20" />
          </div>
        ),
      });
    }
    return tableData;
  };

  return (
    <div className="grid w-full">
      <UserTable
        columns={columns}
        data={getTableData()}
        className="hidden md:block"
      />

      <div className="block md:hidden space-y-3">
        {[...Array(rows)].map((_, index) => (
          <div
            key={`patient-${index}`}
            className="p-4 rounded-xl border-b border-gray-foreground space-y-2 bg-white"
          >
            <div className="flex items-center justify-between mb-1">
              <Skeleton
                animation={animation}
                variant="rounded"
                width={120}
                height={20}
                sx={{ bgcolor: bgColor, borderRadius: "20px" }}
              />
              <div className="flex items-center justify-center w-8 h-8 rounded-md">
                <DotsIcon width="20" height="20" />
              </div>
            </div>
            <div className="flex items-center justify-between">
              <Skeleton
                animation={animation}
                variant="rounded"
                width={159}
                height={16}
                sx={{ bgcolor: bgColor, borderRadius: "16px" }}
              />
              <Skeleton
                animation={animation}
                variant="rounded"
                width={64}
                height={16}
                sx={{ bgcolor: bgColor, borderRadius: "16px" }}
              />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default PatientTableSkeleton;
