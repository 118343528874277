import { useState } from "react";
import { ReactComponent as CampaignIcon } from "../../../assets/icons/campaigns.svg";
import { ReactComponent as TimeIcon } from "../../../assets/icons/clock.svg";
import { ReactComponent as StatusIcon } from "../../../assets/icons/status.svg";
import {
  categoryOptions,
  timeOptions,
  typeOptions,
} from "../../../helpers/constants";
import FilterDropdownButton from "./FilterDropdownButton";
import FilterFlags from "./FilterFlags";
import FilterOther from "./FilterOther";

const FilterDropdownButtons = ({ className }) => {
  const [openedDropdown, setOpenedDropdown] = useState("");

  return (
    <div className={`flex w-full justify-between relative z-10 ${className}`}>
      <FilterDropdownButton
        filterName="category"
        icon={<StatusIcon width="20" height="20" fill="#3C3C47" />}
        arrow
        options={categoryOptions}
        openedDropdown={openedDropdown}
        setOpenedDropdown={setOpenedDropdown}
        multipleSelect={true}
      />
      <FilterDropdownButton
        filterName="type"
        icon={<CampaignIcon width="25" height="20" stroke="#3C3C47" />}
        arrow
        options={typeOptions}
        openedDropdown={openedDropdown}
        setOpenedDropdown={setOpenedDropdown}
        multipleSelect={true}
      />

      <FilterDropdownButton
        filterName="time"
        icon={<TimeIcon width="20" height="20" stroke="#3C3C47" />}
        arrow
        options={timeOptions}
        openedDropdown={openedDropdown}
        setOpenedDropdown={setOpenedDropdown}
      />
      <FilterFlags
        filterName="flag"
        arrow
        openedDropdown={openedDropdown}
        setOpenedDropdown={setOpenedDropdown}
      />
      <FilterOther
        arrow
        openedDropdown={openedDropdown}
        setOpenedDropdown={setOpenedDropdown}
      />
    </div>
  );
};

export default FilterDropdownButtons;
