import { z } from "zod";
import {
  doctorSchema,
  zeroOrOneSchema,
} from "../../helpers/commonValidationSchemas";

const organizationSchema = z.object({
  deleted_at: z.string().nullable(),
  name: z.string(),
  organization_id: z.number(),
});

const userFacilitySchema = z.object({
  assistant_id: z.string().nullable(),
  assistant_phone_number: z.string().nullable(),
  clinic_address: z.string().nullable(),
  created_at: z.string(),
  custom_instructions: z.string().nullable(),
  customer_id: z.number(),
  display_name: z.string().nullable(),
  incentive_program_enabled: zeroOrOneSchema,
  incentive_program_points: zeroOrOneSchema,
  legacy_phone_number: z.string().nullable(),
  name: z.string(),
  note_template_id: z.number().nullable(),
  organization_id: z.number().nullable(),
  phone_number: z.string(),
  time_to_move_complete: z.number(),
  time_to_move_review: z.number(),
  updated_at: z.string(),
});

export const userFacilitiesSchema = z.array(userFacilitySchema);

const facilitySchema = userFacilitySchema.extend({
  organization: organizationSchema.nullable(),
  providers: z.array(doctorSchema),
  total_patients: z.number().min(0),
  total_providers: z.number().min(0),
  total_teams: z.number().min(0),
});

export const facilitiesSchema = z.array(facilitySchema);
