import {
  LOGOUT,
  SET_LOADING,
  SET_METRICS,
  SET_METRICS_CAMPAIGNS,
  SET_METRICS_CUSTOMERS,
  SET_METRICS_PASSWORD,
  SET_METRICS_PASSWORD_ERROR,
} from "../types";

const init = {
  metricsPassword: "",
  passwordError: "",
  customers: null,
  campaigns: null,
  metrics: null,
  loading: false,
};

export default function metricsReducer(state = init, action) {
  const { type, payload } = action;

  switch (type) {
    case LOGOUT:
      return { ...init };
    case SET_METRICS_PASSWORD:
      return { ...state, metricsPassword: payload.metricsPassword };
    case SET_METRICS_PASSWORD_ERROR:
      return { ...state, passwordError: payload.passwordError };
    case SET_METRICS_CUSTOMERS:
      return { ...state, customers: payload.customers };
    case SET_METRICS_CAMPAIGNS:
      return { ...state, campaigns: payload.campaigns };
    case SET_METRICS:
      return { ...state, metrics: payload.metrics };
    case SET_LOADING:
      return { ...state, loading: payload.loading };
    default:
      return state;
  }
}
