import LocalStorageService from "../../services/LocalStorageService";
import {
  LOGOUT,
  SET_ALL_NOTES_GENERATED,
  SET_BILLING_LOADING,
  SET_BILLING_NUMBERS,
  SET_BILLING_PATIENTS,
  SET_BILLING_SEARCH_VALUE,
  SET_BILLING_TABLE_SCROLL_POSITION,
  SET_CURRENT_BILLING_PATIENT,
  SET_CURRENT_DATE_STAMP,
  SET_CURR_BILLING_PAGE,
  SET_EXPORT_LOADING,
  SET_NUM_BILLING_PATIENTS,
  SET_SELECTED_BILLING_TYPE,
  SET_SELECTED_CPT_CODE,
  SET_SORTED_BY,
  SET_SORTING_DIRECTION,
  SET_TIME_ADJUST_LOADING,
} from "../types";

const storageService = new LocalStorageService();

const init = {
  billingPatients: null,
  billingNumbers: null,
  numberOfFilteredPatients: 0,
  currentBillingPatient: null,
  currentDateStamp: storageService.getItem("trackingDateStamp") ?? null,
  selectedBillingType: storageService.getItem("trackingBillingType") ?? "all",
  selectedCptCode: storageService.getItem("trackingCptCode") ?? "all",
  sortedBy: storageService.getItem("trackingSortedBy") ?? null,
  sortingDirection: storageService.getItem("sortingDirection") ?? null,
  billingSearchValue: storageService.getItem("billingSearchValue") ?? "",
  currPage: 1,
  loading: false,
  timeAdjustLoading: false,
  exportLoading: false,
  tableScrollPosition: 0,
  allNotesGenerated: false,
};

export default function billingMetricsReducer(state = init, action) {
  const { type, payload } = action;

  switch (type) {
    case LOGOUT:
      return { ...init };
    case SET_BILLING_PATIENTS:
      return { ...state, billingPatients: payload.billingPatients };
    case SET_NUM_BILLING_PATIENTS:
      return {
        ...state,
        numberOfFilteredPatients: payload.numberOfFilteredPatients,
      };
    case SET_CURRENT_BILLING_PATIENT:
      return { ...state, currentBillingPatient: payload.currentBillingPatient };
    case SET_CURRENT_DATE_STAMP:
      storageService.setItem("trackingDateStamp", payload.currentDateStamp);
      return { ...state, currentDateStamp: payload.currentDateStamp };
    case SET_SELECTED_BILLING_TYPE:
      storageService.setItem(
        "trackingBillingType",
        payload.selectedBillingType,
      );
      return { ...state, selectedBillingType: payload.selectedBillingType };
    case SET_SELECTED_CPT_CODE:
      storageService.setItem("trackingCptCode", payload.selectedCptCode);
      return { ...state, selectedCptCode: payload.selectedCptCode };
    case SET_SORTED_BY:
      storageService.setItem("trackingSortedBy", payload.sortedBy);
      return { ...state, sortedBy: payload.sortedBy };
    case SET_SORTING_DIRECTION:
      storageService.setItem("sortingDirection", payload.sortingDirection);
      return { ...state, sortingDirection: payload.sortingDirection };
    case SET_BILLING_SEARCH_VALUE:
      storageService.setItem("billingSearchValue", payload.billingSearchValue);
      return { ...state, billingSearchValue: payload.billingSearchValue };
    case SET_BILLING_LOADING:
      return { ...state, loading: payload.loading };
    case SET_TIME_ADJUST_LOADING:
      return { ...state, timeAdjustLoading: payload.timeAdjustLoading };
    case SET_EXPORT_LOADING:
      return { ...state, exportLoading: payload.exportLoading };
    case SET_BILLING_TABLE_SCROLL_POSITION:
      return { ...state, tableScrollPosition: payload.tableScrollPosition };
    case SET_CURR_BILLING_PAGE:
      return { ...state, currPage: payload.currPage };
    case SET_BILLING_NUMBERS:
      return { ...state, billingNumbers: payload.billingNumbers };
    case SET_ALL_NOTES_GENERATED:
      return { ...state, allNotesGenerated: payload.allNotesGenerated };
    default:
      return state;
  }
}
