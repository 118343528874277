import { useEffect } from "react";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import Notifications, { NotificationContent } from "../Basic/Notifications";

const NotificationsWrapper = ({ children }) => {
  const { notification } = useSelector((state) => state.user);
  const location = useLocation();

  useEffect(() => {
    if (notification) {
      if (notification.status === "error") {
        toast.error((t) => (
          <NotificationContent
            id={t.id}
            title={notification.title}
            text={notification.desc}
          />
        ));
      } else {
        toast.success((t) => (
          <NotificationContent
            id={t.id}
            title={notification.title}
            text={notification.desc}
          />
        ));
      }
    }
  }, [notification]);

  useEffect(() => {
    if (location.pathname) {
      toast.remove();
    }
  }, [location.pathname]);

  return (
    <div id="wrapper" className="font-Mulish font-medium text-primary">
      {children}
      <Notifications />
    </div>
  );
};

export default NotificationsWrapper;
