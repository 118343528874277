import { useEffect } from "react";
import { useAppDispatch } from "../store";
import { setNotificationAction } from "../store/user/actions";

export const useNoSoundNotification = (
  isReadyToRecord,
  isStalled,
  selectedScribe,
  requestMicrophonePermissions,
) => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!isReadyToRecord && !isStalled && selectedScribe?.isRecording) {
      dispatch(
        setNotificationAction({
          status: "error",
          title: "No Voice Detected",
          desc: "Check Microphone Settings",
        }),
      );

      requestMicrophonePermissions();
    }
  }, [
    dispatch,
    isReadyToRecord,
    selectedScribe,
    isStalled,
    requestMicrophonePermissions,
  ]);
};
