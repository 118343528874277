import Bugsnag from "@bugsnag/js";
import axios from "axios";
import { handleValidation } from "../../helpers/commonValidationSchemas";
import { BASE_URL } from "../../helpers/config";
import { campaignTypes } from "../../helpers/constants";
import { setPatientLoaderAction } from "../patient/actions";
import { setNotificationAction } from "../user/actions";
import {
  setCampaignLoaderAction,
  setCampaignTagsAction,
  setCampaignsAction,
  setSelectedEnrollCampaignAction,
  setSmartEnrollLoadingAction,
  setUpcomingMessagesAction,
} from "./actions";
import {
  campaignSchema,
  campaignsSchema,
  nextCheckinsSchema,
  smartEnrollDataSchema,
} from "./validationSchemas";

export const getCampaigns =
  (onEnd = () => {}) =>
  (dispatch) => {
    dispatch(setCampaignLoaderAction(true));
    axios.get(`${BASE_URL}/campaigns/list`).then((response) => {
      if (response.data.error) {
        dispatch(
          setNotificationAction({
            status: "error",
            title: "Something went wrong",
            desc: "Failed to load campaigns",
          }),
        );
        Bugsnag.notify(response.data.error);
      } else {
        const campaigns = response.data.data.campaigns;

        handleValidation(campaignsSchema, campaigns, "getCampaigns");

        dispatch(setCampaignsAction(campaigns));

        const campaignTags = [];
        campaigns.forEach((campaign) => {
          const type = campaignTypes.find(
            (type) => type.value === campaign.campaign_type,
          );
          if (type) {
            campaignTags.push({
              value: campaign.campaign_type,
              text: type.text,
            });
          }
        });
        dispatch(setCampaignTagsAction(campaignTags));
        onEnd(campaigns);
      }
      dispatch(setCampaignLoaderAction(false));
    });
  };

export const getCampaign = (campaignId) => (dispatch) => {
  axios.get(`${BASE_URL}/campaigns/describe/${campaignId}`).then((response) => {
    if (response.data.error) {
      dispatch(
        setNotificationAction({
          status: "error",
          title: "Something went wrong",
          desc: "Failed to load campaign",
        }),
      );
      Bugsnag.notify(response.data.error);
    } else {
      const campaign = response.data.data.campaign;

      handleValidation(campaignSchema, campaign, "getCampaign");

      dispatch(setSelectedEnrollCampaignAction(campaign));
    }
  });
};

export const getUpcomingMessages = (campaignIds) => (dispatch) => {
  axios
    .post(`${BASE_URL}/campaigns/next-checkins`, {
      campaign_ids: campaignIds,
    })
    .then((response) => {
      if (response.data.error) {
        dispatch(
          setNotificationAction({
            status: "error",
            title: "Something went wrong",
            desc: "Failed to load upcoming messages",
          }),
        );
        Bugsnag.notify(response.data.error);
      } else {
        const nextCheckins = response.data.data.next_checkins;

        handleValidation(
          nextCheckinsSchema,
          nextCheckins,
          "getUpcomingMessages",
        );

        dispatch(setUpcomingMessagesAction(nextCheckins));
      }
    });
};

export const updateCampaignDetails =
  (
    campaignId,
    name,
    description,
    firstMessage,
    checkinMessage,
    lastMessage,
    breakMessage,
    onEnd = () => {},
  ) =>
  (dispatch) => {
    dispatch(setCampaignLoaderAction(true));
    axios
      .post(`${BASE_URL}/campaigns/update/${campaignId}`, {
        campaign: {
          campaign: name,
          description: description,
          first_message: firstMessage,
          checkin_message: checkinMessage,
          last_message: lastMessage,
          break_message: breakMessage,
        },
      })
      .then((response) => {
        if (response.data.error) {
          dispatch(
            setNotificationAction({
              status: "error",
              title: "Something went wrong",
              desc: "Failed to update campaign",
            }),
          );
          Bugsnag.notify(response.data.error);
        } else {
          dispatch(getCampaigns());
          dispatch(
            setNotificationAction({
              status: "success",
              title: "Successfully updated campaign",
              desc: "Campaign has been successfully updated",
            }),
          );
        }
        dispatch(setCampaignLoaderAction(true));
        onEnd();
      });
  };

export const updateCampaignControls =
  (
    campaignId,
    minQuestions,
    maxQuestions,
    defaultStart,
    defaultFrequency,
    reminderActive,
    reminderMessage,
    reminderTimes,
    firstFollowup,
    onEnd = () => {},
  ) =>
  (dispatch) => {
    dispatch(setCampaignLoaderAction(true));
    axios
      .post(`${BASE_URL}/campaigns/update/${campaignId}`, {
        campaign: {
          min_questions: minQuestions,
          max_questions: maxQuestions,
          default_time_to_send: defaultStart,
          default_frequency: defaultFrequency,
          reminder_active: reminderActive,
          reminder_message: reminderMessage,
          reminder_times: reminderTimes,
          first_followup: firstFollowup,
        },
      })
      .then((response) => {
        if (response.data.error) {
          dispatch(
            setNotificationAction({
              status: "error",
              title: "Something went wrong",
              desc: "Failed to update campaign",
            }),
          );
          Bugsnag.notify(response.data.error);
        } else {
          dispatch(getCampaigns());
          dispatch(
            setNotificationAction({
              status: "success",
              title: "Successfully updated campaign",
              desc: "Campaign has been successfully updated",
            }),
          );
        }
        dispatch(setCampaignLoaderAction(true));
        onEnd();
      });
  };

export const updateCampaign =
  (
    campaignId,
    patientIds,
    outreach_focus,
    outreach_goals,
    ehr_information,
    reschedule = false,
    onSuccess = () => {},
  ) =>
  (dispatch) => {
    dispatch(setPatientLoaderAction(true));
    axios
      .post(`${BASE_URL}/campaigns/schedule/${campaignId}`, {
        patient_ids: patientIds,
        outreach_focus: outreach_focus,
        outreach_goals: outreach_goals,
        ehr_information: ehr_information,
        reschedule: reschedule,
      })
      .then((response) => {
        if (response.data.error) {
          dispatch(
            setNotificationAction({
              status: "error",
              title: "Something went wrong",
              desc: "Failed to update campaign",
            }),
          );
          Bugsnag.notify(response.data.error);
        } else {
          onSuccess();
          dispatch(
            setNotificationAction({
              status: "success",
              title: "Successfully updated campaign",
              desc: "Campaign has been successfully updated for selected patients",
            }),
          );
        }
        dispatch(setPatientLoaderAction(false));
      });
  };

export const scheduleCampaign =
  (
    campaignId,
    patientIds,
    start,
    frequency,
    outreach_focus,
    outreach_goals,
    ehr_information,
    doctor_id,
    consent = true,
    reschedule = false,
    onSuccess = () => {},
    showSuccessNotification = true,
  ) =>
  (dispatch) => {
    dispatch(setPatientLoaderAction(true));
    axios
      .post(`${BASE_URL}/campaigns/schedule/${campaignId}`, {
        patient_ids: patientIds,
        start: start,
        frequency: frequency,
        outreach_focus: outreach_focus,
        outreach_goals: outreach_goals,
        ehr_information: ehr_information,
        reschedule: reschedule,
        doctor_id: doctor_id,
        consent: consent,
      })
      .then((response) => {
        if (response.data.error) {
          dispatch(
            setNotificationAction({
              status: "error",
              title: "Something went wrong",
              desc: "Failed to schedule campaign",
            }),
          );
          Bugsnag.notify(response.data.error);
        } else {
          onSuccess();
          if (showSuccessNotification) {
            dispatch(
              setNotificationAction({
                status: "success",
                title: reschedule
                  ? "Successfully updated campaign"
                  : "Successfully scheduled campaign",
                desc: reschedule
                  ? "Campaign has been successfully updated for selected patient"
                  : "Campaign has been successfully scheduled for selected patient",
              }),
            );
          }
        }
        dispatch(setPatientLoaderAction(false));
      });
  };

export const cancelUpcomingMessage =
  (
    campaignId,
    campaignPatientId,
    patientId,
    onSuccess = () => {},
    showSuccessNotification = true,
  ) =>
  (dispatch) => {
    axios
      .post(`${BASE_URL}/campaigns/cancel-checkin`, {
        campaign_id: campaignId,
        campaign_patient_id: campaignPatientId,
        patient_id: patientId,
      })
      .then((response) => {
        if (response.data.error) {
          dispatch(
            setNotificationAction({
              status: "error",
              title: "Something went wrong",
              desc: "Failed to cancel upcoming message",
            }),
          );
          Bugsnag.notify(response.data.error);
        } else {
          if (showSuccessNotification) {
            dispatch(
              setNotificationAction({
                status: "success",
                title: "Successfully cancelled upcoming message",
                desc: "Upcoming message has been successfully cancelled",
              }),
            );
          }
          onSuccess();
        }
      });
  };

export const campaignSmartEnroll =
  (patientId, campaignId, onSuccess = () => {}) =>
  (dispatch) => {
    dispatch(setSmartEnrollLoadingAction(true));
    axios
      .get(`${BASE_URL}/campaigns/smart-enroll/${patientId}/${campaignId}`)
      .then((response) => {
        if (response.data.error) {
          dispatch(
            setNotificationAction({
              status: "error",
              title: "Something went wrong",
              desc: "Failed to smart enroll patient",
            }),
          );
          Bugsnag.notify(response.data.error);
        } else {
          const smartEnrollData = response.data;

          handleValidation(
            smartEnrollDataSchema,
            smartEnrollData,
            "campaignSmartEnroll",
          );

          onSuccess(smartEnrollData);
        }
      })
      .catch((error) => {
        console.error(error);
        dispatch(
          setNotificationAction({
            status: "error",
            title: "Something went wrong",
            desc: "Failed to smart enroll patient",
          }),
        );
      })
      .finally(() => {
        dispatch(setSmartEnrollLoadingAction(false));
      });
  };
