import { useEffect, useRef, useState } from "react";

interface AudioLevelMetrics {
  audioVolume: number; // Current audio level (normalized between 0 and 1)
  noAudioDetected: boolean; // True if no audio is detected
}

interface UseAudioLevelOptions {
  audioContext: AudioContext;
  mediaStream: MediaStream;
  historyLength?: number; // Number of samples to keep in history for smoothing
  noAudioThreshold?: number; // Threshold below which audio is considered missing
}

const useAudioLevel = ({
  audioContext,
  mediaStream,
  historyLength = 500, // Default history length
  // noAudioThreshold = 0.0001, // Default threshold for no audio detection
}: UseAudioLevelOptions): AudioLevelMetrics => {
  const [instant, setInstant] = useState(0);
  const [noAudioDetected, setNoAudioDetected] = useState(false);
  const analyserRef = useRef<AnalyserNode | null>(null);
  const dataArrayRef = useRef<Uint8Array | null>(null);
  const volumeHistoryRef = useRef<number[]>([]);
  const rafIdRef = useRef<number | null>(null);

  useEffect(() => {
    if (!audioContext || !mediaStream) {
      return;
    }

    const analyser = audioContext.createAnalyser();
    analyser.fftSize = 2048;
    const bufferLength = analyser.frequencyBinCount;
    const dataArray = new Uint8Array(bufferLength);

    const source = audioContext.createMediaStreamSource(mediaStream);
    source.connect(analyser);

    analyserRef.current = analyser;
    dataArrayRef.current = dataArray;

    const updateAudioLevel = () => {
      if (!analyserRef.current || !dataArrayRef.current) {
        return;
      }

      analyserRef.current.getByteTimeDomainData(dataArrayRef.current);

      // Calculate the average amplitude (volume)
      let sum = 0;
      dataArrayRef.current.forEach((value) => {
        sum += Math.abs(value - 128); // Center the waveform around zero
      });
      const average = sum / dataArrayRef.current.length;

      // Normalize the average to a value between 0 and 1
      const normalizedAverage = average / 128;

      // Update the instant audio level
      setInstant(normalizedAverage);

      // Update volume history
      volumeHistoryRef.current.push(normalizedAverage);
      if (volumeHistoryRef.current.length > historyLength) {
        volumeHistoryRef.current.shift();
      }

      // Compute average volume over history
      const averageVolume =
        volumeHistoryRef.current.reduce((sum, vol) => sum + vol, 0) /
        volumeHistoryRef.current.length;

      // Detect if no audio is present
      if (
        averageVolume === 0 &&
        volumeHistoryRef.current.length === historyLength
      ) {
        setNoAudioDetected(true);
      } else {
        setNoAudioDetected(false);
      }

      // Schedule the next update
      rafIdRef.current = requestAnimationFrame(updateAudioLevel);
    };

    // Start the audio level updates
    rafIdRef.current = requestAnimationFrame(updateAudioLevel);

    // Cleanup function
    return () => {
      if (rafIdRef.current !== null) {
        cancelAnimationFrame(rafIdRef.current);
      }
      source.disconnect();
      analyser.disconnect();
    };
  }, [audioContext, mediaStream, historyLength]);

  return {
    audioVolume: instant,
    noAudioDetected,
  };
};

export default useAudioLevel;
