import { useCallback, useEffect, useRef, useState } from "react";
import { ReactComponent as CopyIcon } from "../../../assets/icons/copy-check.svg";
import { copyText } from "../../../helpers/helpers";
import { useAppDispatch, useAppSelector } from "../../../store";
import { saveEncounterNoteSimple } from "../../../store/conversation/thunks";
import { setNotificationAction } from "../../../store/user/actions";
import TextEditor from "../../Basic/TextEditor";
import { setScribe } from "../store/scribeSlice";
import { getSelectedScribe } from "../store/selectors";

const labelStyle = {
  default: "text-primary",
  oldRecord: "text-tertiary",
};

const textAreaStyle = {
  default: "text-tertiary",
  oldRecord: "text-primary",
};

const ScribeNote = ({ originalNote, variant = "default" }) => {
  const selectedScribe = useAppSelector(getSelectedScribe);

  const noteRef = useRef(originalNote);
  const [focused, setFocused] = useState(false);
  const dispatch = useAppDispatch();

  useEffect(() => {
    noteRef.current = originalNote;
  }, [originalNote]);

  const handleChange = useCallback((value) => {
    noteRef.current = value;
  }, []);

  const handleBlur = useCallback(() => {
    setFocused(false);

    if (!selectedScribe?.noteId) {
      return;
    }

    dispatch(
      setScribe({
        audioId: selectedScribe.audioId,
        scribe: {
          note: noteRef.current,
          isEditing: false,
        },
      }),
    );

    dispatch(saveEncounterNoteSimple(selectedScribe?.audioId, noteRef.current));
  }, [dispatch, selectedScribe?.audioId, selectedScribe?.noteId]);

  const handleFocus = () => {
    setFocused(true);

    dispatch(
      setScribe({
        audioId: selectedScribe.audioId,
        scribe: {
          isEditing: true,
        },
      }),
    );
  };

  const handleCopyNote = () => {
    copyText(noteRef.current, () =>
      dispatch(
        setNotificationAction({
          status: "success",
          title: "Successfully copied note content",
          desc: "Note content has been successfully copied to the clipboard",
        }),
      ),
    );
  };

  return (
    <>
      <div className="flex items-center space-x-2">
        <p
          className={`${labelStyle[variant]} text-sm font-semibold tracking-wider`}
          data-testid="clinical-note"
        >
          CLINICAL NOTE
        </p>
        <button type="button" aria-label="Copy note" onClick={handleCopyNote}>
          <CopyIcon width="24" height="24" />
        </button>
      </div>
      <TextEditor
        htmlMarkup={originalNote}
        onChange={handleChange}
        onBlur={handleBlur}
        onFocus={handleFocus}
        disabled={false}
        hideToolbar
        className={`mt-2 mb-6 border rounded-lg text-sm
          ${focused ? "border-primary-blue" : "border-gray-300"}
          ${textAreaStyle[variant]}`}
      />
    </>
  );
};

export default ScribeNote;
