import Bugsnag, { type NotifiableError } from "@bugsnag/js";
import axios from "axios";
import { handleValidation } from "../../helpers/commonValidationSchemas";
import { BASE_URL, fetchWithAuth } from "../../helpers/config";
import { inboxes } from "../../helpers/constants";
import { setNotificationAction } from "../user/actions";
import {
  addFailedMessageAction,
  addSentMessageAction,
  removeSentMessageAction,
  setConversationAction,
  setConversationsAction,
  setConversationsCompletedAction,
  setConversationsCountsAction,
  setConversationsReviewAction,
  setLoaderAction,
  setNumOfConvByDoctorIdAction,
} from "./actions";
import {
  conversationCountsSchema,
  conversationSchema,
  conversationsSchema,
} from "./validationSchemas";

export const getConversation =
  (id, onSuccess = () => {}) =>
  (dispatch) => {
    axios.get(`${BASE_URL}/conversation/${id}`).then((response) => {
      if (response.data.error) {
        dispatch(
          setNotificationAction({
            status: "error",
            title: "Something went wrong",
            desc: "Failed to load conversation",
          }),
        );
        Bugsnag.notify(response.data.error);
      } else {
        onSuccess();
        dispatch(removeSentMessageAction(id));

        const conversation = response.data.data.conversation;

        handleValidation(conversationSchema, conversation, "getConversation");

        dispatch(setConversationAction(conversation));
      }
    });
  };

const splitCampaignIdsAndCommon = (campaigns) => {
  return campaigns.reduce(
    (result, value) => {
      if (typeof value === "number" && !Number.isNaN(value)) {
        result.campaignIds.push(value);
      } else if (typeof value === "string") {
        result.commonCampaigns.push(value);
      }
      return result;
    },
    { campaignIds: [], commonCampaigns: [] },
  );
};

export const searchConversationsWithFilters =
  (searchInput, filters, page, rows, inboxType, doctor, onSuccess = () => {}) =>
  (dispatch) => {
    const { campaignIds, commonCampaigns } = splitCampaignIdsAndCommon(
      filters?.campaigns ? filters.campaigns : [],
    );
    const statuses = filters?.status?.[inboxType]
      ? filters.status[inboxType]
      : [];
    const categories = filters?.category ? filters.category : [];
    const types = filters?.type ? filters.type : [];
    const flags = filters?.other?.flag ? filters.other.flag : [];
    const isUnread = filters?.other?.unread ? true : null;
    const isStaffEngaging = filters?.other?.staffEngaging ? true : null;
    const from =
      filters?.time && filters.time !== "all" ? filters.time - 1 : null;

    const doctorId = doctor
      ? doctor !== "unassigned"
        ? doctor.doctor_id
        : null
      : null;
    const unassigned = doctor === "unassigned";

    const clinicAll = doctor === "clinic_all";

    axios
      .post(`${BASE_URL}/conversation/search`, {
        q: searchInput,
        inbox: inboxType,
        doctor_id: doctorId,
        campaignIds: campaignIds,
        commonCampaigns: commonCampaigns,
        statuses: [...statuses, ...categories, ...types],
        flags: flags,
        isUnread: isUnread,
        staffEngaging: isStaffEngaging,
        from: from,
        to: 0,
        offset: (page - 1) * rows,
        limit: rows,
        unassigned: unassigned,
        clinic_all: clinicAll,
      })
      .then((response) => {
        if (response.data.error) {
          dispatch(
            setNotificationAction({
              status: "error",
              title: "Something went wrong",
              desc: "Failed to load conversations",
            }),
          );
          Bugsnag.notify(response.data.error);
        } else {
          const conversations = response.data.data;

          handleValidation(
            conversationsSchema,
            conversations,
            "searchConversationsWithFilters",
          );

          switch (inboxType) {
            case inboxes.ACTIVE:
              dispatch(setConversationsAction(response.data.data));
              break;
            case inboxes.REVIEW:
              dispatch(setConversationsReviewAction(response.data.data));
              break;
            case inboxes.COMPLETE:
              dispatch(setConversationsCompletedAction(response.data.data));
              break;
            default:
          }
          onSuccess();
        }
        dispatch(
          getConversationCounts(
            from,
            inboxType,
            campaignIds,
            commonCampaigns,
            [...statuses, ...categories, ...types],
            flags,
            isUnread,
            clinicAll ? "clinic_all" : doctorId,
            unassigned,
            undefined,
          ),
        );
      });
  };

export const getConversationCounts =
  (
    from,
    inboxType,
    campaignIds,
    commonCampaigns,
    statuses,
    flags,
    isUnread,
    doctorId,
    unassigned,
    clinicAll,
  ) =>
  async (dispatch) => {
    try {
      const data = await fetchWithAuth(`${BASE_URL}/conversation/count`, {
        method: "POST",
        body: JSON.stringify({
          from: from,
          to: 0,
          inbox: inboxType,
          campaignIds: campaignIds,
          commonCampaigns: commonCampaigns,
          statuses: statuses,
          flags: flags,
          isUnread: isUnread,
          doctor_id: doctorId,
          unassigned: unassigned,
          clinic_all: clinicAll,
        }),
      });

      if (data.error) {
        dispatch(
          setNotificationAction({
            status: "error",
            title: "Something went wrong",
            desc: "Failed to load counts",
          }),
        );
        Bugsnag.notify(data.error);
      } else {
        const counts = data.data;

        handleValidation(
          conversationCountsSchema,
          counts,
          "getConversationCounts",
        );

        dispatch(setConversationsCountsAction(inboxType, counts));
        dispatch(getConversationCountByDoctor(doctorId, unassigned));
      }
    } catch (error) {
      console.error("Failed to load conversation counts:", error);
      dispatch(
        setNotificationAction({
          status: "error",
          title: "Something went wrong",
          desc: "Failed to load counts",
        }),
      );
      Bugsnag.notify(error);
    } finally {
      dispatch(setLoaderAction(false));
    }
  };

export const getConversationCountByDoctor =
  (doctor, unassigned) => (dispatch) => {
    const doctorId = doctor
      ? doctor !== "unassigned"
        ? doctor.doctor_id
        : null
      : null;
    const unassignedDoctor = doctor === "unassigned" || unassigned;

    const clinicAll = doctor === "clinic_all";

    axios
      .post(`${BASE_URL}/conversation/count`, {
        from: null,
        to: 0,
        inbox: "active",
        campaignIds: [],
        commonCampaigns: [],
        statuses: [],
        flags: [],
        isUnread: null,
        doctor_id: doctorId,
        unassigned: unassignedDoctor,
        clinic_all: clinicAll,
      })
      .then((response) => {
        if (response.data.error) {
          dispatch(
            setNotificationAction({
              status: "error",
              title: "Something went wrong",
              desc: "Failed to load counts",
            }),
          );
          Bugsnag.notify(response.data.error);
        } else {
          const counts = response.data.data;

          handleValidation(
            conversationCountsSchema,
            counts,
            "getConversationCountByDoctor",
          );

          dispatch(
            setNumOfConvByDoctorIdAction({
              [`${
                clinicAll
                  ? "clinic_all"
                  : unassignedDoctor
                    ? "unassigned"
                    : doctorId
                      ? doctorId
                      : "all"
              }`]:
                Number(counts["active TOTAL"]) + Number(counts["review TOTAL"]),
            }),
          );
        }
      })
      .finally(() => {
        dispatch(setLoaderAction(false));
      });
  };

export const completeConversation =
  (
    conversationId,
    conversationSearchInput,
    activeFilters,
    page,
    rows,
    inboxType,
    doctor,
  ) =>
  (dispatch) => {
    axios
      .post(`${BASE_URL}/conversation/${conversationId}/complete`)
      .then((response) => {
        if (response.data.error) {
          dispatch(
            setNotificationAction({
              status: "error",
              title: "Conversation is not completed",
              desc: "Failed to complete conversation",
            }),
          );
          Bugsnag.notify(response.data.error);
        } else {
          dispatch(
            searchConversationsWithFilters(
              conversationSearchInput,
              activeFilters,
              page,
              rows,
              inboxType,
              doctor,
            ),
          );
          dispatch(
            searchConversationsWithFilters(
              conversationSearchInput,
              activeFilters,
              page,
              rows,
              inboxes.COMPLETE,
              doctor,
            ),
          );
        }
      });
  };

export const markAsUnread =
  (
    conversationId,
    conversationSearchInput,
    activeFilters,
    page,
    rows,
    inboxType,
    doctor,
  ) =>
  (dispatch) => {
    axios
      .post(`${BASE_URL}/conversation/mark-as-unread`, {
        conversation_id: conversationId,
      })
      .then((response) => {
        if (response.data.error) {
          dispatch(
            setNotificationAction({
              status: "error",
              title: "Something went wrong",
              desc: "Failed to mark conversation as unread",
            }),
          );
          Bugsnag.notify(response.data.error);
        } else {
          dispatch(
            searchConversationsWithFilters(
              conversationSearchInput,
              activeFilters,
              page,
              rows,
              inboxType,
              doctor,
            ),
          );
        }
      });
  };

export const changeFlag =
  (
    conversationId,
    flag,
    conversationSearchInput,
    activeFilters,
    page,
    rows,
    inboxType,
    doctor,
  ) =>
  (dispatch) => {
    axios
      .post(`${BASE_URL}/conversation/change-flag`, {
        conversation_id: conversationId,
        flag: flag,
      })
      .then((response) => {
        if (response.data.error) {
          dispatch(
            setNotificationAction({
              status: "error",
              title: "Something went wrong",
              desc: "Failed to change conversation flag",
            }),
          );
          Bugsnag.notify(response.data.error);
        } else {
          dispatch(
            searchConversationsWithFilters(
              conversationSearchInput,
              activeFilters,
              page,
              rows,
              inboxType,
              doctor,
            ),
          );
        }
      });
  };

export const suggestAnswer = (id) => (dispatch) => {
  axios
    .get(`${BASE_URL}/conversation/${id}/suggested-answer`)
    .then((response) => {
      if (response.data.error) {
        dispatch(
          setNotificationAction({
            status: "error",
            title: "Something went wrong",
            desc: "Failed to load suggested answer",
          }),
        );
        Bugsnag.notify(response.data.error);
      }
      dispatch(getConversation(id));
    });
};

export const sendMessage =
  (
    message,
    conversationId,
    conversationSearchInput,
    activeFilters,
    page,
    rows,
    inboxType,
    doctor,
  ) =>
  (dispatch) => {
    dispatch(addSentMessageAction(message, conversationId));
    axios
      .post(`${BASE_URL}/message/send`, {
        conversation_id: conversationId,
        message: message,
      })
      .then((response) => {
        dispatch(
          searchConversationsWithFilters(
            conversationSearchInput,
            activeFilters,
            page,
            rows,
            inboxType,
            doctor,
          ),
        );
        if (response.data.error) {
          dispatch(
            getConversation(conversationId, () =>
              dispatch(addFailedMessageAction(message, conversationId)),
            ),
          );
          dispatch(
            setNotificationAction({
              status: "error",
              title: "Something went wrong",
              desc: "Failed to send message",
            }),
          );
          Bugsnag.notify(response.data.error);
        } else {
          dispatch(getConversation(conversationId));
        }
      })
      .catch((error) => {
        dispatch(
          getConversation(conversationId, () =>
            dispatch(addFailedMessageAction(message, conversationId)),
          ),
        );
        dispatch(
          setNotificationAction({
            status: "error",
            title: "Something went wrong",
            desc: "Failed to send message",
          }),
        );
        Bugsnag.notify(<NotifiableError>error);
      });
  };

export const removeDiagnoses = (diagnoses, conversationId) => (dispatch) => {
  axios
    .post(`${BASE_URL}/conversation/remove-diagnoses`, {
      conversation_id: conversationId,
      diagnoses: diagnoses,
    })
    .then((response) => {
      if (response.data.error) {
        dispatch(
          setNotificationAction({
            status: "error",
            title: "Diagnoses are not removed",
            desc: "Failed to remove diagnoses",
          }),
        );
        Bugsnag.notify(response.data.error);
      } else {
        dispatch(
          setNotificationAction({
            status: "success",
            title: "Successfully removed diagnosis",
            desc: "Diagnosis was successfully removed",
          }),
        );
        dispatch(getConversation(conversationId));
      }
    });
};

export const saveEncounterNote =
  (audioId, note, onSuccess = () => {}) =>
  (dispatch) => {
    axios
      .post(`${BASE_URL}/encounter/${audioId}/save-note`, {
        note: note,
      })
      .then((response) => {
        if (response.data.error) {
          dispatch(
            setNotificationAction({
              status: "error",
              title: "Something went wrong",
              desc: "Failed to save encounter note",
            }),
          );
          Bugsnag.notify(response.data.error);
        } else {
          onSuccess();
        }
      });
  };

export const saveEncounterNoteSimple = (audioId, note) => (dispatch) => {
  axios
    .post(`${BASE_URL}/encounter/${audioId}/save-note`, {
      note: note,
    })
    .then((response) => {
      if (response.data.error) {
        dispatch(
          setNotificationAction({
            status: "error",
            title: "Something went wrong",
            desc: "Failed to save encounter note",
          }),
        );
        Bugsnag.notify(response.data.error);
      }
    });
};

export const saveEncounterTitle =
  (audioId, title, onSuccess?: () => void, onError?: () => void) =>
  (dispatch) => {
    axios
      .post(`${BASE_URL}/encounter/${audioId}/save-note`, {
        title,
      })
      .then((response) => {
        if (response.data.error) {
          onError?.();
          dispatch(
            setNotificationAction({
              status: "error",
              title: "Something went wrong",
              desc: "Failed to save encounter title",
            }),
          );
          Bugsnag.notify(response.data.error);

          return;
        }

        onSuccess?.();
      });
  };

export const saveEncounterFreeText = (audioId, freeText) => (dispatch) => {
  axios
    .post(`${BASE_URL}/encounter/${audioId}/save-note`, {
      free_text: freeText,
    })
    .then((response) => {
      if (response.data.error) {
        dispatch(
          setNotificationAction({
            status: "error",
            title: "Something went wrong",
            desc: "Failed to save encounter free text",
          }),
        );
        Bugsnag.notify(response.data.error);
      }
    });
};

export const saveEncounterPatient = (audioId, patientId) => (dispatch) => {
  axios
    .post(`${BASE_URL}/encounter/${audioId}/save-note`, {
      patient_id: patientId,
    })
    .then((response) => {
      if (response.data.error) {
        dispatch(
          setNotificationAction({
            status: "error",
            title: "Something went wrong",
            desc: "Failed to save encounter patient",
          }),
        );
        Bugsnag.notify(response.data.error);
      } else {
        dispatch(
          setNotificationAction({
            status: "success",
            title: "Successfully saved patient",
            desc: "Encounter has been successfully updated",
          }),
        );
      }
    });
};
