import { Analytics } from "@vercel/analytics/react";
import { SpeedInsights } from "@vercel/speed-insights/react";
import { Suspense, lazy, useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { IntercomProvider } from "react-use-intercom";

import Bugsnag from "@bugsnag/js";
import Loader from "./components/Basic/Loader";
import ProtectedRoute from "./components/Login/ProtectedRoute";
import { WS_URL } from "./helpers/config";
import {
  Pages,
  commonCampaigns,
  convPerPage,
  filtersClear,
  getFiltersInit,
  inboxes,
  rowsPerPage,
} from "./helpers/constants";
import { deepEqual } from "./helpers/helpers";
import { history } from "./helpers/history";
import { lazyRetry } from "./helpers/lazyRetry";
import BillingMetrics from "./pages/BillingMetrics";
import Campaigns from "./pages/Campaigns";
import Enroll from "./pages/Enroll";
import Messaging from "./pages/Messaging";
import Onboarding from "./pages/Onboarding";
import PatientProfile from "./pages/PatientProfile";
import Patients from "./pages/Patients";
import Scribe from "./pages/Scribe";
import CareManagementSettings from "./pages/Settings/CareManagementSettings";
import FacilitiesSettings from "./pages/Settings/FacilitiesSettings";
import NotesSettings from "./pages/Settings/NotesSettings";
import OrganizationSettings from "./pages/Settings/OrganizationSettings";
import ProfileSettings from "./pages/Settings/ProfileSettings";
import SettingsTabs from "./pages/Settings/SettingsTabs";
import LocalStorageService from "./services/LocalStorageService";
import { setSelectedEnrollCampaignAction } from "./store/campaign/actions";
import { getCampaigns } from "./store/campaign/thunks";
import {
  setActiveFiltersAction,
  setCampaignOptionsAction,
  setConversationsUpdatedAction,
  setInitFiltersAction,
  setOnMessageConversationIdAction,
} from "./store/conversation/actions";
import { getConversationCountByDoctor } from "./store/conversation/thunks";
import { searchConversationsWithFilters } from "./store/conversation/thunks";
import { getFacilities, getUserFacilities } from "./store/facility/thunks";
import { getNumOfPatients, searchPatients } from "./store/patient/thunks";
import {
  setDoctorOptionsAction,
  setOnlyScribeFeatureAction,
} from "./store/user/actions";
import { getUser, getUsers, updateStoredUser } from "./store/user/thunks";
import { setCallEventAction } from "./store/voiceRecorder/actions";
import { getTwilioToken } from "./store/voiceRecorder/thunks";

const Metrics = lazy(() => lazyRetry(() => import("./pages/Metrics")));
const Login = lazy(() => lazyRetry(() => import("./pages/Login")));
const SignUp = lazy(() => lazyRetry(() => import("./pages/SignUp")));
const ForgotPassword = lazy(() =>
  lazyRetry(() => import("./pages/ForgotPassword")),
);
const CheckEmail = lazy(() => lazyRetry(() => import("./pages/CheckEmail")));
const ResetPassword = lazy(() =>
  lazyRetry(() => import("./pages/ResetPassword")),
);
const ResetPasswordConfirmation = lazy(() =>
  lazyRetry(() => import("./pages/ResetPasswordConfirmation")),
);
const PasswordCreation = lazy(() =>
  lazyRetry(() => import("./pages/PasswordCreation")),
);

const storageService = new LocalStorageService();

const App = () => {
  const { user, doctorOptions, onlyScribeFeature } = useSelector(
    (state) => state.user,
  );
  const { campaigns } = useSelector((state) => state.campaign);
  const {
    campaignFilterOptions,
    initFilters,
    selectedDoctorInbox,
    conversationSearchInput,
    activeFilters,
    convCurrPage,
  } = useSelector((state) => state.conversation);
  const dispatch = useDispatch();
  history.navigate = useNavigate();
  history.location = useLocation();

  const token = storageService.getItem("token");

  const onChatMessage = useCallback(
    (eventData) => {
      console.log("websocket event chat", eventData.conversation_id);
      const id = eventData.conversation_id;
      dispatch(setOnMessageConversationIdAction(id));
      dispatch(setConversationsUpdatedAction(false));
      dispatch(
        searchConversationsWithFilters(
          conversationSearchInput,
          activeFilters,
          1,
          convPerPage * convCurrPage,
          inboxes.COMPLETE,
          selectedDoctorInbox,
        ),
      );
    },
    [
      dispatch,
      conversationSearchInput,
      activeFilters,
      convCurrPage,
      selectedDoctorInbox,
    ],
  );

  const onVoiceMessage = useCallback(
    (eventData) => {
      console.log("websocket event voice", eventData.data);
      dispatch(setCallEventAction(eventData.data));
    },
    [dispatch],
  );

  const connect = useCallback(() => {
    const websocket = new WebSocket(`${WS_URL}?token=${token}`);

    websocket.onopen = () => {
      console.log("Websocket is connected");
    };

    websocket.onerror = () => {
      websocket.close();
    };

    websocket.onclose = () => {
      console.log(
        "Websocket is closed. Reconnect will be attempted in 1 second",
      );
      dispatch(setConversationsUpdatedAction(false));
      setTimeout(() => {
        connect();
      }, 1000);
    };

    websocket.onmessage = (event) => {
      const eventData = JSON.parse(event.data);

      if (eventData.type === "chat") {
        onChatMessage(eventData);
      } else if (eventData.type === "voice") {
        onVoiceMessage(eventData);
      }
    };
  }, [dispatch, token, onChatMessage, onVoiceMessage]);

  useEffect(() => {
    if (storageService.getItem("token")) {
      getUser().then((user) => {
        dispatch(updateStoredUser(user));
      });
    }
  }, [dispatch]);

  useEffect(() => {
    if (user) {
      connect();
    }
  }, [user, connect]);

  useEffect(() => {
    if (user) {
      dispatch(
        setOnlyScribeFeatureAction(
          user?.organization?.featureFlags?.SCRIBE_ONLY,
        ),
      );

      Bugsnag.setUser(user.doctor_id, user.email, user.display_name);
      Bugsnag.setContext(`org: ${user.customer.display_name}`);

      dispatch(getFacilities());
      dispatch(getUsers(""));
      dispatch(getUserFacilities(user.doctor_id));
      if (!user?.organization?.featureFlags?.SCRIBE_ONLY) {
        dispatch(getCampaigns());
        dispatch(getTwilioToken());
        dispatch(getNumOfPatients());
        dispatch(
          searchPatients(
            storageService.getItem("patientSearchInput") ?? "",
            1,
            rowsPerPage,
            storageService.getItem("selectedDoctorPatients") ?? null,
            true,
            () => {},
            storageService.getItem("selectedPatientCampaigns") ?? [],
            storageService.getItem("selectedPatientStatuses") ?? [],
            storageService.getItem("selectedPatientInsurances") ?? [],
          ),
        );
        dispatch(setDoctorOptionsAction(user.doctors));
        dispatch(getConversationCountByDoctor("unassigned"));
        dispatch(getConversationCountByDoctor("clinic_all"));
        dispatch(getConversationCountByDoctor(null));
      }
    }
  }, [user, dispatch]);

  useEffect(() => {
    if (doctorOptions.length > 0) {
      doctorOptions.forEach((doctor) => {
        dispatch(getConversationCountByDoctor(doctor));
      });
      dispatch(getConversationCountByDoctor("unassigned"));
      dispatch(getConversationCountByDoctor(null));
    }
  }, [doctorOptions, dispatch]);

  useEffect(() => {
    if (user && campaigns.length > 0) {
      dispatch(
        setSelectedEnrollCampaignAction(
          storageService.getItem("selectedEnrollCampaign") ?? campaigns[0],
        ),
      );

      const customerCampaigns = campaigns.map((campaign) => ({
        value: campaign.campaign_id,
        text: campaign.campaign,
      }));

      dispatch(
        setCampaignOptionsAction([...customerCampaigns, ...commonCampaigns]),
      );
    }
  }, [campaigns, dispatch, user]);

  useEffect(() => {
    if (campaignFilterOptions.length > 0) {
      dispatch(setInitFiltersAction(getFiltersInit(campaignFilterOptions)));
    }
  }, [campaignFilterOptions, dispatch]);

  useEffect(() => {
    if (initFilters && !deepEqual(initFilters, filtersClear)) {
      dispatch(
        setActiveFiltersAction(
          storageService.getItem("activeFilters") ?? initFilters,
        ),
      );
    }
  }, [initFilters, dispatch]);

  return (
    <>
      <IntercomProvider appId="qu4axgoz" autoBoot>
        <Suspense
          fallback={
            <div className="w-screen h-screen flex items-center justify-center">
              <Loader />
            </div>
          }
        >
          <Routes>
            <Route path={Pages.METRICS} element={<Metrics />} />
            <Route path={Pages.LOGIN} element={<Login />} />
            <Route path={Pages.SIGNUP} element={<Login signup />} />
            <Route path={Pages.SIGNUP_V2} element={<SignUp />} />
            <Route path={Pages.FORGOT_PASS} element={<ForgotPassword />} />
            <Route path={Pages.CHECK_EMAIL} element={<CheckEmail />} />
            <Route path={Pages.RESET_PASS} element={<ResetPassword />} />
            <Route
              path={Pages.RESET_PASS_CONFIRM}
              element={<ResetPasswordConfirmation />}
            />
            <Route path={Pages.CREATE_PASS} element={<PasswordCreation />} />
            <Route path={Pages.ONBOARDING} element={<Onboarding />} />

            <Route element={<ProtectedRoute />}>
              {!onlyScribeFeature && (
                <>
                  <Route path={Pages.MESSAGING} element={<Messaging />} />
                  <Route path={Pages.PATIENTS} element={<Patients />} />
                  <Route
                    path={Pages.PATIENT_PROFILE}
                    element={<PatientProfile />}
                  />
                  <Route path={Pages.ENROLL} element={<Enroll />} />
                  <Route
                    path={Pages.BILLING_METRICS}
                    element={<BillingMetrics />}
                  />
                  <Route path={Pages.CAMPAIGNS} element={<Campaigns />} />

                  <Route
                    path={Pages.SETTINGS_FACILITIES}
                    element={
                      <SettingsTabs>
                        <FacilitiesSettings />
                      </SettingsTabs>
                    }
                  />
                  <Route
                    path={Pages.SETTINGS_CARE_MANAGEMENT}
                    element={
                      <SettingsTabs>
                        <CareManagementSettings />
                      </SettingsTabs>
                    }
                  />
                </>
              )}

              <Route path={Pages.SCRIBE} element={<Scribe />} />

              <Route
                path={Pages.SETTINGS_PROFILE}
                element={
                  <SettingsTabs>
                    <ProfileSettings />
                  </SettingsTabs>
                }
              />
              <Route
                path={Pages.SETTINGS_ORGANIZATION}
                element={
                  <SettingsTabs>
                    <OrganizationSettings />
                  </SettingsTabs>
                }
              />
              <Route
                path={Pages.SETTINGS_NOTES}
                element={
                  <SettingsTabs>
                    <NotesSettings />
                  </SettingsTabs>
                }
              />

              <Route
                path="*"
                element={
                  <Navigate
                    to={onlyScribeFeature ? Pages.SCRIBE : Pages.MESSAGING}
                    replace
                  />
                }
              />
            </Route>

            <Route path="*" element={<Navigate to={Pages.LOGIN} replace />} />
          </Routes>
        </Suspense>
      </IntercomProvider>
      <Analytics />
      <SpeedInsights />
    </>
  );
};

export default App;
