import { z } from "zod";
import {
  patientSchema,
  scribeSchema,
} from "../../helpers/commonValidationSchemas";
import {
  campaignConversationSchema,
  campaignSchema,
} from "../campaign/validationSchemas";

export const numberOfPatientsSchema = z.number().min(0);

const totalPerDoctorSchema = z.object({
  doctor_id: z.number().nullable(),
  total_patients: z.number().min(0).optional(),
  total: z.number().min(0).optional(),
});

export const patientsDataSchema = z.object({
  patients: z.array(patientSchema),
  total: numberOfPatientsSchema,
  total_clinic: z.number().min(0),
  total_per_doctor: z.array(totalPerDoctorSchema),
});

const enrollmentSchema = z.object({
  campaign: campaignSchema,
  campaign_id: z.number(),
  campaign_patient_id: z.number().nullish(),
  conversation: campaignConversationSchema,
  description: z.string().nullish(),
  doctor_id: z.number().nullish(),
  ehr_information: z.string().nullish(),
  frequency: z.string().nullish(),
  interval: z.number().nullish(),
  next_checkin: z.string().nullish(),
  outreach_focus: z.string().nullish(),
  outreach_goals: z.string().nullish(),
  patient: patientSchema,
  patient_id: z.number(),
  status: z.string().nullish(),
  times_campaign_sent: z.number().min(0).nullish(),
  verify_completion: z.number().nullish(),
});

export const enrollmentsSchema = z.array(enrollmentSchema);

export const patientScribeSchema = scribeSchema.extend({
  note: z.string().nullable(),
  type: z.string(),
});

export const patientScribesSchema = z.array(patientScribeSchema);
