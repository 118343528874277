import type { AxiosError } from "axios";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { useNavigate, useParams } from "react-router-dom";
import { Connection, Device } from "twilio-client";
import { ReactComponent as PhoneIcon } from "../assets/icons/calling-gray.svg";
import { ReactComponent as MessageIcon } from "../assets/icons/chat-round.svg";
import { ReactComponent as ScribeIcon } from "../assets/icons/microphone-scribe.svg";
import { ReactComponent as CloseIcon } from "../assets/icons/x-mark.svg";
import DeleteModal from "../components/Basic/DeleteModal";
import ProfilePicture from "../components/Basic/ProfilePicture";
import TabButtonRound from "../components/Basic/TabButtonRound";
import FinishLaterModal from "../components/IndependentScribe/FinishLaterModal";
import useRecorder from "../components/IndependentScribe/Recorder";
import {
  ERROR_NOTE_GENERATION_DELAYED,
  ERROR_NOT_ENOUGH_TRANSCRIPT,
} from "../components/IndependentScribe/consts";
import { useScribeGenerationDelay } from "../components/IndependentScribe/hooks/useScribeGenerationDelay";
import { setPatientNotesLoadingAction } from "../components/IndependentScribe/store/actions";
import type { Scribe } from "../components/IndependentScribe/store/interfaces";
import {
  setSelectedScribeNoteTemplate,
  setShowScribeNoteTemplate,
} from "../components/IndependentScribe/store/noteTemplateSlice";
import {
  type FixLater,
  setInterruptedRecordingAction,
  setScribePaused,
  setScribeResumeGenerated,
  setScribeSelectedAudioId,
  setScribes,
} from "../components/IndependentScribe/store/scribeSlice";
import {
  getByPatientId,
  getRecordingScribe,
  getSelectedScribe,
} from "../components/IndependentScribe/store/selectors";
import { describeNoteTemplate } from "../components/IndependentScribe/store/thunks";
import { SetInterruptedRecordingActionType } from "../components/IndependentScribe/store/types";
import { isGeneratedNote } from "../components/IndependentScribe/utils";
import ChatCallScribePanel from "../components/Layout/ChatCallScribePanel";
import EnrollmentEditFields from "../components/PatientProfile/CareCoordination/EnrollmentEditFields";
import EnrollmentEditHeader from "../components/PatientProfile/CareCoordination/EnrollmentEditHeader";
import EnrollmentsTab from "../components/PatientProfile/CareCoordination/EnrollmentsTab";
import { useNewEnrollmentLogic } from "../components/PatientProfile/CareCoordination/useNewEnrollmentLogic";
import HistoryTab from "../components/PatientProfile/History/HistoryTab";
import InformationTab from "../components/PatientProfile/Information/InformationTab";
import NoteHeader from "../components/PatientProfile/Notes/NoteHeader";
import NoteInfo from "../components/PatientProfile/Notes/NoteInfo";
import NotePreview from "../components/PatientProfile/Notes/NotePreview";
import NotesTab from "../components/PatientProfile/Notes/NotesTab";
import PatientShortInfo from "../components/PatientProfile/PatientShortInfo";
import {
  NoteTabs,
  patientPanelTabs,
  patientProfileTabs,
  twilioDeviceStatuses,
} from "../helpers/constants";
import { useActivityTracker } from "../hooks/useActivityTracker";
import { useScribeTitleChange } from "../hooks/useScribeTitleChange";
import { store, useAppDispatch, useAppSelector } from "../store";
import { openConversationEvent } from "../store/billingMetrics/thunks";
import { cancelUpcomingMessage } from "../store/campaign/thunks";
import {
  setConversationsUpdatedAction,
  setMessagesAction,
} from "../store/conversation/actions";
import {
  setLastSelectedPatientProfileAction,
  setProfileConversationAction,
  setProfileLoadingAction,
  setSelectedPatientEnrollmentsAction,
  setSelectedPatientInfoAction,
  setSelectedPatientPanelTabAction,
  setSelectedPatientProfileTabAction,
} from "../store/patient/actions";
import {
  getPatientEnrollments,
  getPatientInfo,
  getPatientScribes,
  getProfileConversation,
} from "../store/patient/thunks";
import { setSelectedSettingsTabAction } from "../store/user/actions";
import {
  setDeviceStatusAction,
  setOpenCallModalAction,
  setOpenCallOptionsModalAction,
} from "../store/voiceRecorder/actions";
import {
  getTwilioToken,
  sendFinishScribe,
  sendPatientAudioPart,
} from "../store/voiceRecorder/thunks";

const buttonClasses =
  "w-full h-full flex flex-col items-center justify-center text-base font-medium text-tertiary";

const PatientProfile = () => {
  const { id } = useParams();

  const { user } = useAppSelector((state) => state.user);
  const { messages } = useAppSelector((state) => state.conversation);
  const {
    selectedPatientInfo,
    selectedPatientEnrollments,
    selectedPatientProfileTab,
    lastSelectedPatientProfile,
    profileConversation,
    patientRecordingScribeTitle,
    selectedPatientPanelTab,
    selectedGeneratedNoteAudioId,
  } = useAppSelector((state) => state.patient);
  const { twilioToken, openCallOptionsModal, callEvent, deviceStatus } =
    useAppSelector((state) => state.voiceRecorder);
  const { showScribeNoteTemplate, selectedScribeNoteTemplate } = useAppSelector(
    (state) => state.noteTemplate,
  );
  const { interruptedRecordingAction } = useAppSelector(
    (state) => state.scribe,
  );
  const { pathBeforeProfile } = useAppSelector((state) => state.ui);

  const recordingScribe = useAppSelector(getRecordingScribe);
  const selectedScribe = useAppSelector(getSelectedScribe);
  const scribes = useAppSelector((state) =>
    getByPatientId(state, Number(selectedPatientInfo?.patient_id)),
  );
  const selectedGeneratedNote = scribes.find(
    (scribe) => scribe.audioId === selectedGeneratedNoteAudioId,
  );

  const isDesktop = useMediaQuery({ minWidth: 768 });
  const isWidthLg = useMediaQuery({ minWidth: 1024 });
  const isHeightSm = useMediaQuery({ minHeight: 640 });

  const { onUpdateTitle } = useScribeTitleChange(selectedScribe);

  const { READY, RINGING, IN_PROGRESS, OFFLINE } = twilioDeviceStatuses;
  const { INFORMATION, ENROLLMENTS, NOTES, HISTORY } = patientProfileTabs;
  const { NOTE } = NoteTabs;

  const [twilioDevice, setTwilioDevice] = useState(new Device());
  const [call, setCall] = useState(null);
  const [isMuted, setIsMuted] = useState(false);
  const [editEnrollment, setEditEnrollment] = useState(false);
  const [selectedEnrollment, setSelectedEnrollment] = useState(null);
  const [activeNoteTab, setActiveNoteTab] = useState(NOTE);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [notEnoughTranscript, setNotEnoughTranscript] = useState(false);
  const [showFinishLaterModal, setShowFinishLaterModal] = useState(false);

  const prevSelectedScribeRef = useRef<Scribe | null>(null);
  const selectedPatientInfoRef = useRef(selectedPatientInfo);
  const selectedPatientProfileTabRef = useRef(selectedPatientProfileTab);

  const { handleNewEnrollment } = useNewEnrollmentLogic();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const {
    showNoteGenerationDelayedWarning,
    hideNoteGenerationDelayedWarning,
    setShowNoteGenerationDelayedWarning,
    setScribeGenerationDelayed,
  } = useScribeGenerationDelay(selectedScribe);

  const isNoteTitleEditable =
    !!selectedScribe?.audioId && !selectedScribe?.isGenerating;

  const doTracking = useMemo(
    () => (profileConversation ? profileConversation.trackable : false),
    [profileConversation],
  );
  const { restartTimer } = useActivityTracker(doTracking);

  useEffect(() => {
    selectedPatientProfileTabRef.current = selectedPatientProfileTab;
  }, [selectedPatientProfileTab]);

  useEffect(() => {
    if (
      !user.customer.has_ehr_data &&
      selectedPatientProfileTabRef.current === patientProfileTabs.HISTORY
    ) {
      dispatch(setSelectedPatientProfileTabAction(patientProfileTabs.NOTES));
    }
  }, [user, dispatch]);

  useEffect(() => {
    if (
      lastSelectedPatientProfile?.info &&
      Number(id) === lastSelectedPatientProfile.info.patient_id
    ) {
      if (!selectedPatientInfoRef.current) {
        dispatch(setSelectedPatientInfoAction(lastSelectedPatientProfile.info));
      }
      dispatch(setScribes({ scribes: lastSelectedPatientProfile.notes }));
      dispatch(
        setSelectedPatientEnrollmentsAction(
          lastSelectedPatientProfile.enrollments,
        ),
      );
    } else {
      dispatch(setProfileLoadingAction(true));
      dispatch(
        getPatientInfo(id, (patientInfo) => {
          dispatch(setSelectedPatientInfoAction(patientInfo));
        }),
      );
    }

    dispatch(
      describeNoteTemplate(user.note_template_id, (data) =>
        dispatch(setSelectedScribeNoteTemplate(data)),
      ),
    );
    dispatch(setConversationsUpdatedAction(false));

    return () => {
      dispatch(setSelectedPatientInfoAction(null));
      dispatch(setSelectedPatientEnrollmentsAction(null));

      dispatch(setProfileConversationAction(null));
      dispatch(setOpenCallOptionsModalAction(false));
      dispatch(setOpenCallModalAction(false));

      dispatch(setScribeSelectedAudioId({ audioId: null }));
      dispatch(setMessagesAction({}));

      dispatch(setShowScribeNoteTemplate(false));
      dispatch(setPatientNotesLoadingAction(true));
    };
  }, [id, dispatch, lastSelectedPatientProfile, user.note_template_id]);

  useEffect(() => {
    selectedPatientInfoRef.current = selectedPatientInfo;
    if (selectedPatientInfo?.conversation_id) {
      dispatch(getProfileConversation(selectedPatientInfo.conversation_id));
    }
  }, [selectedPatientInfo, dispatch]);

  useEffect(() => {
    if (
      selectedScribe &&
      !isDesktop &&
      isGeneratedNote(selectedScribe) &&
      prevSelectedScribeRef.current?.audioId === selectedScribe.audioId &&
      !isGeneratedNote(prevSelectedScribeRef.current)
    ) {
      dispatch(setOpenCallOptionsModalAction(false));
    }

    prevSelectedScribeRef.current = selectedScribe;
  }, [selectedScribe, isDesktop, dispatch]);

  useEffect(() => {
    (async () => {
      if (doTracking) {
        restartTimer();
        await openConversationEvent(
          profileConversation.patient.patient_id,
          profileConversation.campaign_type,
        );
      }
    })();
  }, [profileConversation, restartTimer, doTracking]);

  const setActiveTabPatientProfile = (tab) => {
    dispatch(setSelectedPatientProfileTabAction(tab));
  };

  const onCloseEditEnrollment = () => {
    setEditEnrollment(false);
    setSelectedEnrollment(null);
  };

  const onDeleteEnrollment = (e) => {
    e?.preventDefault();
    setOpenDeleteModal(true);
  };

  const deleteEnrollment = () => {
    setEditEnrollment(false);
    setSelectedEnrollment(null);
    dispatch(
      cancelUpcomingMessage(
        selectedEnrollment.campaign_id,
        selectedEnrollment.campaign_patient_id,
        selectedEnrollment.patient.patient_id,
        () => {
          dispatch(getPatientEnrollments(id));
        },
      ),
    );
  };

  const onCloseNote = () => {
    setActiveNoteTab(NOTE);
    dispatch(setShowScribeNoteTemplate(false));
  };

  const onSaveNote = () => {
    dispatch(getPatientScribes(id));
  };

  const onCloseProfile = (e) => {
    if (recordingScribe) {
      e.preventDefault();
      dispatch(
        setInterruptedRecordingAction({
          type: SetInterruptedRecordingActionType.PATIENT_PROFILE_CLOSE,
        }),
      );

      return;
    }

    dispatch(
      setLastSelectedPatientProfileAction({
        info: selectedPatientInfo,
        notes: scribes,
        enrollments: selectedPatientEnrollments,
      }),
    );
    navigate(pathBeforeProfile);
  };

  const selectTab = (tab) => {
    dispatch(setSelectedPatientPanelTabAction(tab));
  };

  useEffect(() => {
    if (twilioToken) {
      twilioDevice.setup(twilioToken, {
        codecPreferences: [Connection.Codec.Opus, Connection.Codec.PCMU],
        fakeLocalDTMF: true,
        enableRingingState: true,
        debug: false,
      });

      twilioDevice.on("ready", (device) => {
        console.log("twilio device ready");
        setTwilioDevice(device);
        dispatch(setDeviceStatusAction(READY));
      });

      twilioDevice.on("error", (error) => {
        dispatch(setDeviceStatusAction(OFFLINE));
        console.error("twilio error", error);
      });

      twilioDevice.on("disconnect", () => {
        console.log("twilio device disconnect");
        dispatch(setDeviceStatusAction(READY));
        dispatch(setOpenCallModalAction(false));
      });

      twilioDevice.on("incoming", (connection) => {
        console.log("twilio device incoming");

        connection.on("reject", () => {
          dispatch(setDeviceStatusAction(READY));
        });
      });

      twilioDevice.on("tokenWillExpire", () => {
        console.log("twilio token will expire");
        dispatch(getTwilioToken());
      });
    }
  }, [twilioToken, dispatch, twilioDevice, OFFLINE, READY]);

  useEffect(() => {
    console.debug("device status", deviceStatus);
    if (deviceStatus === OFFLINE) {
      dispatch(getTwilioToken());
    }
  }, [deviceStatus, OFFLINE, dispatch]);

  const handleDisconnect = useCallback(() => {
    console.log("handle disconnect");
    twilioDevice?.disconnectAll();
    dispatch(setDeviceStatusAction(READY));
  }, [twilioDevice, dispatch, READY]);

  useEffect(() => {
    if (call) {
      call.on("cancel", () => {
        console.log("call canceled");
        handleDisconnect();
      });
      call.on("disconnect", () => {
        console.log("call disconnected");
        handleDisconnect();
      });
      call.on("error", (error) => {
        handleDisconnect();
        console.error("call error", error);
      });
      call.on("reject", () => {
        console.log("call rejected");
        handleDisconnect();
      });
      call.on("reconnecting", (twilioError) => {
        console.log("call reconnecting", twilioError);
      });
    }
    return () => {
      console.debug("call is muted", false);
      setIsMuted(false);
    };
  }, [call, handleDisconnect]);

  useEffect(() => {
    if (call) {
      console.debug("call is muted", call.isMuted());
    } else {
      console.debug("call is muted", false);
    }
  }, [call]);

  useEffect(() => {
    if (callEvent) {
      if (callEvent.status === "ringing") {
        console.log("call ringing");
        dispatch(setDeviceStatusAction(RINGING));
      }
      if (callEvent.status === "in-progress") {
        console.log("call in progress");
        dispatch(setDeviceStatusAction(IN_PROGRESS));
      }
      if (callEvent.status === "completed") {
        console.log("call completed");
        handleDisconnect();
      }
    }
  }, [callEvent, IN_PROGRESS, RINGING, dispatch, handleDisconnect]);

  const handleCall = useCallback(
    async (phone_number) => {
      console.log("handle call, device status", deviceStatus);
      if (deviceStatus !== twilioDeviceStatuses.READY || !twilioDevice) {
        dispatch(getTwilioToken());
      } else {
        const call = await twilioDevice?.connect({ To: phone_number });
        setCall(call);
      }
    },
    [deviceStatus, twilioDevice, dispatch],
  );

  const handleMute = () => {
    setIsMuted(!isMuted);
    call.mute(!isMuted);
  };

  const handleSetMessages = useCallback(
    (messages) => {
      dispatch(setMessagesAction(messages));
    },
    [dispatch],
  );

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (recordingScribe) {
        event.preventDefault();
        event.returnValue = "Are you sure you want to cancel the recording?";
      }
    };

    const handlePopState = (_) => {
      if (recordingScribe) {
        history.pushState(null, null, window.location.href);
        setShowFinishLaterModal(true);
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);
    window.addEventListener("popstate", handlePopState);
    history.pushState(null, null, window.location.href);

    return () => {
      if (recordingScribe) {
        onCancel();
        cancelRecording();
      } else {
        setNotEnoughTranscript(false);
        setShowFinishLaterModal(false);
        dispatch(setInterruptedRecordingAction(null));
      }

      if (
        !selectedScribe?.note &&
        (selectedScribe?.isPaused || selectedScribe?.isRecording)
      ) {
        dispatch(setShowScribeNoteTemplate(false));
      }

      window.removeEventListener("beforeunload", handleBeforeUnload);
      window.removeEventListener("popstate", handlePopState);
    };
  }, [
    selectedScribe?.isPaused,
    selectedScribe?.isRecording,
    selectedScribe?.note,
    recordingScribe,
    dispatch,
  ]);

  useEffect(() => {
    setShowFinishLaterModal(!!interruptedRecordingAction);
  }, [interruptedRecordingAction]);

  const sendAudioPart = useCallback(
    (data: Blob, chunkPosition: number) => {
      dispatch(
        sendPatientAudioPart(
          selectedPatientInfo?.patient_id,
          data,
          () => {},
          () => {},
          selectedScribeNoteTemplate
            ? selectedScribeNoteTemplate.note_template_id
            : null,
          chunkPosition,
        ),
      );
    },
    [dispatch, selectedPatientInfo?.patient_id, selectedScribeNoteTemplate],
  );

  const finishRecording = (withTranscriptAlert = true) => {
    dispatch(
      sendFinishScribe(
        selectedPatientInfo?.patient_id,
        () => {},
        (error: AxiosError) => {
          if (
            (error.response.data as FixLater).error ===
            ERROR_NOT_ENOUGH_TRANSCRIPT
          ) {
            dispatch(setScribePaused());

            if (withTranscriptAlert) {
              setNotEnoughTranscript(true);
            }
          }

          if (
            (error.response?.data as FixLater)?.error ===
            ERROR_NOTE_GENERATION_DELAYED
          ) {
            setScribeGenerationDelayed(selectedScribe.audioId, true);
          }
        },
      ),
    );
  };

  const {
    mediaRecorder,
    audioVolume,
    noAudioDetected,
    microphones,
    microphoneId,
    setMicrophoneId,
    startRecording,
    togglePause,
    cancelRecording,
    saveRecording,
    isStalled,
    isReadyToRecord,
    readyToRecordErrors,
    requestMicrophonePermissions,
  } = useRecorder(sendAudioPart, finishRecording);

  const onCancel = useCallback(
    (actionType = "") => {
      if (
        actionType !==
          SetInterruptedRecordingActionType.PATIENT_CHANGE_SELECTED_AUDIO_ID_WHILE_RECORDING &&
        actionType !== SetInterruptedRecordingActionType.PATIENT_CALL &&
        actionType !==
          SetInterruptedRecordingActionType.CLOSE_PANEL_WHILE_RECORDING
      ) {
        dispatch(setScribeSelectedAudioId({ audioId: null }));
        dispatch(setScribePaused());
      }

      setNotEnoughTranscript(false);
      setShowFinishLaterModal(false);
      setShowNoteGenerationDelayedWarning(false);
      dispatch(setInterruptedRecordingAction(null));
    },
    [dispatch, setShowNoteGenerationDelayedWarning],
  );

  const handleCancelRecording = useCallback(() => {
    onCancel(interruptedRecordingAction?.type);

    if (
      interruptedRecordingAction?.type ===
      SetInterruptedRecordingActionType.NAVIGATION
    ) {
      navigate(interruptedRecordingAction.value);
    } else if (
      interruptedRecordingAction?.type ===
      SetInterruptedRecordingActionType.PATIENT_PROFILE_CLOSE
    ) {
      dispatch(
        setLastSelectedPatientProfileAction({
          info: selectedPatientInfo,
          notes: scribes,
          enrollments: selectedPatientEnrollments,
        }),
      );
      navigate(pathBeforeProfile);
    } else if (
      interruptedRecordingAction?.type ===
      SetInterruptedRecordingActionType.NEW_ENROLLMENT
    ) {
      handleNewEnrollment();
    } else if (
      interruptedRecordingAction?.type ===
      SetInterruptedRecordingActionType.SETTINGS_NAVIGATION
    ) {
      const { selectedSettingsTab } = store.getState().user;

      if (!selectedSettingsTab) {
        dispatch(setSelectedSettingsTabAction("Profile"));
      }

      navigate(interruptedRecordingAction.value);
    } else if (
      interruptedRecordingAction?.type ===
        SetInterruptedRecordingActionType.PATIENT_CHANGE_SELECTED_AUDIO_ID_WHILE_RECORDING ||
      interruptedRecordingAction?.type ===
        SetInterruptedRecordingActionType.PATIENT_CALL ||
      interruptedRecordingAction?.type ===
        SetInterruptedRecordingActionType.CLOSE_PANEL_WHILE_RECORDING
    ) {
      togglePause();
      dispatch(setShowScribeNoteTemplate(false));
      dispatch(setScribeSelectedAudioId({ audioId: null }));
      dispatch(setScribePaused());
    }

    if (
      interruptedRecordingAction?.type ===
      SetInterruptedRecordingActionType.PATIENT_CALL
    ) {
      dispatch(setOpenCallModalAction(true));
      handleCall(selectedPatientInfo?.phone_number);
    }
  }, [
    togglePause,
    pathBeforeProfile,
    navigate,
    dispatch,
    onCancel,
    interruptedRecordingAction,
    scribes,
    selectedPatientEnrollments,
    handleNewEnrollment,
    handleCall,
    selectedPatientInfo,
  ]);

  const onResumeRecording = () => {
    if (recordingScribe) {
      dispatch(
        setInterruptedRecordingAction({
          type: SetInterruptedRecordingActionType.RESUME_GENERATED_ENCOUNTER,
        }),
      );
      return;
    }

    dispatch(setSelectedPatientPanelTabAction(patientPanelTabs.SCRIBE));
    dispatch(setScribeSelectedAudioId({ audioId: selectedScribe.audioId }));
    dispatch(setScribeResumeGenerated());
    startRecording();
  };

  return (
    <div
      className={`h-full w-full overflow-hidden grid grid-cols-conv-layout bg-gray-background
        ${isDesktop ? "space-x-4" : ""}`}
    >
      <div
        className={`relative flex flex-col overflow-x-auto min-h-0
        ${isDesktop && isHeightSm ? "py-4 pl-4 height-md:py-6 height-md:pl-6" : "md:py-2 md:pl-2"}`}
      >
        {!isDesktop && editEnrollment && selectedEnrollment ? (
          <EnrollmentEditHeader
            onClose={onCloseEditEnrollment}
            enrollment={selectedEnrollment}
          />
        ) : !isDesktop &&
          selectedScribe?.audioId &&
          !selectedScribe?.note &&
          showScribeNoteTemplate ? (
          <NoteHeader
            note={selectedScribe}
            onClose={onCloseNote}
            activeTab={activeNoteTab}
            setActiveTab={setActiveNoteTab}
          />
        ) : !isDesktop &&
          showScribeNoteTemplate &&
          !selectedScribe?.isPaused &&
          selectedScribeNoteTemplate ? (
          <NoteHeader
            note={{ title: patientRecordingScribeTitle + 123 }}
            onClose={onCloseNote}
            activeTab={activeNoteTab}
            setActiveTab={setActiveNoteTab}
          />
        ) : (
          <div className="px-4 md:px-0 pt-3 md:pt-0 overflow-x-hidden flex flex-col flex-none">
            <div className="grid grid-cols-conv-layout md:grid-cols-2-right md:gap-4 w-full">
              {isDesktop && (
                <button
                  type="button"
                  onClick={onCloseProfile}
                  className="w-6 h-8 md:w-8 md:bg-white md:border rounded-lg flex items-center justify-center flex-none"
                >
                  <CloseIcon stroke="#121212" width="18" height="18" />
                </button>
              )}

              {selectedPatientInfo && (
                <div className="w-full truncate">
                  <div className="flex items-center md:items-start truncate">
                    <ProfilePicture
                      firstName={
                        selectedPatientInfo.preferred_name
                          ? selectedPatientInfo.preferred_name
                          : selectedPatientInfo.first_name
                      }
                      lastName={selectedPatientInfo.last_name}
                      src={selectedPatientInfo.profile_picture}
                      size={isWidthLg ? 16 : 12}
                      fontSize={isWidthLg ? "2xl" : "lg"}
                      className="bg-[#FF9800] flex-none text-2xl hidden lg:flex lg:mr-4"
                      bgColor="[#FF9800]"
                      textColor="white"
                      flagPadding={false}
                    />
                    <div className="truncate">
                      <p className="font-semibold mt-1 md:mt-0 truncate text-base md:text-xl lg:text-2xl">
                        {selectedPatientInfo.preferred_name
                          ? selectedPatientInfo.preferred_name
                          : selectedPatientInfo.first_name}{" "}
                        {selectedPatientInfo.last_name}
                      </p>
                      {isDesktop && (
                        <PatientShortInfo
                          patient={selectedPatientInfo}
                          className="mt-1.5 pb-0.5 flex-wrap text-xs md:text-sm lg:text-base"
                        />
                      )}
                    </div>
                  </div>

                  {!isDesktop && (
                    <PatientShortInfo
                      patient={selectedPatientInfo}
                      className="mt-1 pb-0.5 truncate text-xs text-tertiary"
                    />
                  )}
                </div>
              )}

              {!isDesktop && (
                <button
                  type="button"
                  onClick={onCloseProfile}
                  className="w-6 h-8 md:w-8 md:bg-white md:border rounded-lg flex items-center justify-center flex-none"
                >
                  <CloseIcon stroke="#121212" width="18" height="18" />
                </button>
              )}
            </div>

            {editEnrollment ? (
              <EnrollmentEditHeader
                onClose={onCloseEditEnrollment}
                enrollment={selectedEnrollment}
              />
            ) : (((selectedScribe?.audioId || selectedGeneratedNote) &&
                selectedScribeNoteTemplate) ||
                selectedGeneratedNote ||
                selectedScribe?.note) &&
              showScribeNoteTemplate ? (
              <NoteHeader
                note={selectedGeneratedNote || selectedScribe}
                onClose={onCloseNote}
                activeTab={activeNoteTab}
                setActiveTab={setActiveNoteTab}
                editableTitle={isNoteTitleEditable}
                onUpdateTitle={onUpdateTitle}
              />
            ) : !selectedScribe?.isPaused &&
              showScribeNoteTemplate &&
              selectedScribeNoteTemplate ? (
              <NoteHeader
                note={{ title: patientRecordingScribeTitle }}
                onClose={onCloseNote}
                activeTab={activeNoteTab}
                setActiveTab={setActiveNoteTab}
                editableTitle={true}
                onUpdateTitle={onUpdateTitle}
              />
            ) : (
              <div className="flex min-w-0 space-x-2 whitespace-nowrap overflow-auto scrollbar pb-0.5 my-5">
                <TabButtonRound
                  label={NOTES}
                  active={selectedPatientProfileTab}
                  onClick={() => setActiveTabPatientProfile(NOTES)}
                />
                <TabButtonRound
                  label={ENROLLMENTS}
                  active={selectedPatientProfileTab}
                  onClick={() => setActiveTabPatientProfile(ENROLLMENTS)}
                />
                <TabButtonRound
                  label={INFORMATION}
                  active={selectedPatientProfileTab}
                  onClick={() => setActiveTabPatientProfile(INFORMATION)}
                />
                {user.customer.has_ehr_data && (
                  <TabButtonRound
                    label={HISTORY}
                    active={selectedPatientProfileTab}
                    onClick={() => setActiveTabPatientProfile(HISTORY)}
                  />
                )}
              </div>
            )}
          </div>
        )}

        <div
          className={`w-full h-full overflow-y-auto scrollbar
          ${
            editEnrollment ||
            (
              showScribeNoteTemplate &&
                (selectedScribeNoteTemplate || selectedScribe?.note)
            )
              ? `bg-white rounded-b-xl border-x border-b md:pt-0
                ${selectedScribe || showScribeNoteTemplate ? "" : "px-4 pt-4"}`
              : "md:pt-2 px-4 md:px-0"
          }`}
        >
          {editEnrollment && selectedEnrollment && selectedPatientInfo ? (
            <div className="w-full h-full pr-1">
              <EnrollmentEditFields
                enrollment={selectedEnrollment}
                patient={selectedPatientInfo}
              />
            </div>
          ) : ((selectedScribe?.audioId &&
              selectedScribe?.note &&
              !selectedScribe.isGenerating) ||
              selectedGeneratedNote) &&
            showScribeNoteTemplate &&
            (!selectedScribe?.isPaused || selectedGeneratedNote) &&
            selectedPatientInfo ? (
            <NoteInfo
              scribe={selectedGeneratedNote || selectedScribe}
              onSaveNote={onSaveNote}
              activeTab={activeNoteTab}
              patient={selectedPatientInfo}
              onResumeRecording={onResumeRecording}
            />
          ) : showScribeNoteTemplate &&
            (selectedScribe?.isPaused ||
              selectedScribe?.isRecording ||
              selectedScribe?.isGenerating) &&
            selectedPatientInfo &&
            selectedScribeNoteTemplate ? (
            <NotePreview
              title={selectedScribe.title || "Scribe"}
              sections={selectedScribeNoteTemplate.sections}
            />
          ) : (
            <div className="w-full h-full min-h-fit pr-1 flex-none">
              {selectedPatientProfileTab === NOTES && <NotesTab />}
              {selectedPatientProfileTab === ENROLLMENTS && (
                <EnrollmentsTab
                  onDeleteEnrollment={onDeleteEnrollment}
                  setSelectedEnrollment={setSelectedEnrollment}
                />
              )}
              {selectedPatientProfileTab === INFORMATION &&
                selectedPatientInfo && (
                  <InformationTab patientInfo={selectedPatientInfo} />
                )}
              {user.customer.has_ehr_data &&
                selectedPatientProfileTab === HISTORY && (
                  <HistoryTab patientInfo={selectedPatientInfo} />
                )}
            </div>
          )}
        </div>

        {!isDesktop && !editEnrollment && !showScribeNoteTemplate && (
          <div className="fixed bottom-5 w-full px-5">
            <div className="bg-white w-full h-16 rounded-full border grid items-center shadow-button grid-cols-3">
              <button
                type="button"
                onClick={() => {
                  dispatch(setOpenCallOptionsModalAction(true));
                  dispatch(
                    setSelectedPatientPanelTabAction(patientPanelTabs.MESSAGE),
                  );
                }}
                className={buttonClasses}
              >
                <MessageIcon width="19" height="19" />
                <p>Message</p>
              </button>
              <button
                type="button"
                onClick={() => {
                  dispatch(setOpenCallOptionsModalAction(true));
                  dispatch(
                    setSelectedPatientPanelTabAction(patientPanelTabs.CALL),
                  );
                }}
                className={buttonClasses}
              >
                <PhoneIcon width="20" height="20" />
                <p>Call</p>
              </button>
              <button
                type="button"
                onClick={() => {
                  dispatch(setOpenCallOptionsModalAction(true));
                  dispatch(
                    setSelectedPatientPanelTabAction(patientPanelTabs.SCRIBE),
                  );
                }}
                className={buttonClasses}
              >
                <ScribeIcon width="20" height="20" />
                <p>Scribe</p>
              </button>
            </div>
          </div>
        )}

        {openDeleteModal && selectedEnrollment && (
          <DeleteModal
            title="Delete Campaign"
            description="Are you sure you want to stop this campaign? This action cannot be undone."
            onClose={() => setOpenDeleteModal(false)}
            onDelete={deleteEnrollment}
          />
        )}
      </div>
      <div className={"overflow-hidden md:right-0"}>
        {((selectedPatientInfo && isDesktop) ||
          ((openCallOptionsModal || selectedScribe?.isRecording) &&
            !isDesktop)) && (
          <div
            className={`
                  ${
                    selectedPatientInfo && isDesktop
                      ? `h-full w-[42vw] min-w-[400px]
                        ${isHeightSm ? "py-4 pr-4 height-md:py-6 height-md:pr-6" : "py-2 pr-2"}`
                      : ""
                  }
                  ${(openCallOptionsModal || selectedScribe?.isRecording || selectedScribe?.isPaused) && !isDesktop ? "absolute top-0 left-0 z-20 h-screen-dynamic w-full" : ""}`}
          >
            <ChatCallScribePanel
              handleCall={handleCall}
              handleDisconnect={handleDisconnect}
              handleMute={handleMute}
              isMuted={isMuted}
              conversation={profileConversation}
              patient={selectedPatientInfo}
              selectedTab={selectedPatientPanelTab}
              setSelectedTab={selectTab}
              messages={messages}
              setMessages={handleSetMessages}
              scribeTab={true}
              notEnoughTranscript={notEnoughTranscript}
              setNotEnoughTranscript={setNotEnoughTranscript}
              cancelRecording={cancelRecording}
              togglePause={togglePause}
              isStalled={isStalled}
              mediaRecorder={mediaRecorder}
              audioVolume={audioVolume}
              noAudioDetected={noAudioDetected}
              microphones={microphones}
              microphoneId={microphoneId}
              setMicrophoneId={setMicrophoneId}
              startRecording={startRecording}
              saveRecording={saveRecording}
              onCancelScribe={onCancel}
              isReadyToRecord={isReadyToRecord}
              readyToRecordErrors={readyToRecordErrors}
              requestMicrophonePermissions={requestMicrophonePermissions}
              showNoteGenerationDelayedWarning={
                showNoteGenerationDelayedWarning
              }
              hideNoteGenerationDelayedWarning={
                hideNoteGenerationDelayedWarning
              }
            />
          </div>
        )}
      </div>

      {showFinishLaterModal && (
        <FinishLaterModal
          onClose={() => {
            setShowFinishLaterModal(false);
            dispatch(setInterruptedRecordingAction(null));
          }}
          onConfirm={handleCancelRecording}
        />
      )}
    </div>
  );
};

export default PatientProfile;
