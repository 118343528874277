import { useState } from "react";
import { ReactComponent as ArrowIcon } from "../../../assets/icons/chevron-down.svg";
import MeasureCard from "./MeasureCard";
import RafCard from "./RafCard";

const Reports = ({ className = "" }) => {
  const [openReports, setOpenReports] = useState(false);

  return (
    <div className={className}>
      <button
        type="button"
        className="flex items-center space-x-2"
        onClick={() => setOpenReports(!openReports)}
      >
        <p className="uppercase text-sm font-medium font-Inter tracking-wider">
          Reports
        </p>
        <ArrowIcon
          width="15"
          height="15"
          stroke="#121212"
          className={`flex-none transition-all ${openReports ? "-rotate-180" : ""}`}
        />
      </button>

      {openReports && (
        <div className="space-y-3 pt-3">
          <div className="w-full flex items-center space-x-4 py-1">
            <hr className="w-full border-gray-300" />
            <p className="uppercase font-Inter text-sm font-medium tracking-normal text-primary-blue">
              MEASURES
            </p>
            <hr className="w-full border-gray-300" />
          </div>

          <MeasureCard
            title="Annual Wellness"
            date="01/01/2024"
            tag="Non Compliant"
          />
          <MeasureCard
            title="High Blood Pressure"
            date="01/01/2024"
            tag="Non Compliant"
          />
          <MeasureCard
            title="Fall Risk Assessment"
            date="01/01/2024"
            tag="Compliant"
          />
          <MeasureCard
            title="High Risk for Cardiovascular Event"
            date="01/01/2024"
            tag="Non Compliant"
            warnings={[
              "Blood Pressure increase over 140/90",
              "Low Medication Adherence",
              "Past Hospitalization",
            ]}
          />

          <div className="w-full flex items-center space-x-4 py-1">
            <hr className="w-full border-gray-300" />
            <p className="uppercase font-Inter text-sm font-medium tracking-normal text-primary-blue">
              RAF
            </p>
            <hr className="w-full border-gray-300" />
          </div>

          <RafCard
            tag="Chronic Condition"
            title="Reumathoid arthritis and inflammatory connective tissue disease"
            data={{
              "CC Report Code": "HCC40",
              "CCLF Version": "V24",
              "Previously Billed": "M461 (02/09/2023)",
            }}
          />
        </div>
      )}
    </div>
  );
};

export default Reports;
