import emptyTeamsIllustrationSrc from "../../../../assets/empty-teams.svg";
import { ReactComponent as PlusIcon } from "../../../../assets/icons/plus.svg";
import Button from "../../../Basic/Button";

const EmptyUsers = ({ onClick }) => {
  return (
    <div className="w-full flex flex-col items-center justify-center py-10 md:py-16 space-y-7">
      <img
        src={emptyTeamsIllustrationSrc}
        alt="Empty Users"
        width="459"
        height="165"
      />
      <p className="text-xl font-bold">No Users Found</p>
      <Button
        className="font-semibold text-sm sm:text-base space-x-2"
        onClick={onClick}
      >
        <PlusIcon width="20" height="20" stroke="white" />
        <p>Create User</p>
      </Button>
    </div>
  );
};

export default EmptyUsers;
