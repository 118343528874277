import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  useFilters,
  useGlobalFilter,
  useRowSelect,
  useSortBy,
  useTable,
} from "react-table";
import {
  Pages,
  patientPanelTabs,
  patientProfileTabs,
} from "../../helpers/constants";
import { getFormattedPhoneNumber } from "../../helpers/helpers";
import {
  setOpenEditInfoModalAction,
  setSelectedPatientInfoAction,
  setSelectedPatientPanelTabAction,
  setSelectedPatientProfileTabAction,
  setSelectedPatientsAction,
} from "../../store/patient/actions";
import { getPatientInfo } from "../../store/patient/thunks";
import { setPathBeforeProfile } from "../../store/ui/actions";
import PatientTableSkeleton from "../Skeletons/PatientTableSkeleton";
import EmptyPatients from "./EmptyPatients";
import TableData from "./TableData";

export const DateCell = ({ value }) => {
  return value ? (
    <p>
      {new Date(value.replace(/-/g, "/")).toLocaleDateString("en-US", {
        month: "2-digit",
        day: "2-digit",
        year: "numeric",
      })}
    </p>
  ) : (
    <></>
  );
};

export const PhoneCell = ({ value, patientId }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  return value ? (
    <p>{getFormattedPhoneNumber(value)}</p>
  ) : (
    <button
      type="button"
      className="flex items-center space-x-2.5 text-sm text-tertiary font-semibold"
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
        dispatch(setPathBeforeProfile(Pages.PATIENTS));
        dispatch(
          getPatientInfo(patientId, (patientInfo) => {
            dispatch(setSelectedPatientInfoAction(patientInfo));
            navigate(`${Pages.PATIENTS}/${patientId}`);
          }),
        );
        dispatch(
          setSelectedPatientProfileTabAction(patientProfileTabs.INFORMATION),
        );
        dispatch(setSelectedPatientPanelTabAction(patientPanelTabs.SCRIBE));
        dispatch(setOpenEditInfoModalAction(true));
      }}
    >
      + Add Phone Number
    </button>
  );
};

const PatientsTable = ({
  columns,
  data,
  cancelSelection,
  setCancelSelection,
  onDelete,
  onEdit,
  onPause,
  onAdd,
}) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    toggleAllRowsSelected,
    rows,
  } = useTable(
    {
      columns,
      data,
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    useRowSelect,
  );
  const { patientsPage, numOfPatients, loader } = useSelector(
    (state) => state.patient,
  );
  const dispatch = useDispatch();

  useEffect(() => {
    if (cancelSelection) {
      toggleAllRowsSelected(false);
      setCancelSelection(false);
      dispatch(setSelectedPatientsAction([]));
    }
  }, [cancelSelection, toggleAllRowsSelected, setCancelSelection, dispatch]);

  return (
    <div className="min-h-0 h-full w-full overflow-hidden">
      {loader || patientsPage === null ? (
        <PatientTableSkeleton />
      ) : numOfPatients > 0 ? (
        <div className="h-full w-full overflow-hidden">
          {data.length > 0 ? (
            <TableData
              getTableProps={getTableProps}
              headerGroups={headerGroups}
              getTableBodyProps={getTableBodyProps}
              rows={rows}
              prepareRow={prepareRow}
              onEdit={onEdit}
              onPause={onPause}
              onDelete={onDelete}
            />
          ) : (
            <div className="bg-white px-3 xl:px-4 h-14 w-full flex items-center justify-center rounded-lg my-1.5 text-sm">
              No patients matching the given filters
            </div>
          )}
        </div>
      ) : (
        <EmptyPatients onClick={onAdd} />
      )}
    </div>
  );
};

export default PatientsTable;
