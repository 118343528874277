import type { FixLater, scribeSlice } from "./scribeSlice";

export const LOGOUT = "LOGOUT";
export const SET_PROVIDER_NOTES_LOADING = "SET_PROVIDER_NOTES_LOADING";
export const SET_PATIENT_NOTES_LOADING = "SET_PATIENT_NOTES_LOADING";

export enum SetInterruptedRecordingActionType {
  NAVIGATION = "NAVIGATION",
  SETTINGS_NAVIGATION = "SETTINGS_NAVIGATION",
  PATIENT_PROFILE_CLOSE = "PATIENT_PROFILE_CLOSE",
  PATIENT_CHANGE_SELECTED_AUDIO_ID_WHILE_RECORDING = "PATIENT_CHANGE_SELECTED_AUDIO_ID_WHILE_RECORDING",
  CLOSE_PANEL_WHILE_RECORDING = "CLOSE_PANEL_WHILE_RECORDING",
  NEW_ENROLLMENT = "NEW_ENROLLMENT",
  NEW_ENCOUNTER = "NEW_ENCOUNTER",
  RESUME_ANOTHER_ENCOUNTER = "RESUME_ANOTHER_ENCOUNTER",
  RESUME_GENERATED_ENCOUNTER = "RESUME_GENERATED_ENCOUNTER",
  SCRIBE_BACK = "SCRIBE_BACK",
  PATIENT_PROFILE_OPEN = "PATIENT_PROFILE_OPEN",
  PATIENT_CALL = "PATIENT_CALL",
}

export type ScribeAction =
  | ReturnType<(typeof scribeSlice.actions)[keyof typeof scribeSlice.actions]>
  | { type: string; payload: FixLater };
