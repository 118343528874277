import { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { ReactComponent as PencilIcon } from "../../../../assets/icons/pencil.svg";
import { ReactComponent as PlusIcon } from "../../../../assets/icons/plus-small.svg";
import userIconSrc from "../../../../assets/icons/user-default.svg";
import { occupations } from "../../../../helpers/constants";
import { createUser } from "../../../../store/user/thunks";
import Button from "../../../Basic/Button";
import FacilityMultipleSelect from "../../../Basic/FacilityMultipleSelect";
import Input from "../../../Basic/Input";
import Select from "../../../Basic/Select";
import AvatarModal from "../../Profile/AvatarModal";

const AddUserForm = ({ className = "", onClose, onAddMultiple }) => {
  const { userSearchValue, roleOptions } = useSelector((state) => state.user);
  const [showInputPosition, setShowInputPosition] = useState(false);
  const [positionOther, setPositionOther] = useState("");
  const [positionError, setPositionError] = useState(null);
  const [openAvatarModal, setOpenAvatarModal] = useState(false);
  const [profilePicture, setProfilePicture] = useState("");
  const [selectedFacilities, setSelectedFacilities] = useState([]);
  const [facilitiesError, setFacilitiesError] = useState(null);
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues: {
      first_name: "",
      last_name: "",
      display_name: "",
      email: "",
      role_id: 1,
      occupation: occupations[0].value,
    },
  });
  const watchPosition = watch("occupation");

  useEffect(() => {
    if (watchPosition === "other") {
      setShowInputPosition(true);
    } else {
      setPositionOther("");
      setShowInputPosition(false);
    }
  }, [watchPosition]);

  const checkInputPositionOther = useCallback(() => {
    if (watchPosition === "other" && !positionOther.trim()) {
      setPositionError({ message: "Position can't be empty" });
    } else {
      setPositionError(null);
    }
  }, [watchPosition, positionOther]);

  useEffect(() => {
    checkInputPositionOther();
  }, [checkInputPositionOther]);

  const onSubmit = (data) => {
    if (selectedFacilities.length === 0) {
      setFacilitiesError("Please select at least one clinic");
      return;
    }

    data.facilities = selectedFacilities.map(
      (facility) => facility.customer_id,
    );

    data.profile_picture = profilePicture;

    if (data.role_id) {
      data.role_id = Number(data.role_id);
    }
    if (data.occupation === "other") {
      data.occupation = positionOther.trim();
    }

    if (!positionError) {
      dispatch(
        createUser(
          [data],
          () => {
            reset();
            onClose();
          },
          userSearchValue,
        ),
      );
    }
  };

  const onCancel = () => {
    onClose();
    reset();
  };

  return (
    <div
      className={`w-full grid grid-rows-note-layout overflow-hidden ${className}`}
    >
      <form
        id="add-user"
        onSubmit={handleSubmit(onSubmit)}
        className="px-5 space-y-4 flex flex-col min-h-0 overflow-y-auto scrollbar"
      >
        <button
          type="button"
          onClick={(e) => {
            e.preventDefault();
            setOpenAvatarModal(true);
          }}
          className="bg-gray-200 rounded-full relative mb-4 w-20 h-20 flex-none"
        >
          <img
            src={profilePicture || userIconSrc}
            alt="Avatar"
            className={`rounded-full
              ${profilePicture ? "min-w-full min-h-full object-cover" : "w-full max-w-[60px] mx-auto p-2"}`}
          />
          <div className="absolute bottom-0.5 right-0.5 rounded-full bg-primary-blue flex items-center justify-center w-6 h-6">
            <PencilIcon width={12} height={12} />
          </div>
        </button>

        <div className="grid md:grid-cols-2 gap-5">
          <Input
            register={register}
            name="first_name"
            label="First Name"
            placeholder="Enter first name"
            error={errors.firstName}
          />
          <Input
            register={register}
            name="last_name"
            label="Last Name"
            placeholder="Enter last name"
            error={errors.lastName}
          />
        </div>
        <Input
          label="Display Name"
          register={register}
          name="display_name"
          placeholder="Enter name with titles displayed"
          error={errors.displayName}
        />

        <Input
          register={register}
          name="email"
          label="Email"
          placeholder="Enter email"
          type="email"
          required="Email can’t be blank"
          withoutAsterisk
          error={errors.email}
          validationPattern={{
            value: /\S+@\S+\.\S+/,
            message: "Enter a valid email address",
          }}
        />
        <Select
          label="Role"
          placeholder="Select role"
          name="role_id"
          options={roleOptions}
          register={register}
          error={errors.role_id}
        />
        <Select
          label="Position"
          placeholder="Select position"
          name="occupation"
          required="Position can't be empty"
          options={occupations}
          register={register}
          error={errors.occupation}
          withoutAsterisk
        />
        {showInputPosition && (
          <Input
            value={positionOther}
            onChange={(e) => setPositionOther(e.target.value)}
            placeholder="Enter position"
            required="Position can't be empty"
            error={positionError}
            withoutAsterisk
          />
        )}

        <div className="space-y-1.5">
          <p className="font-semibold text-sm">Clinics</p>
          <FacilityMultipleSelect
            displaySelect
            selectedFacilities={selectedFacilities}
            setSelectedFacilities={setSelectedFacilities}
            error={facilitiesError}
            setError={setFacilitiesError}
          />
        </div>

        <button
          type="submit"
          className="text-primary-blue text-sm font-semibold flex items-center gap-2"
          onClick={(e) => {
            e.preventDefault();
            onAddMultiple();
          }}
        >
          <PlusIcon
            width="10"
            height="10"
            stroke="#2970FF"
            className="flex-none mb-0.5"
          />
          <p>Add Multiple Users</p>
        </button>
      </form>

      <div className="flex justify-end gap-4 font-semibold text-sm bg-gray-background py-4 px-5 mt-5">
        <Button
          className="w-full md:w-fit"
          variant="gray"
          onClick={(e) => {
            e.preventDefault();
            onCancel();
          }}
        >
          Cancel
        </Button>
        <Button
          className="w-full md:w-fit"
          form="add-user"
          type="submit"
          onClick={checkInputPositionOther}
        >
          Save
        </Button>
      </div>

      <AvatarModal
        open={openAvatarModal}
        onClose={() => setOpenAvatarModal(false)}
        onSave={(picture) => {
          setProfilePicture(picture);
          setOpenAvatarModal(false);
        }}
      />
    </div>
  );
};

export default AddUserForm;
