import { z } from "zod";
import {
  baseConversationSchema,
  doctorSchema,
  patientSchema,
  zeroOrOneSchema,
} from "../../helpers/commonValidationSchemas";

const conversationDoctorSchema = z.object({
  doctor_id: z.number(),
  first_name: z.string(),
  last_name: z.string(),
});

const conversationPatientSchema = z.object({
  birthdate: z.string(),
  first_name: z.string(),
  gender: z.string().nullable(),
  is_paused: zeroOrOneSchema.nullish(),
  last_name: z.string(),
  middle_name: z.string().nullable(),
  patient_id: z.number(),
  phone_number: z.string().nullable(),
  preferred_name: z.string().nullable(),
  profile_picture: z.string().nullish(),
  pronouns: z.string().nullable(),
  sex: z.string(),
});

const messageSchema = z.object({
  from: z.string(),
  is_automatic: zeroOrOneSchema,
  message: z.string().optional(),
  send_at: z.string(),
  sender: z.union([patientSchema.partial(), doctorSchema.partial()]).nullable(),
});

export const conversationSchema = baseConversationSchema.extend({
  campaign: z.string(),
  doctor: conversationDoctorSchema,
  messages: z.array(messageSchema),
  patient: conversationPatientSchema,
  trackable: z.boolean(),
});

const lastMessageSchema = z.object({
  conversation_id: z.number(),
  from: z.string(),
  is_answer: zeroOrOneSchema,
  is_automatic: zeroOrOneSchema,
  is_question: zeroOrOneSchema,
  message: z.string(),
  message_id: z.number(),
  read: zeroOrOneSchema,
  send_at: z.string(),
  sender_id: z.number().nullable(),
  state: z.number().nullable(),
});

const conversationPreviewSchema = z.object({
  conversation_id: z.number(),
  flag: z.string().nullable(),
  is_urgent: zeroOrOneSchema,
  lastMessage: lastMessageSchema,
  patient: conversationPatientSchema,
  unread: z.number().min(0),
});

export const conversationsSchema = z.object({
  all: z.array(conversationPreviewSchema),
  total_conversations: z.number().min(0),
});

export const conversationCountsSchema = z.object({
  UNREAD: z.number().min(0).optional(),
  "active TOTAL": z.number().min(0),
  all: z.number().min(0).optional(),
  noResponse: z.number().min(0).optional(),
  patientInitiated: z.number().min(0).optional(),
  paused: z.number().min(0).optional(),
  "review TOTAL": z.number().min(0),
  staffEngaging: z.number().min(0).optional(),
});
