import { useCallback, useEffect, useRef, useState } from "react";
import { useAppDispatch } from "../../../store";
import type { Scribe } from "../store/interfaces";
import { setScribe } from "../store/scribeSlice";

export const useScribeGenerationDelay = (selectedScribe: Scribe) => {
  const [
    showNoteGenerationDelayedWarning,
    setShowNoteGenerationDelayedWarning,
  ] = useState(false);
  const dispatch = useAppDispatch();
  const generationTimeoutRef = useRef<NodeJS.Timeout | null>(null);
  const delayedGenerationIdsRef = useRef<Set<string>>(new Set());
  const prevSelectedScribeRef = useRef<Scribe | null>(null);

  const [hasClosedWarning, setHasClosedWarning] = useState(false);

  const setScribeGenerationDelayed = useCallback(
    (audioId: string, isGenerationDelayed: boolean) => {
      if (isGenerationDelayed) {
        delayedGenerationIdsRef.current.add(audioId);

        if (!hasClosedWarning) {
          setShowNoteGenerationDelayedWarning(true);
        }

        dispatch(
          setScribe({
            audioId,
            scribe: { isGenerationDelayed: true },
          }),
        );
      } else {
        delayedGenerationIdsRef.current.delete(audioId);
        setShowNoteGenerationDelayedWarning(false);
        dispatch(
          setScribe({
            audioId,
            scribe: { isGenerationDelayed: false },
          }),
        );
      }
    },
    [dispatch, hasClosedWarning],
  );

  useEffect(() => {
    if (
      prevSelectedScribeRef.current &&
      prevSelectedScribeRef.current?.audioId !== selectedScribe?.audioId
    ) {
      setHasClosedWarning(false);
      setShowNoteGenerationDelayedWarning(false);
    }

    prevSelectedScribeRef.current = selectedScribe;

    if (
      selectedScribe?.isGenerationDelayed ||
      delayedGenerationIdsRef.current.has(selectedScribe?.audioId)
    ) {
      setShowNoteGenerationDelayedWarning(true);
      setScribeGenerationDelayed(selectedScribe?.audioId, true);

      return;
    }

    if (
      !selectedScribe?.isGenerationDelayed &&
      selectedScribe?.isGenerating &&
      !generationTimeoutRef.current
    ) {
      if (
        generationTimeoutRef.current &&
        prevSelectedScribeRef.current?.audioId !== selectedScribe?.audioId
      ) {
        clearTimeout(generationTimeoutRef.current);
      }

      generationTimeoutRef.current = setTimeout(
        () => {
          if (selectedScribe?.isGenerating) {
            setScribeGenerationDelayed(selectedScribe.audioId, true);
          }
          generationTimeoutRef.current = null;
        },
        2 * 60 * 1000,
      );
    } else if (!selectedScribe?.isGenerating) {
      if (selectedScribe?.audioId) {
        setScribeGenerationDelayed(selectedScribe.audioId, false);
      }

      if (generationTimeoutRef.current) {
        clearTimeout(generationTimeoutRef.current);
        generationTimeoutRef.current = null;
      }
    }
  }, [selectedScribe, setScribeGenerationDelayed]);

  useEffect(() => {
    return () => {
      delayedGenerationIdsRef.current.clear();
      setShowNoteGenerationDelayedWarning(false);
    };
  }, []);

  const hideNoteGenerationDelayedWarning = () => {
    setShowNoteGenerationDelayedWarning(false);
    setHasClosedWarning(true);
  };

  return {
    showNoteGenerationDelayedWarning:
      showNoteGenerationDelayedWarning && !hasClosedWarning,
    setShowNoteGenerationDelayedWarning,
    hideNoteGenerationDelayedWarning,
    setScribeGenerationDelayed,
  };
};
