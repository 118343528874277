import TemplatePreview from "../../Basic/TemplatePreview";

const NotePreview = ({ title, sections }) => {
  return (
    <div className="w-full grid grid-rows-tab-layout md:block h-full pb-4 md:pt-1">
      <div
        className="flex md:hidden w-full bg-gray-background border-b border-gray-150 font-base font-semibold py-3
          mb-4 px-4"
      >
        {title}
      </div>

      <TemplatePreview
        sections={sections}
        className="h-full overflow-y-auto px-4 scrollbar"
      />
    </div>
  );
};

export default NotePreview;
