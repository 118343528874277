import { z } from "zod";

export const twilioTokenSchema = z.string();

export const voiceConversationSchema = z.object({
  audio_id: z.union([z.string(), z.number()]),
  audio_status: z.string(),
  context: z.string().nullable(),
  conversation: z.string().nullish(),
  conversation_history_id: z.number().nullish(),
  conversation_id: z.number().nullish(),
  created_at: z.string(),
  deleted_at: z.string().nullable(),
  doctor_id: z.number(),
  duration: z.number().min(0).nullable(),
  file_size: z.number().min(0).nullable(),
  finished_at: z.string().nullable(),
  first_seen_at: z.string().nullable(),
  form_templates: z.any().nullable(),
  free_text: z.string().nullable(),
  input_language: z.string().nullable(),
  note: z.string().nullish(),
  note_language: z.string().nullable(),
  note_template_id: z.number(),
  patient_id: z.number().nullable(),
  summary: z.string().nullish(),
  title: z.string().nullable(),
  transcription: z.string().nullable(),
  voice_conversation_id: z.number(),
});
