import Popover from "@mui/material/Popover";
import { useCallback, useEffect, useState } from "react";
import { ReactComponent as EyeIcon } from "../../../../assets/icons/eye-big.svg";
import { ReactComponent as EyeClosedIcon } from "../../../../assets/icons/eye-closed.svg";
import { ReactComponent as PlusIcon } from "../../../../assets/icons/plus-circle.svg";
import { ReactComponent as SearchIcon } from "../../../../assets/icons/search.svg";
import { ReactComponent as TrashIcon } from "../../../../assets/icons/trash-redesign.svg";
import { selectInputText } from "../../../../helpers/helpers";
import ListItem from "./ListItem";

const DropdownPopover = ({
  open,
  onClose,
  anchorEl,
  width,
  preMadeItems,
  customItems,
  onSelectItem,
  onCreateItem,
  itemName,
  withoutSearch,
  withoutSubtitles,
  onRemoveCustomItem,
  onHidePreMadeItem,
}) => {
  const [searchValue, setSearchValue] = useState("");
  const [filteredPreMadeItems, setFilteredPreMadeItems] =
    useState(preMadeItems);
  const [filteredCustomItems, setFilteredCustomItems] = useState(customItems);

  const handleSearch = useCallback(
    (value) => {
      const normalizedValue = value.toLowerCase().trim();
      if (!preMadeItems && !customItems) {
        return;
      }

      setFilteredPreMadeItems(
        preMadeItems?.filter((item) =>
          (item.name || "").toLowerCase().trim().includes(normalizedValue),
        ),
      );
      setFilteredCustomItems(
        customItems?.filter((item) =>
          (item.name || "").toLowerCase().trim().includes(normalizedValue),
        ),
      );
    },
    [preMadeItems, customItems],
  );

  useEffect(() => {
    handleSearch(searchValue);
  }, [handleSearch, searchValue]);

  return (
    <Popover
      open={open}
      onClose={() => {
        onClose();
        setSearchValue("");
      }}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      PaperProps={{
        sx: {
          width: width,
          boxShadow: "0px 32px 64px 0px rgba(16, 24, 40, 0.08)",
          margin: "4px 0 0 0",
          borderRadius: "8px",
          border: "1px solid #EAECF0",
          padding: "12px",
        },
      }}
    >
      <div className="h-fit max-h-50vh-dynamic height-sm:max-h-80 overflow-hidden flex flex-col">
        {!withoutSearch && (
          <div className="relative w-full mb-4">
            <input
              value={searchValue}
              onClick={selectInputText}
              onChange={(e) => setSearchValue(e.target.value)}
              className="rounded-lg h-10 text-base outline-none w-full pl-2.5 pr-11 placeholder:text-gray-400 border"
              placeholder={`Search ${itemName}`}
            />
            <SearchIcon
              className="absolute top-2.5 right-2.5"
              width="20"
              height="20"
              stroke="#A0A6B2"
            />
          </div>
        )}

        <div className="flex flex-col overflow-y-auto scrollbar px-1">
          {(customItems?.length > 0 || onCreateItem) && (
            <div
              className={`text-sm font-semibold space-y-1
                ${preMadeItems?.length > 0 ? "pb-4 mb-4 border-b border-gray-foreground" : ""}`}
            >
              {!withoutSubtitles && (
                <p className="uppercase text-tertiary text-xs font-Inter font-medium tracking-wide">
                  Custom {itemName}s
                </p>
              )}
              {filteredCustomItems?.map((item, index) => (
                <ListItem
                  key={`${item.name}_${index}`}
                  item={item}
                  onClick={() => onSelectItem(item)}
                  hoveredIcon={
                    <TrashIcon width="20" height="20" className="flex-none" />
                  }
                  onClickHovered={
                    onRemoveCustomItem &&
                    ((item) => {
                      onRemoveCustomItem(item);
                      onClose();
                    })
                  }
                />
              ))}
              {onCreateItem && (
                <button
                  type="button"
                  className="p-2 space-x-2 text-primary-blue flex items-center"
                  onClick={() => {
                    onCreateItem();
                    onClose();
                    setSearchValue("");
                  }}
                >
                  <PlusIcon width="20" height="20" className="flex-none" />
                  <p>Create new {itemName}</p>
                </button>
              )}
            </div>
          )}

          {preMadeItems?.length > 0 && (
            <div className="text-sm font-semibold space-y-1">
              {!withoutSubtitles && (
                <p className="uppercase text-tertiary text-xs font-Inter font-medium tracking-wide">
                  Pre-made {itemName}s
                </p>
              )}
              {filteredPreMadeItems?.map((item, index) => (
                <ListItem
                  key={`${item.name}_${index}`}
                  item={item}
                  onClick={() => onSelectItem(item)}
                  hoveredIcon={
                    item.is_hidden ? (
                      <EyeClosedIcon
                        width="20"
                        height="20"
                        className="flex-none"
                      />
                    ) : (
                      <EyeIcon width="20" height="20" className="flex-none" />
                    )
                  }
                  onClickHovered={onHidePreMadeItem}
                  className={
                    item.is_hidden ? "text-gray-placeholder" : "text-primary"
                  }
                />
              ))}
            </div>
          )}

          {!filteredCustomItems?.length > 0 &&
            !filteredPreMadeItems?.length > 0 &&
            !onCreateItem && (
              <p className="text-sm font-medium">No {itemName}s found</p>
            )}
        </div>
      </div>
    </Popover>
  );
};

export default DropdownPopover;
