import { useCallback, useEffect, useRef, useState } from "react";
import { throttle } from "../../../helpers/throttle";
import type { AudioNodes, AudioSetupContext } from "../../../types/audio";
import {
  type RecoveryAttempt,
  type RecoveryState,
  RecoveryStrategy,
} from "../../../types/audioRecovery";
import { connectAudioNodes, setupAudioNodes } from "./audioOperations";

export function useAudioRecoveryStrategy(
  setupContext: AudioSetupContext,
  onRecoveryComplete: (successful: boolean) => void,
  config = {
    maxConsecutiveFailures: 3,
    recoveryTimeoutMs: 5000,
    minTimeBetweenRecoveriesMs: 1000,
    maxRecoveryAttempts: 5,
  },
) {
  const {
    audioContextRef,
    audioNodesRef,
    streamRef,
    setupAudio,
    cleanupAudio,
  } = setupContext;

  const localAudioContextRef = useRef<AudioContext | null>(null);
  const localAudioNodesRef = useRef<AudioNodes | null>(null);
  const localStreamRef = useRef<MediaStream | null>(null);

  // Update local refs when props change
  useEffect(() => {
    localAudioContextRef.current = audioContextRef.current;
    localAudioNodesRef.current = audioNodesRef.current;
    localStreamRef.current = streamRef.current;
  }, [audioContextRef.current, audioNodesRef.current, streamRef.current]);

  const [recoveryState, setRecoveryState] = useState<RecoveryState>({
    attempts: [],
    currentStrategy: RecoveryStrategy.RESUME_CONTEXT,
    isRecovering: false,
    lastSuccessfulRecovery: null,
    consecutiveFailures: 0,
  });
  const { currentStrategy, consecutiveFailures } = recoveryState;

  const timeoutRef = useRef<NodeJS.Timeout>();
  const recoveryInProgressRef = useRef(false);

  const clearRecoveryTimeout = useCallback(() => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
      timeoutRef.current = undefined;
    }
  }, []);

  const updateRecoveryState = useCallback(
    (update: Partial<RecoveryState> & { attempt?: RecoveryAttempt }) => {
      setRecoveryState((prev) => {
        const newState = {
          ...prev,
          ...update,
          attempts: update.attempt
            ? [...prev.attempts, update.attempt]
            : prev.attempts,
        };
        return newState;
      });
    },
    [],
  );

  const logRecoveryAttempt = useCallback(
    (strategy: RecoveryStrategy, successful: boolean, error?: Error) => {
      const attempt: RecoveryAttempt = {
        strategy,
        timestamp: Date.now(),
        successful,
        error,
      };
      updateRecoveryState({ attempt });

      // Log to monitoring system
      console.log("Recovery attempt:", {
        ...attempt,
        error: error?.message,
      });
    },
    [updateRecoveryState],
  );

  // Individual recovery strategies
  const strategies = {
    [RecoveryStrategy.RESUME_CONTEXT]: async () => {
      const context = audioContextRef.current;
      if (!context) {
        throw new Error("No audio context available");
      }

      if (context.state === "suspended") {
        await context.resume();
      }

      return context.state === "running";
    },

    [RecoveryStrategy.RECREATE_NODES]: async () => {
      const context = audioContextRef.current;
      if (!context) {
        throw new Error("No audio context available");
      }

      // Disconnect existing nodes
      if (audioNodesRef.current) {
        Object.values(audioNodesRef.current).forEach((node) => {
          try {
            node.disconnect();
          } catch (error) {
            console.warn("Error disconnecting node:", error);
          }
        });
      }

      // Recreate and reconnect nodes
      localAudioNodesRef.current = setupAudioNodes(context);
      // await setupAudio();

      return true;
    },

    [RecoveryStrategy.RECONNECT_STREAM]: async () => {
      const context = audioContextRef.current;
      const stream = streamRef.current;

      if (!context || !stream) {
        throw new Error("Missing context or stream");
      }

      // Create new source and connect
      const source = context.createMediaStreamSource(stream);
      const destination = context.createMediaStreamDestination();

      if (audioNodesRef.current) {
        connectAudioNodes(source, audioNodesRef.current, destination);
      }

      return true;
    },

    [RecoveryStrategy.RESTART_CONTEXT]: async () => {
      const context = audioContextRef.current;
      if (!context) {
        throw new Error("No audio context available");
      }

      await context.close();
      localAudioContextRef.current = new (
        window.AudioContext || (window as any).webkitAudioContext
      )();

      // Reconnect everything
      await strategies[RecoveryStrategy.RECREATE_NODES]();
      await strategies[RecoveryStrategy.RECONNECT_STREAM]();

      return true;
    },

    [RecoveryStrategy.FULL_RESET]: async () => {
      // Full teardown and rebuild
      await cleanupAudio();
      await setupAudio();
      return true;
    },
  };

  // Strategy selection logic
  const selectNextStrategy = useCallback(
    (currentStrategy: RecoveryStrategy): RecoveryStrategy => {
      const strategyOrder = [
        RecoveryStrategy.RESUME_CONTEXT,
        RecoveryStrategy.RECREATE_NODES,
        RecoveryStrategy.RECONNECT_STREAM,
        RecoveryStrategy.RESTART_CONTEXT,
        RecoveryStrategy.FULL_RESET,
      ];

      const currentIndex = strategyOrder.indexOf(currentStrategy);
      return strategyOrder[
        Math.min(currentIndex + 1, strategyOrder.length - 1)
      ];
    },
    [],
  );

  // Main recovery function
  const recover = useCallback(async () => {
    if (recoveryInProgressRef.current) {
      return;
    }

    try {
      recoveryInProgressRef.current = true;

      // Check if we should attempt recovery
      const timeSinceLastRecovery = recoveryState.lastSuccessfulRecovery
        ? Date.now() - recoveryState.lastSuccessfulRecovery
        : Number.POSITIVE_INFINITY;

      if (timeSinceLastRecovery < config.minTimeBetweenRecoveriesMs) {
        return;
      }

      // Set recovery timeout
      clearRecoveryTimeout();
      timeoutRef.current = setTimeout(() => {
        recoveryInProgressRef.current = false;
        const nextStrategy = selectNextStrategy(currentStrategy);
        updateRecoveryState({
          currentStrategy: nextStrategy,
          consecutiveFailures: consecutiveFailures + 1,
        });
        onRecoveryComplete(false);
      }, config.recoveryTimeoutMs);

      // Attempt recovery
      const strategy = strategies[currentStrategy];
      const successful = await strategy();

      clearRecoveryTimeout();

      if (successful) {
        updateRecoveryState({
          lastSuccessfulRecovery: Date.now(),
          consecutiveFailures: 0,
          currentStrategy: RecoveryStrategy.RESUME_CONTEXT, // Reset to simplest strategy
        });
        onRecoveryComplete(true);
      } else {
        const nextStrategy = selectNextStrategy(currentStrategy);
        updateRecoveryState({
          currentStrategy: nextStrategy,
          consecutiveFailures: consecutiveFailures + 1,
        });
        onRecoveryComplete(false);
      }

      logRecoveryAttempt(currentStrategy, successful);
    } catch (error) {
      logRecoveryAttempt(recoveryState.currentStrategy, false, error as Error);
      onRecoveryComplete(false);
    } finally {
      recoveryInProgressRef.current = false;
    }
  }, [
    recoveryState,
    clearRecoveryTimeout,
    updateRecoveryState,
    logRecoveryAttempt,
    selectNextStrategy,
    config,
    onRecoveryComplete,
    currentStrategy,
    strategies[currentStrategy],
    consecutiveFailures,
  ]);

  // Throttled recovery to prevent too frequent attempts
  const throttledRecover = useCallback(
    throttle(recover, config.minTimeBetweenRecoveriesMs),
    [],
  );

  // Cleanup
  const cleanup = useCallback(() => {
    clearRecoveryTimeout();
    recoveryInProgressRef.current = false;
    updateRecoveryState({
      attempts: [],
      currentStrategy: RecoveryStrategy.RESUME_CONTEXT,
      isRecovering: false,
      lastSuccessfulRecovery: null,
      consecutiveFailures: 0,
    });
  }, [clearRecoveryTimeout, updateRecoveryState]);

  return {
    recover: throttledRecover,
    recoveryState,
    cleanup,
    reset: cleanup,
  };
}
