import { z } from "zod";

const templateSchema = z.object({
  campaign_template_id: z.number(),
  conditions: z.string(),
  customer_id: z.number(),
  goals: z.string(),
  name: z.string(),
  past_medical_history: z.string(),
});

export const templatesSchema = z.array(templateSchema);
