import { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import TextareaAutosize from "react-textarea-autosize";
import { ReactComponent as WandIcon } from "../../../assets/icons/magic-stick.svg";
import { ReactComponent as SendIcon } from "../../../assets/icons/send-outline.svg";
import { convPerPage } from "../../../helpers/constants";
import { useAppDispatch, useAppSelector } from "../../../store";
import { setClosedResponsesAction } from "../../../store/conversation/actions";
import { sendMessage, suggestAnswer } from "../../../store/conversation/thunks";
import TooltipMui from "../../Basic/TooltipMui";
import type { FixLater } from "../../IndependentScribe/store/scribeSlice";
import CompleteButton from "./CompleteButton";
import MobileInputArea from "./MobileInputArea";
import SuggestedAnswer from "./SuggestedAnswer";

type Conversation = FixLater;

type InputAreaProps = {
  setScrolledUp: (scrolledUp: boolean) => void;
  messages: Record<number, string>;
  setMessages: (messages: Record<number, string>) => void;
  showResponse: boolean;
  setShowResponse: (showResponse: boolean) => void;
  currentConversation: Conversation;
  currentConversationId: number;
  className?: string;
  completeClassName?: string;
};

const InputArea: React.FC<InputAreaProps> = ({
  setScrolledUp,
  messages,
  setMessages,
  showResponse,
  setShowResponse,
  currentConversation,
  currentConversationId,
  className = "",
  completeClassName,
}) => {
  const [suggestedAnswerData, setSuggestedAnswerData] = useState("");
  const [conversationChanged, setConversationChanged] = useState(true);
  const {
    conversationSearchInput,
    activeFilters,
    activeInbox,
    closedResponses,
    convCurrPage,
    selectedDoctorInbox,
  } = useAppSelector((state) => state.conversation);
  const dispatch = useAppDispatch();
  const isDesktop = useMediaQuery({ minWidth: 768 });
  const isHeightSm = useMediaQuery({ minHeight: 640 });

  useEffect(() => {
    setSuggestedAnswerData(
      currentConversation ? currentConversation.suggested_answer : "",
    );
    if (conversationChanged) {
      if (currentConversation?.suggested_answer) {
        setShowResponse(
          !closedResponses.includes(currentConversation?.conversation_id),
        );
      } else {
        setShowResponse(false);
      }
      setConversationChanged(false);
    }
  }, [
    currentConversation,
    setShowResponse,
    conversationChanged,
    closedResponses,
  ]);

  useEffect(() => {
    setConversationChanged(true);
    if (currentConversationId && !messages[currentConversationId]) {
      setMessages({ ...messages, [currentConversationId]: "" });
    }
  }, [currentConversationId, setMessages, messages[currentConversationId]]);

  const onSendMessage = () => {
    if (messages[currentConversationId].trim()) {
      dispatch(
        sendMessage(
          messages[currentConversationId],
          currentConversationId,
          conversationSearchInput,
          activeFilters,
          1,
          convPerPage * convCurrPage,
          activeInbox,
          selectedDoctorInbox,
        ),
      );
      setScrolledUp(false);
    }
    setMessages({ ...messages, [currentConversationId]: "" });
  };

  const onResponse = () => {
    setSuggestedAnswerData(
      currentConversation ? currentConversation.suggested_answer : "",
    );
    if (!showResponse) {
      if (!currentConversation?.suggested_answer) {
        dispatch(suggestAnswer(currentConversationId));
      }
      setShowResponse(true);
    } else {
      setShowResponse(false);
    }
  };

  const onKeyUp = (event) => {
    if (
      !event.altKey &&
      !event.ctrlKey &&
      !event.shiftKey &&
      event.key === "Enter"
    ) {
      event.preventDefault();
      onSendMessage();
    }
  };

  return (
    <div className={className}>
      {isDesktop && isHeightSm ? (
        <div className="px-4 pb-4 w-full max-w-5xl mx-auto">
          <div>
            {showResponse && currentConversation?.show_response && (
              <SuggestedAnswer
                answer={suggestedAnswerData}
                onCancel={() => {
                  setShowResponse(false);
                  if (!closedResponses.includes(currentConversationId)) {
                    dispatch(setClosedResponsesAction(currentConversationId));
                  }
                }}
                onUse={() => {
                  setMessages({
                    ...messages,
                    [currentConversationId]: suggestedAnswerData,
                  });
                  setShowResponse(false);
                }}
              />
            )}

            <div className="py-3 bg-white rounded-lg border border-gray-foreground">
              <TextareaAutosize
                value={messages[currentConversationId]}
                onChange={(e) =>
                  setMessages({
                    ...messages,
                    [currentConversationId]: e.target.value,
                  })
                }
                onKeyUp={onKeyUp}
                placeholder="Type a message"
                minRows={2}
                maxRows={14}
                className="w-full resize-none scrollbar border-r-8 border-white pl-3 pr-1 pt-1 outline-none
                focus:outline-none focus:ring-0 text-xs bg-transparent align-top mb-1 leading-[1.3]"
              />

              <div className="px-3 h-9 flex items-center justify-between">
                {currentConversation?.show_response ? (
                  <button
                    type="button"
                    aria-label="Suggest answer"
                    className="flex items-center justify-center rounded-lg h-9 w-9 hover:bg-gray-background"
                    onClick={onResponse}
                  >
                    <WandIcon width="20" height="20" stroke="#2970FF" />
                  </button>
                ) : (
                  <TooltipMui title="Conversation in progress" arrow>
                    <div
                      className="flex items-center justify-center rounded-lg h-9 w-9 hover:bg-gray-background
                      cursor-default"
                    >
                      <WandIcon width="20" height="20" />
                    </div>
                  </TooltipMui>
                )}
                <button
                  type="button"
                  aria-label="Send message"
                  className="w-9 h-9 bg-primary-blue rounded-lg flex items-center justify-center"
                  onClick={onSendMessage}
                >
                  <SendIcon width="18" height="18" />
                </button>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <MobileInputArea
          messages={messages}
          setMessages={setMessages}
          onSendMessage={onSendMessage}
          onClickWand={onResponse}
          showResponse={showResponse}
          setShowResponse={setShowResponse}
          suggestedAnswer={suggestedAnswerData}
          currentConversation={currentConversation}
          currentConversationId={currentConversationId}
        />
      )}
      <CompleteButton
        conversation={currentConversation}
        className={completeClassName}
      />
    </div>
  );
};

export default InputArea;
