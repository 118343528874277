import { Draggable } from "react-beautiful-dnd";
import { ReactComponent as DotsIcon } from "../../../../assets/icons/dots-grid.svg";
import {
  sectionDetailLevels,
  sectionStyles,
} from "../../../../helpers/constants";
import SectionContentPreview from "./SectionContentPreview";

const SectionPreview = ({ section, index }) => {
  return (
    <Draggable
      draggableId={section.note_template_section_id.toString()}
      index={index}
    >
      {(provided) => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          className="w-full flex items-top space-x-2"
        >
          <div {...provided.dragHandleProps}>
            <DotsIcon width="24" height="24" className="flex-none" />
          </div>

          <div className="w-full truncate space-y-2">
            <p className="uppercase text-sm font-medium font-Inter tracking-wider leading-none truncate">
              {section.name}
            </p>

            {section.has_subsections && section.subsections?.length > 0 ? (
              section.subsections.map((subsection, index) => (
                <div
                  key={`${subsection}_${index}`}
                  className="w-full space-y-2 rounded-xl p-3 bg-gray-background truncate"
                >
                  <p className="text-sm font-medium truncate">{subsection}</p>
                  <SectionContentPreview
                    detail={section.detail_level === sectionDetailLevels.HIGH}
                    bullet={section.section_style === sectionStyles.BULLET}
                  />
                </div>
              ))
            ) : (
              <SectionContentPreview
                detail={section.detail_level === sectionDetailLevels.HIGH}
                bullet={section.section_style === sectionStyles.BULLET}
              />
            )}
          </div>
        </div>
      )}
    </Draggable>
  );
};

export default SectionPreview;
