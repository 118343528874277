import { Suspense, lazy } from "react";
import { useMediaQuery } from "react-responsive";
import { useNavigate } from "react-router-dom";
import {
  Pages,
  patientPanelTabs,
  patientProfileTabs,
} from "../../../helpers/constants";
import { getFormattedPhoneNumber } from "../../../helpers/helpers";
import { lazyRetry } from "../../../helpers/lazyRetry";
import { useAppDispatch, useAppSelector } from "../../../store";
import {
  setSelectedPatientPanelTabAction,
  setSelectedPatientProfileTabAction,
} from "../../../store/patient/actions";
import { getPatientInfo } from "../../../store/patient/thunks";
import { setPathBeforeProfile } from "../../../store/ui/actions";
import { setOpenCallOptionsModalAction } from "../../../store/voiceRecorder/actions";
import ProfilePicture from "../../Basic/ProfilePicture";
import PatientShortInfo from "../../PatientProfile/PatientShortInfo";
import InsightPanelSkeleton from "../../Skeletons/InsightPanelSkeleton";

const InsightTab = lazy(() => lazyRetry(() => import("./InsightTab/index.js")));

const InsightPanel = () => {
  const { currentConversation } = useAppSelector((state) => state.conversation);
  const { patient } = currentConversation;
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const isLg = useMediaQuery({ minWidth: 1024 });
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  return (
    <div
      className="md:border border-gray-200 bg-white md:rounded-lg h-full flex-none grid grid-rows-tab-layout
        transition-all w-full md:w-80 lg:w-[375px] relative overflow-hidden"
    >
      <div className="h-fit flex px-5 overflow-hidden">
        <button
          type="button"
          className="flex items-center w-full border-b overflow-hidden
            pb-3 md:pb-5 pl-7 md:pl-0 pt-3 md:pt-5 mb-5"
          onClick={() => {
            dispatch(setPathBeforeProfile(Pages.MESSAGING));
            dispatch(
              getPatientInfo(patient.patient_id, () => {
                navigate(`${Pages.PATIENTS}/${patient.patient_id}`);
                dispatch(setOpenCallOptionsModalAction(true));
              }),
            );
            dispatch(
              setSelectedPatientProfileTabAction(patientProfileTabs.NOTES),
            );
            dispatch(setSelectedPatientPanelTabAction(patientPanelTabs.SCRIBE));
          }}
        >
          {!isMobile && (
            <ProfilePicture
              src={patient.profile_picture}
              firstName={
                patient.preferred_name
                  ? patient.preferred_name
                  : patient.first_name
              }
              lastName={patient.last_name}
              size={isLg ? 14 : 10}
              fontSize={isLg ? "xl" : "base"}
              className="h-10 w-10 lg:h-14 lg:w-14 mr-2 lg:mr-3"
            />
          )}
          <div className="flex flex-col items-start w-full truncate">
            <p className="font-semibold text-left text-base lg:text-xl mt-1 md:mt-0 w-full truncate">
              {patient.preferred_name
                ? patient.preferred_name
                : patient.first_name}{" "}
              {patient.last_name}
            </p>
            {patient.phone_number && !isMobile ? (
              <div className="leading-none mt-1 pb-0.5 w-full truncate text-left text-xs lg:text-base text-tertiary">
                <p>{getFormattedPhoneNumber(patient.phone_number)}</p>
              </div>
            ) : (
              <PatientShortInfo
                patient={patient}
                className="mt-1 pb-0.5 w-full truncate text-left text-xs lg:text-base text-tertiary"
                showBirthdate={false}
              />
            )}
          </div>
        </button>
      </div>

      <div className="overflow-y-auto scrollbar">
        <Suspense fallback={<InsightPanelSkeleton />}>
          <InsightTab />
        </Suspense>
      </div>
    </div>
  );
};

export default InsightPanel;
